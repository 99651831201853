export { default as HomePage } from "./homepage";
export { default as Custom404 } from "./404";
export { default as Thanks } from "./Thanks";
export { default as Team } from "./OurTeam";
export { default as Faq } from "./Faq";
export { default as Login } from "./login";
export { default as ResendVerifyEmail } from "./resendVerifyEmail"
export { default as VerifyEmail } from "./verifyEmail";
export { default as PrivacyPolicy } from "./PrivacyPolicy";
export { default as TermsAndConditions } from "./Terms&Condition";
export { default as ForgotPassword } from "./forgotPassword";
export { default as ResetPassword } from "./resetPassword";
export { default as Register } from "./register";
export { default as Profile } from "./profile";
export { default as Products } from "./buckets";
export { default as Charts } from "./charts";
export { default as AdminView } from "./adminUsers";
export { default as AdminBlacklist } from "./adminBlacklist";
export { default as AdminFeedbackUrl } from "./adminFeedbackUrl";
export { default as ProfitableProducts } from "./profitableProducts";
export { default as ProfitableProductsDetails } from "./profitableProductsDetails";
export { default as SavedSearches } from "./savedSearches";
export { default as ShipmentPlan } from "./shipmentPlans";
export { default as ShipmentPlanItem } from "./shipmentPlan";
export { default as CustomerView } from "./customerView";
export { default as TechSupport } from "./techSupport";
export { default as LoadingScreen } from "./loadingScreen";
export { default as Teams } from "./Teams";
