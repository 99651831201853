import React from "react";
import { MAX_ALLOWED_SELECTED_STATES } from "../constants";
import { StateDropdown } from "./StateDropdown";

export const HighlightedStatesDisplay: React.FC<{ highlightedStates: string[]; setHighlightedStates: (stateName: string) => void }> = ({ highlightedStates, setHighlightedStates }) => {



  return (
    <div className="p-4 bg-blue-100 rounded-lg shadow-lg z-10">
      <div className="mb-2">
        <h2 className="text-gray-800 text-lg font-semibold">
          Selected States (No more than {MAX_ALLOWED_SELECTED_STATES} states):
        </h2>
      </div>

      <div className="dropdowns mt-4">

        {highlightedStates.map((selectedState, index) => (
          <div key={index} className="mb-2">
            <span className="font-semibold text-lg text-gray-700">State {index + 1}</span>
            <StateDropdown selectedState={selectedState} setHighlightedStates={setHighlightedStates} />
          </div>
        ))}
      </div>
    </div>
  );
};
