import { IAddOrderModalProps } from "./interface";
import "./styles.scss";
import ShipmentPlanProduct from "../ShipmentPlanProduct";
import { ellipsis } from "utils";
import { useState } from "react";
import { Button } from "@amcharts/amcharts5";
import { toast } from "react-toastify";
import { set } from "lodash";

const AddOrderModal: React.FC<IAddOrderModalProps> = ({
  shipmentPlan,
  warehouseOwner,
  sendSelectedProductToParent,
}) => {
  const shipmentPlanItems = shipmentPlan.products;
  const [shipTo, setShipTo] = useState("name");

  const [rows, setRows] = useState(
    shipmentPlanItems.map((item, index) => ({
      id: index + 1,
      isClicked: false,
      shipmentPlanProduct: item,
    }))
  );
  const [orderItems, setOrderItems] = useState<{ id: string; quantity: number }[]>([]);

  const handleAddToOrder = (productId: string) => {
    setOrderItems([...orderItems, { id: productId, quantity: 1 }]);
    setRows((prev) =>
      prev.map((row) => {
        if (row.shipmentPlanProduct._id === productId) {
          return {
            ...row,
            isClicked: !row.isClicked,
          };
        }
        return row;
      })
    );
  };

  const handleRemoveFromOrder = (productId: string) => {
    setOrderItems(orderItems.filter((item) => item.id !== productId));
    setRows((prev) =>
      prev.map((row) => {
        if (row.shipmentPlanProduct._id === productId) {
          return {
            ...row,
            isClicked: !row.isClicked,
          };
        }
        return row;
      })
    );
  };

  const handleConfirmOrder = () => {
    // check if orderItems is empty
    if (orderItems.length === 0) {
      toast.error("Please select at least one product to order");
      return;
    }
    const orderID = `${Date.now()}${Math.floor(Math.random() * 1000)}`;
    sendSelectedProductToParent(
      orderItems,
      orderID,
      warehouseOwner.businessName,
      shipTo
    );
  };

  return (
    <div>
      <div className="order-shipment-container">
        <div className="order-ship-from">
          <label className="order-shipment-label" htmlFor="ship-from-name">
            Shipping From
          </label>
          <span className="order-shipment-span">
            {warehouseOwner?.businessName}
          </span>
          <span className="order-shipment-span">
            {warehouseOwner?.businessAddress.address}
          </span>
          <span className="order-shipment-span">
            {warehouseOwner?.businessAddress.city}
          </span>
          <span className="order-shipment-span">
            {warehouseOwner?.businessAddress.state}
          </span>
          <span className="order-shipment-span">
            {warehouseOwner?.businessAddress.zipCode}
          </span>
        </div>

        <div className="order-ship-to">
          <label htmlFor="ship-from-name" className="order-shipment-label">
            Shipping To
          </label>
          <input
            className="order-shipment-input"
            type="text"
            id="ship-from-name"
            placeholder={shipTo}
            onChange={(e) => setShipTo(e.target.value)}
          />
          <input
            className="order-shipment-input"
            type="text"
            id="ship-from-street"
            placeholder="street"
          />
          <input
            className="order-shipment-input"
            type="text"
            id="ship-from-city"
            placeholder="city"
          />
          <input
            className="order-shipment-input"
            type="text"
            id="ship-from-state"
            placeholder="state"
          />
          <input
            className="order-shipment-input"
            type="text"
            id="ship-from-zipcode"
            placeholder="zipcode"
          />
        </div>
      </div>

      {/* display shipment plan product */}
      <div className="inventory-item-table-container">
        <table className="table table-sm">
          <thead className="sticky-header">
            <tr>
              <th className="text-center">ASIN</th>
              <th className="text-center">IMG</th>
              <th className="text-center">TITLE</th>
              <th className="text-center">HAZMAT</th>
              <th className="text-center">Quantity</th>
              <th className="text-center">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {shipmentPlanItems.length > 0 &&
              shipmentPlanItems.map((item, index) => (
                <tr
                  key={rows[index].id}
                  style={{
                    backgroundColor: rows[index].isClicked ? "grey" : "",
                  }}
                >
                  <td className="align-middle text-center white-space-nowrap">
                    <a
                      className="text-details-asin"
                      href={`https://www.amazon.com/dp/${item.asin}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.asin}
                    </a>
                  </td>

                  <td className="align-middle text-center white-space-nowrap">
                    <img
                      src={item.imageUrl}
                      className="mb-1"
                      alt="product-thumb"
                    />
                  </td>
                  <td className="align-middle text-center white-space-nowrap">
                    {ellipsis(item.title, 40)}
                  </td>
                  <td className="align-middle text-center white-space-nowrap">
                    {item.isHazmat ? "YES" : "NO"}
                  </td>
                  <td className="align-middle text-center white-space-nowrap">
                    {/* user enter quantity */}
                    <input
                      type="number"
                      className="form-control"
                      id="quantity"
                      placeholder="Quantity"
                      max={item.unitsPerBox && item.boxes
                        ? parseInt(item.unitsPerBox) * parseInt(item.boxes)
                        : "-"}
                      
                    />
                  </td>
                  <td className="align-middle text-center white-space-nowrap">
                    {rows[index].isClicked ? (
                      <span
                        className="icon-wrapper csp-delete-icon ms-1"
                        onClick={() => handleRemoveFromOrder(item._id)}
                      >
                        <i
                          className="fa fa-minus text-danger"
                          aria-hidden="true"
                        ></i>
                      </span>
                    ) : (
                      <span
                        className="icon-wrapper csp-delete-icon ms-1"
                        onClick={() => handleAddToOrder(item._id)}
                      >
                        <i
                          className="fa fa-plus text-success"
                          aria-hidden="true"
                        ></i>
                      </span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <button
        className="create-order-btn btn-primary"
        onClick={() => handleConfirmOrder()}
      >
        Confirm Inventory
      </button>
    </div>
  );
};

export default AddOrderModal;
