// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profitable-products-more-options {
  color: #999999;
  cursor: pointer;
}
.profitable-products-more-options:hover, .profitable-products-more-options:active, .profitable-products-more-options:focus {
  color: #313749;
}

.same-line {
  display: inline-flex;
  width: auto;
}

.hover-detail {
  display: none;
  position: absolute;
  width: auto;
  height: auto;
  margin-top: 10px;
  z-index: 10;
  background-color: #231d1d;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #231d1d;
}

.hover-row {
  position: relative;
}

.hover-row:hover .hover-detail {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/ProfitableProductsList/styles.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAGE;EACE,cCgBO;EDfP,eAAA;AAFJ;AAGI;EAGE,cCQU;ADXhB;;AAQA;EACE,oBAAA;EACA,WAAA;AALF;;AAQA;EACE,aAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;EACA,yBAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA;AALF;;AAQA;EACE,kBAAA;AALF;;AAQA;EACE,cAAA;AALF","sourcesContent":["@import \"../../assets/styles/colors.scss\";\n\n.profitable-products {\n  &-more-options {\n    color: $textGray;\n    cursor: pointer;\n    &:hover,\n    &:active,\n    &:focus {\n      color: $separatorGray;\n    }\n  }\n}\n\n.same-line {\n  display: inline-flex;\n  width: auto;\n}\n\n.hover-detail {\n  display: none;\n  position: absolute;\n  width: auto;\n  height: auto;\n  margin-top: 10px;\n  z-index: 10;\n  background-color: #231d1d;\n  padding: 10px;\n  border-radius: 5px;\n  border: 1px solid #231d1d;\n}\n\n.hover-row {\n  position: relative;\n}\n\n.hover-row:hover .hover-detail {\n  display: block;\n}","$budhaGold: #8b76b2;\n$sorbusOrange: #8481be;\n$lighterSorbusOrange: #8b76b2;\n$codGray: #191919;\n$mineShaftGray: #252525;\n$selectiveYellow: #8481be;\n$inchWormGreen: \"#7fec12\";\n$errorRed: #f27761;\n$codBlack: #101010;\n$codBlackV2: #171717;\n$tundoraGray: #484848;\n$silverGray: #a6a6a6;\n$pictonBlue: #a44c92;\n$scoprionGray: #5b5b5b;\n$limeade: #3c9c00;\n$sanFelixGreen: #1c6c09;\n$phoenixLinkBlue: #85a9ff;\n$separatorGray: #313749;\n$vulcanBlue: #0f111a;\n$mirageBlue: #151824;\n$textGray: #999999;\n$victoriaBlue: #465298;\n$flamingoRed: #f33f45;\n$thunderbirdRed: #c51a16;\n$green: #02752c;\n$lightGreen: #d5fae0;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
