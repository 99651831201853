import { LatLng, Tooltip } from "leaflet";
import React from "react";
import { useMap } from "react-leaflet";

export const TooltipManager = ({
  position,
  stateName,
  tooltipRef,
}: {
  position: LatLng;
  stateName: string;
  tooltipRef: React.RefObject<Tooltip>;
}) => {
  const map = useMap(); // Get map instance
  // Manage tooltip using useEffect without re-rendering
  React.useEffect(() => {
    if (!position || !stateName) {
      // Remove the tooltip if position or stateName is not available
      if (tooltipRef.current) {
        tooltipRef.current.remove();
      }
      return;
    }
    
    if (tooltipRef.current) {
      tooltipRef.current.setLatLng(position).setContent(`<div>${stateName}</div>`).addTo(map);
    }
  }, [position, stateName, map, tooltipRef]); // Runs when position or stateName changes

  return null; // This component doesn't render anything itself
};