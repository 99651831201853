import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { toast } from "react-toastify";
import NewPassword from "assets/images/newPassword.png";
import { apiRoutes, getQueryParameterByName } from "utils";
import { onLoginSuccess } from "utils/auth";
import { setRememberMeCookie } from "utils/auth/cookie";
import Navbar from "./components/navbar";
import MobileMenu from "./components/mobileMenu";
import Footer from "./components/footer";

const VerifyEmailPage: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const verified = useRef(false);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  useEffect(() => {
    const verifyEmail = async (token: string) => {
      const response = await fetch(`${apiRoutes.VERIFY_EMAIL}?token=${token}`, {
        method: "POST",
      });
      const data = await response.json();
      if (data.status === "success") {
        const user = data.data;
        const { message } = data;
        verified.current = true;
        toast.success(message);
        setTimeout(() => {
          setRememberMeCookie(true, user.email);
          onLoginSuccess(user);
        }, 2000);
      } else {
        const { message } = data.error;
        toast.error(message);
      }
    };

    const token = getQueryParameterByName("token");
    if (token && !verified.current) {
      verifyEmail(token);
    }
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      {/* Top Navigation */}
      <Navbar toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />

      {/* Mobile Menu */}
      {isMenuOpen && <MobileMenu toggleMenu={toggleMenu} />}

      <div className="flex-grow grid grid-cols-1 md:grid-cols-2">
        {/* Left Section */}
        <div
          className="relative flex flex-col p-8 bg-[#0f0c29] text-white left-section"
          style={{ minHeight: "575px" }}
        >
          <div className="flex flex-col justify-center h-full">
            <div className="flex flex-col gap-2">
              <div className="flex justify-center">
                <img
                  src={NewPassword}
                  alt="Reset Password"
                  className="w-3/12 sm:w-4/12 md:w-5/12 lg:w-6/12 object-contain"
                />
              </div>
              <h1 className="font-bold bg-gradient-to-r from-purple-300 to-white bg-clip-text text-center text-transparent text-[28px] lg:text-[44px]">
                Verifying Your Email
              </h1>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="relative flex flex-col items-center justify-center p-4 sm:p-8 bg-black text-white">
          <div className="flex-1 flex flex-col items-center justify-center w-full max-w-sm gap-6 lg:mt-[90px] mb-6">
            {/* Message */}
            {verified.current ? (
              <h2 className="text-2xl font-bold mb-6">
                Your Email Was Verified. Redirecting to Home Page
              </h2>
            ) : (
              <h2 className="text-2xl font-bold mb-6">Verifying Email...</h2>
            )}
          </div>

          {/* Footer */}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
