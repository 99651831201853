import "./styles.scss";
import { useState, useEffect, useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {} from "utils/globals";
import { ShipmentPlanDateSelectionContext } from "utils/context";
import { IShipmentPlan } from "pages/shipmentPlans/interfaces";
import { updateShipmentPlanProductRequest } from "../../pages/shipmentPlan/api";
import { QUERY_KEYS } from "utils";
import { IUpdateShipmentPlanProduct } from "pages/shipmentPlan/interfaces";
import { UserContext } from "utils/context";
import { IContextUser } from "common/interfaces";

interface IStepper {
  darkMode?: boolean;
  shipmentPlanData: IShipmentPlan;
}

const Stepper = ({ darkMode, shipmentPlanData }: IStepper) => {
  const [showPayPal, setShowPayPal] = useState(false);

  // shipment plan date selection context
  const [shipmentPlanDateSelectionFlag, setShipmentPlanDateSelectionFlag] =
    useState(true);
  const [shipmentPlanEstimateArrivalDate, setShipmentPlanEstimateArrivalDate] =
    useState(new Date());
  const [shipmentPlanDateSelection, setShipmentPlanDateSelection] = useState(
    new Date()
  );

  const [selectedDate, setSelectedDate] = useState<string | "">("");

  const [closeUserEdit, setCloseUserEdit] = useState(false);

  const [progress, setProgress] = useState(0);

  const queryClient = useQueryClient();

  const user: IContextUser = useContext(UserContext)!;
  const { email } = user;

  useEffect(() => {
    const areAllProductFieldsFilled =
      shipmentPlanData?.products?.every((product) => {
        return (
          product.boxHeight &&
          product.boxLength &&
          product.boxWidth &&
          product.boxes &&
          product.unitsPerBox &&
          product.fnsku &&
          product.upc &&
          product.weightPerBox
        );
      }) ?? false;

    //change this to the actual state of the orders
    //required the order table
    const ordersLinked = false;

    if (shipmentPlanData?.warehouseOwner?._id && !areAllProductFieldsFilled) {
      setProgress(25);
    }

    if (areAllProductFieldsFilled && !shipmentPlanData?.payment.paid) {
      setProgress(50);
    }

    if (
      areAllProductFieldsFilled &&
      ordersLinked &&
      !shipmentPlanData?.payment.paid
    ) {
      setProgress(75);
    }

    if (shipmentPlanData?.payment.paid) {
      setProgress(100);
      setCloseUserEdit(true);
    }
  }, [shipmentPlanData]);

  useEffect(() => {
    if (window.paypal) {
      const container = document.getElementById("paypal-button-container");

      if (container) {
        window.paypal
          .Buttons({
            style: {
              color: "gold",
              shape: "rect",
              size: "large",
              label: "pay",
            },
          })
          .render("#paypal-button-container");
      } else {
        // console.error("#paypal-button-container not found in the DOM.");
      }
    } else {
      console.error("PayPal SDK not loaded");
    }
  }, [showPayPal]);

  const {
    mutate: updateShipmentProduct,
    isLoading: isLoadingUpdateShipmentPlanProduct,
  } = useMutation(updateShipmentPlanProductRequest, {
    onSuccess: (data) => {
      if (data.status) {
        toast.success("Successfully updated shipment plan products.");
      }

      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.GET_SHIPMENT_PLAN,
      });
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
          "There was an error updating your plan products."
      );
    },
  });

  const handleShipmentDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedDate(event.target.value);
  };

  const submitDates = () => {
    setShipmentPlanDateSelectionFlag(false);
    const estimatedArrivalDate = new Date(selectedDate);
    const payload: IUpdateShipmentPlanProduct = {
      email,
      shipmentPlanId: shipmentPlanData._id,
      estimatedArrivalDate: estimatedArrivalDate,
    };
  };

  const closePopup = () => {
    setShipmentPlanDateSelectionFlag(false);
  };

  return (
    <div>
      <div className="steps-wrapper">
        <div
          style={{
            height: "20px",
            width: "100%",
            backgroundColor: "#e0e0e0",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: "100%",
              width: `${progress}%`,
              backgroundColor: "#28a745",
              transition: "width 0.3s ease",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
