import LogoBig from "assets/images/LogoPrincipal.png";

const PrivacyPolicy = () => {
  return (
    <div className="bg-black text-white font-sans flex flex-col justify-between min-h-screen p-5">
      <div className="bg-black text-white border-2 border-white rounded-lg p-8 w-full max-w-4xl mx-auto">
        <h1 className="text-2xl text-center">
          AMERICA DATA & SOFTWARE TECHNOLOGIES INC. PRIVACY POLICY
        </h1>
        <p className="text-lg text-center mt-5">
          <strong>Effective Date</strong>: August 1, 2022
        </p>

        <h2 className="text-xl mt-5">1. Information We Collect</h2>
        <p className="text-base leading-relaxed my-2">
          We collect personal information, including name, email, phone number,
          business details, and Amazon seller data through Amazon’s SP-API for
          the purpose of providing logistics services. We also collect payment
          information through third-party payment processors like Stripe, but do
          not store sensitive financial data directly.
        </p>

        <h2 className="text-xl mt-5">2. How We Use Your Information</h2>
        <p className="text-base leading-relaxed my-2">
          Your information is used for the following purposes:
        </p>
        <ul className="list-disc list-inside text-base my-2">
          <li>To process and fulfill orders and provide services.</li>
          <li>
            To communicate with you about product updates, service offerings,
            and account activity.
          </li>
          <li>To prevent fraud and unauthorized access.</li>
          <li>
            To share necessary information with third-party service providers
            like logistics partners, ensuring fulfillment of orders.
          </li>
        </ul>

        <h2 className="text-xl mt-5">3. Payment Handling</h2>
        <p className="text-base leading-relaxed my-2">
          Payments are processed through PCI-compliant third-party providers
          (e.g., Stripe). We do not store credit card details but retain records
          of payment transactions for account and billing purposes.
        </p>

        <h2 className="text-xl mt-5">4. Fraud Prevention</h2>
        <p className="text-base leading-relaxed my-2">
          We implement fraud detection systems to identify and prevent
          unauthorized transactions. This includes reviewing purchasing patterns
          and utilizing third-party tools for security monitoring.
        </p>

        <h2 className="text-xl mt-5">
          5. Identity Verification for Higher Logistics Limits
        </h2>
        <p className="text-base leading-relaxed my-2">
          To access higher logistics limits, you may need to complete an
          identity verification process. This is done to comply with regulatory
          requirements and prevent fraud. The process may require submitting
          personal details or documents for authentication.
        </p>

        <h2 className="text-xl mt-5">6. Data Sharing with Third Parties</h2>
        <p className="text-base leading-relaxed my-2">
          We may share your information with trusted third parties for the
          following purposes:
        </p>
        <ul className="list-disc list-inside text-base my-2">
          <li>
            Service Providers: To facilitate order fulfillment, customer
            service, and data processing.
          </li>
          <li>
            Suppliers and Logistics Partners: For inventory management and
            shipment tracking.
          </li>
          <li>
            Legal Compliance: To comply with legal obligations or respond to
            requests by law enforcement.
          </li>
        </ul>

        <h2 className="text-xl mt-5">7. Data Security</h2>
        <p className="text-base leading-relaxed my-2">
          We use advanced security measures including encryption (AES-256 at
          rest and TLS in transit) and multi-factor authentication (MFA) to
          protect your personal and financial data.
        </p>

        <h2 className="text-xl mt-5">8. Your Data Rights</h2>
        <p className="text-base leading-relaxed my-2">
          You have the right to access, update, correct, or delete your personal
          data. You can also opt out of receiving promotional communications at
          any time.
        </p>

        <h2 className="text-xl mt-5">
          9. Compliance with CCPA (California Consumer Privacy Act)
        </h2>
        <p className="text-base leading-relaxed my-2">
          If you are a California resident, you can request to know what
          personal data we have collected, request the deletion of your personal
          data, and opt out of any sale of your data (We do not sell your
          personal data).
        </p>

        <h2 className="text-xl mt-5">10. Children’s Privacy</h2>
        <p className="text-base leading-relaxed my-2">
          Our services are not intended for children under the age of 16. We do
          not knowingly collect personal information from children under this
          age. If we become aware of such information, we will delete it
          immediately.
        </p>

        <h2 className="text-xl mt-5">11. Updates to this Privacy Policy</h2>
        <p className="text-base leading-relaxed my-2">
          We may update this Privacy Policy periodically to reflect changes in
          our practices or legal requirements. Any significant changes will be
          posted here, and we will notify you if applicable.
        </p>

        <h2 className="text-xl mt-5">12. Contact Information</h2>
        <p className="text-base leading-relaxed my-2">
          If you have any questions or concerns regarding this Privacy Policy or
          your personal data, you can contact us at:
        </p>
        <ul className="list-disc list-inside text-base my-2">
          <li>Email: info@unielogics.com</li>
          <li>
            Mail: America Data & Software Technologies Inc., 823 Westfield Ave,
            Elizabeth, New Jersey.
          </li>
        </ul>
      </div>
      {/* Primer Footer */}
      <footer className="bg-black text-white py-5 text-center mt-auto">
        <div className="max-w-4xl mx-auto flex flex-col items-center gap-4">
          <a href="/">
            <img
              src={LogoBig}
              alt="Unielogics Logo"
              className="h-12 sm:h-16 w-auto"
            />
          </a>
          <div className="flex items-center gap-4 text-sm text-gray-500">
            <a
              href="https://docs.google.com/document/d/10apy8E_ith2dFrKrfeFMFCdTy14vx8lK/edit"
              className="hover:text-gray-400">
              Terms of Use
            </a>
            <span>|</span>
            <a href="/" className="hover:text-gray-400">
              Home
            </a>
          </div>
        </div>
      </footer>

      {/* Segundo Footer (Derechos de autor) */}
      <footer className="border-t border-gray-700 mt-8 pt-4">
        <p className="text-center text-sm">
          &copy; 2024 America Data & Software Technologies Inc. All rights
          reserved.
        </p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
