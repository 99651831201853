import {
	useStripe,
	useElements,
	CardElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { useState } from "react";
import { apiRoutes } from "utils";
import { toast } from "react-toastify";

type ShipmentPlanCheckoutProps = {
	handlePaymentStatusRedirect: () => void;
	totalPriceCb?: string;
};
const ShipmentPlanCheckout: React.FC<
	ShipmentPlanCheckoutProps
> = ({ totalPriceCb, handlePaymentStatusRedirect }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [isProcessing, setIsProcessing] = useState(false);
	const [cardholderName, setCardHolderName] = useState("");
	const [addressLine1, setAddressLine1] = useState("");
	const [addressLine2, setAddressLine2] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [email, setEmail] = useState("");

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}
		setIsProcessing(true);

		const cardElement = elements.getElement(CardElement);

		if (!cardElement) {
			console.error("CardElement not found!");
			setIsProcessing(false);
			return;
		}

		const { error, paymentMethod } =
			await stripe.createPaymentMethod({
				type: "card",
				card: cardElement,
				billing_details: {
					name: cardholderName,
					email: email,
					address: {
						line1: addressLine1,
						line2: addressLine2,
						city: city,
						state: state,
						postal_code: zipCode,
					},
				},
			});

		if (error) {
			console.error(
				"Error creating payment method:",
				error
			);
			setIsProcessing(false);
			return;
		}

		try {
			const { data } = await axios.post(
				apiRoutes.CREATE_PAYMENT,
				{
					paymentMethodId: paymentMethod.id,
					amount: totalPriceCb,
				}
			);
			if (data.status === "success") {
				toast.success(
					"All operations completed successfully!",
					{
						position: "top-right",
						autoClose: 2000,
					}
				);
				handlePaymentStatusRedirect(); // Trigger the redirect
			}
		} catch (error: any) {
			handlePaymentStatusRedirect();
			// toast.error(
			// 	`Operation failed: ${
			// 		error.message || "Unknown error"
			// 	}`,
			// 	{
			// 		position: "bottom-center",
			// 		autoClose: false,
			// 	}
			// );
		}
		setIsProcessing(false);
	};

	return (
		<>
			<div
				className="modal fade checkout-form"
				tabIndex={-1}
				id="checkoutModal"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
				data-bs-backdrop="static"
				data-bs-keyboard="false">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-body">
							<form
								className="row text-start"
								autoComplete="on">
								<div className="mb-23">
									<label>Cardholder Name</label>
									<input
										type="text"
										className="form-control"
										value={cardholderName}
										onChange={(e) =>
											setCardHolderName(e.target.value)
										}
										placeholder="Cardholder Name"
									/>
								</div>
								<div className="mb-23">
									<label>Address Line 1</label>
									<input
										type="text"
										className="form-control"
										value={addressLine1}
										onChange={(e) =>
											setAddressLine1(e.target.value)
										}
										placeholder="Address Line 1"
									/>
								</div>
								<div className="mb-23">
									<label>Address Line 2</label>
									<input
										type="text"
										className="form-control"
										value={addressLine2}
										onChange={(e) =>
											setAddressLine2(e.target.value)
										}
										placeholder="Address Line 2"
									/>
								</div>
								<div className="col-4">
									<label>City</label>
									<input
										type="text"
										className="form-control"
										value={city}
										onChange={(e) =>
											setCity(e.target.value)
										}
										placeholder="City"
									/>
								</div>
								<div className="col-4">
									<label>State</label>
									<input
										type="text"
										className="form-control"
										value={state}
										onChange={(e) =>
											setState(e.target.value)
										}
										placeholder="State"
									/>
								</div>
								<div className="col-4">
									<label>Zip Code</label>
									<input
										type="text"
										className="form-control"
										value={zipCode}
										onChange={(e) =>
											setZipCode(e.target.value)
										}
										placeholder="Zip Code"
									/>
								</div>

								<div>
									<label>Card details</label>
									<CardElement
										className="form-control"
										id="card"
										options={{
											hidePostalCode: true,
											style: {
												base: {
													fontSize: "16px",
													color: "white",
													"::placeholder": {
														color: "#aab7c4",
													},
												},
											},
										}}
									/>
								</div>
								<div className="col-12">
									<label>Email</label>
									<input
										type="text"
										className="form-control"
										value={email}
										onChange={(e) =>
											setEmail(e.target.value)
										}
										placeholder="Email"
									/>
								</div>
								<div className="d-flex justify-content-end pay-checkout">
									<button
										type="button"
										className="btn btn-secondary mr-3"
										data-bs-dismiss="modal">
										Close
									</button>{" "}
									<button
										type="button"
										className="btn btn-primary"
										data-bs-dismiss="modal"
										onClick={handleSubmit}
										disabled={isProcessing || !stripe}>
										{isProcessing
											? "Processing..."
											: "Confirm"}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ShipmentPlanCheckout;
