import { useContext, useEffect, useRef, useState } from "react";
import {
  Footer,
  FullScreenLoading,
  Loading,
  Modal,
  ProfitableProductsList,
  SidebarMenu,
} from "components";

import "./styles.scss";
import {
  QUERY_KEYS,
  UserContext,
  localStorageNames,
  pageRoutes,
  useDebounce,
} from "utils";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  changeDefaultRequest,
  deleteFolderRequest,
  deleteItemFromFolderRequest,
  editFolderRequest,
  getFoldersRequest,
  postAddFolderRequest,
  postShipmentPlan,
  updateSupplierProductDetailsRequest,
  updateSupplierRequest,
} from "./api";
import {
  IChangeDefaultFolderResponse,
  IDeleteFolderResponse,
  IDeleteItemFromFolderResponse,
  IEditFolderResponse,
  IFolder,
  ILocalStorageShipmentItems,
  IPostAddFolderResponse,
  IPostShipmentPlanProducts,
  IPostShipmentPlanRequest,
  IUpdateSupplierResponse,
} from "./interfaces";
import { toast } from "react-toastify";
import {
  IDeleteItemCB,
  IItemInFolder,
  IShipmentPlanAdditionalInfo,
} from "components/ProfitableProductsList/interfaces";
import { ISupplier } from "pages/suppliers/interfaces";
import ShipmentPlanModalList from "components/ShipmentPlanModalList";
import { IContextUser } from "common/interfaces";
import { isEmpty, set } from "lodash";
import { useNavigate } from "react-router-dom";
import { totalmem } from "os";

const ProfitableProducts = () => {
  const user: IContextUser = useContext(UserContext)!;
  const editFolderNameRef = useRef(null);
  const { email } = user;

  const [editedFolderDetails, setEditedFolderDetails] = useState({
    folderName: "",
    folderId: "",
  });
  const [showFolderEdit, setShowFolderEdit] = useState("");
  const [showNewFolderModal, setShowNewFolderModal] = useState(false);
  const [createFolderName, setCreateFolderName] = useState("");
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
  const [currentFolderForDeletion, setCurrentFolderForDeletion] = useState("");
  const [createFolderError, setCreateFolderError] = useState(false);
  const [editFolderError, setEditFolderError] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState("");
  const [selectedNewAction, setSelectedNewAction] = useState(false);
  const [showDefaultFolderModal, setShowDefaultFolderModal] = useState(false);
  const [showCreateShipmentPlanModal, setShowCreateShipmentPlanModal] =
    useState(false);
  const [defaultFolderId, setDefaultFolderId] = useState("");
  const [shipmentPlanItems, setShipmentPlanItems] = useState<IItemInFolder[]>(
    []
  );
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [shipmentPlanErrors, setShipmentPlanErrors] = useState<string>("");
  const [searchASINCodeTerm, setSearchASINCodeTerm] = useState<string>("");
  const [profitableProducts, setProfitableProducts] = useState<IItemInFolder[]>(
    []
  );
  const [ASINCodeResults, setASINCodeResults] = useState<IItemInFolder[]>([]);
  const [currentPage, setCurrentPage] = useState(1); // Added state for pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const debounceASINCode = useDebounce(searchASINCodeTerm, 500);

  const shipmentTitleRef = useRef<HTMLInputElement>(null);
  const orderNoRef = useRef<HTMLInputElement>(null);
  const isShipmentPlanDisabled = shipmentPlanItems.length > 0 ? false : true;

  const { data, isLoading: isLoadingGetFolders } = useQuery(
    QUERY_KEYS.GET_FOLDERS,
    () => getFoldersRequest(email),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [folders, setFolders] = useState<IFolder[]>([]);

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const generateColor = () => {
    return `#${Math.random().toString(16).substr(-6)}`;
  };

  const { mutate: postAddFolder, isLoading: isLoadingPostAddFolder } =
    useMutation(postAddFolderRequest, {
      onSuccess: (data: IPostAddFolderResponse) => {
        if (data.status === "success") {
          toast.success("Successfully added folder.");
          setFolders(data.response.folders);
        } else {
          toast.error("There was an error creating your folder.");
        }
        return;
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
          "There was an error creating your folder."
        );
        return;
      },
    });

  const { mutate: deleteFolder, isLoading: isLoadingDeleteFolder } =
    useMutation(deleteFolderRequest, {
      onSuccess: (data: IDeleteFolderResponse) => {
        if (data.status === "success") {
          toast.success("Successfully deleted folder.");
          setFolders(data.response.folders);
          setSelectedFolderId(data?.response?.folders[0]._id);
        } else {
          toast.error("There was an error deleting your folder.");
        }
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
          "There was an error deleting your folder."
        );
        return;
      },
    });

  const { mutate: editFolder, isLoading: isLoadingEditFolder } = useMutation(
    editFolderRequest,
    {
      onSuccess: (data: IEditFolderResponse) => {
        if (data.status) {
          toast.success("Successfully renamed your folder.");
          setFolders(data.response.folders);
          setShowFolderEdit("");
          setEditedFolderDetails({
            folderId: "",
            folderName: "",
          });
        }
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
          "There was an error editing your folder name."
        );
        return;
      },
    }
  );

  const {
    mutate: changeDefaultFolder,
    isLoading: isLoadingChangeDefaultFolder,
  } = useMutation(changeDefaultRequest, {
    onSuccess: (data: IChangeDefaultFolderResponse) => {
      if (data.status) {
        toast.success("Successfully changed default folder.");
        setFolders(data.response.folders);
      }
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
        "There was an error changing the default folder."
      );
      return;
    },
  });

  const {
    mutate: deleteItemFromFolder,
    isLoading: isLoadingDeleteItemFromFolder,
  } = useMutation(deleteItemFromFolderRequest, {
    onSuccess: (data: IDeleteItemFromFolderResponse) => {
      if (data.status) {
        toast.success("Successfully deleted item from folder.");
        setFolders(data.response.folders);
      }
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
        "There was an error editing your folder name."
      );
      return;
    },
  });

  const { mutate: updateSupplierProductDetails } = useMutation(
    updateSupplierProductDetailsRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.GET_FOLDERS,
        });
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
          "There was an error updating the supplier."
        );
        return;
      },
    }
  );

  const { mutate: updateSupplierForProduct } = useMutation(
    updateSupplierRequest,
    {
      onSuccess: (supplierSuccessData: IUpdateSupplierResponse) => {
        if (supplierSuccessData.status) {
          toast.success("Successfully supplier for selected item.");
          setFolders(supplierSuccessData?.response?.folders);
        }
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
          "There was an error updating the supplier."
        );
        return;
      },
    }
  );

  const {
    mutate: postShipmentPlanMutation,
    isLoading: isLoadingPostShipmentPlan,
  } = useMutation(postShipmentPlan, {
    onSuccess: (shipmentPlanSuccessData) => {
      const planId = shipmentPlanSuccessData.response.planId;
      const updatedFolders = folders.map((folder: IFolder) => {
        folder.folderItems.forEach((folderItem) => {
          if (folderItem.isChecked) {
            folderItem.isChecked = false;
          }
        });
        return folder;
      });

      setFolders(updatedFolders);
      setShipmentPlanItems([]);
      setShowCreateShipmentPlanModal(false);
      toast.success(shipmentPlanSuccessData.message);

      navigate(`${pageRoutes.WAREHOUSES_MAP_PLAN_ID}?id=${planId}`);
    },
    onError: (error: any) => {
      const defaultMessage = "There was an error adding the shipment plan.";
      const errorMessage =
        error.response?.data?.message &&
          error.response?.data?.response?.shipmentTitle
          ? `${error.response.data.message}${error.response.data.response.shipmentTitle}`
          : defaultMessage;

      toast.error(errorMessage);
    },
  });

  const onFolderNameChange = (id: string, createFolderName: string) => {
    setEditFolderError(false);

    setEditedFolderDetails({
      folderId: id,
      folderName: createFolderName,
    });
  };

  const onFolderEditSubmit = () => {
    if (!editedFolderDetails.folderName) {
      setEditFolderError(true);
    } else {
      setShowFolderEdit("");
      editFolder({
        email,
        folderId: editedFolderDetails.folderId,
        folderName: editedFolderDetails.folderName,
      });
    }
  };

  const folderInput = () => {
    return (
      <div className="dark-input my-3">
        <input
          type="text"
          className={`add-new-folder-input simple-input mb-2 ${createFolderError && "error-input"
            }`}
          onChange={(e) => {
            setCreateFolderError(false);
            setCreateFolderName(e.target.value);
          }}
          value={createFolderName}
          placeholder="Name of the folder (category)"
        />
        {createFolderError && (
          <p className="error">Name of the folder cannot be empty.</p>
        )}
      </div>
    );
  };

  const onCreateFolderConfirmClick = () => {
    if (!createFolderName) {
      setCreateFolderError(true);
    } else {
      setShowNewFolderModal(false);
      postAddFolder({
        email,
        folderName: createFolderName,
        folderColor: generateColor(),
      });
    }
  };

  const onClickDeleteFolder = (folderId: string) => {
    setShowDeleteFolderModal(true);
    setCurrentFolderForDeletion(folderId);
  };

  const onModalConfirmDeleteFolderClick = () => {
    deleteFolder({
      email,
      folderId: currentFolderForDeletion,
    });
    setShowDeleteFolderModal(false);
  };

  const onDeleteItemClickCb = ({ folderId, folderItemId }: IDeleteItemCB) => {
    deleteItemFromFolder({ email, folderId, folderItemId });
  };

  const onChangeDefaultFolderClick = (folderId: string) => {
    setDefaultFolderId(folderId);
    setShowDefaultFolderModal(true);
  };

  const onModalSetNewDefaultFolderClick = () => {
    setShowDefaultFolderModal(false);
    changeDefaultFolder({
      email,
      folderId: defaultFolderId,
      folderSelected: true,
    });
  };

  const onSelectProductsForShipmentPlanClickCb = (
    selectedItem: IItemInFolder
  ) => {
    const itemIndex = shipmentPlanItems.findIndex(
      (item: IItemInFolder) => item._id === selectedItem._id
    );
    const isTheSameASIN =
      shipmentPlanItems.length > 0 &&
      shipmentPlanItems.some(
        (item: IItemInFolder) =>
          item.asin === selectedItem.asin && item._id !== selectedItem._id
      );
    const isTheSameSupplier =
      shipmentPlanItems.length > 0 &&
      shipmentPlanItems.some(
        (item: IItemInFolder) =>
          item.supplier.supplierName !== selectedItem.supplier.supplierName
      );

    if (isTheSameSupplier) {
      toast.error("Please select products with the same supplier.");
    } else if (isTheSameASIN) {
      toast.error("A product with same ASIN is already selected.");
    } else if (!isEmpty(selectedItem.supplier.supplierName)) {
      const updatedFolders = folders.map((folder: IFolder) => {
        folder.folderItems.forEach((folderItem) => {
          if (folderItem._id === selectedItem._id) {
            folderItem.isChecked = !folderItem.isChecked;
            folderItem.unitsPerBox = "";
            folderItem.width = "";
            folderItem.height = "";
            folderItem.length = "";
          }
        });

        return folder;
      });

      setFolders(updatedFolders);

      if (itemIndex !== -1) {
        const updatedItems = [...shipmentPlanItems];
        updatedItems.splice(itemIndex, 1);
        setShipmentPlanItems(updatedItems);
        const savedSelectedItems = {
          selectedFolderId,
          updatedItems,
        };
        localStorage.setItem(
          localStorageNames.selectedShipmentPlans,
          JSON.stringify(savedSelectedItems)
        );
      } else {
        const updatedItems = [...shipmentPlanItems, selectedItem];
        const savedSelectedItems = {
          selectedFolderId,
          updatedItems,
        };
        localStorage.setItem(
          localStorageNames.selectedShipmentPlans,
          JSON.stringify(savedSelectedItems)
        );
        setShipmentPlanItems(updatedItems);
      }
    } else {
      toast.error(
        "Please select a supplier first, before adding the product to shipment plan."
      );
    }
  };

  const onDeleteSelectedProductCb = (id: string) => {
    const updatedFolders = folders.map((folder: IFolder) => {
      folder.folderItems.forEach((folderItem) => {
        if (folderItem._id === id) {
          folderItem.isChecked = !folderItem.isChecked;
        }
      });
      return folder;
    });
    setFolders(updatedFolders);
    setShipmentPlanItems(shipmentPlanItems.filter((item) => item._id !== id));
  };

  const handleValidation = () => {
    if (isEmpty(shipmentTitleRef.current?.value)) {
      setShipmentPlanErrors("shipment-title");
    }
  };

  const handleInputChange = (
    productIndex: number | undefined = 0,
    fieldName: keyof IShipmentPlanAdditionalInfo | undefined,
    value: string
  ) => {
    if (
      shipmentPlanErrors.includes("shipment-title") &&
      !isEmpty(shipmentTitleRef.current?.value)
    ) {
      setShipmentPlanErrors("");
    }

    if (fieldName) {
      const updatedProducts = [...shipmentPlanItems];
      updatedProducts[productIndex][fieldName] = value;
      setShipmentPlanItems(updatedProducts);
    }
  };

  const onSubmitProductsForShipmentPlan = () => {
    if (isEmpty(shipmentTitleRef.current?.value)) {
      setShipmentPlanErrors("shipment-title");
      handleValidation();
    } else {
      const products: IPostShipmentPlanProducts[] = shipmentPlanItems.map(
        ({
          title,
          asin,
          date,
          price,
          _id,
          supplier,
          imageUrl,
          unitsPerBox,
          width,
          height,
          length,
        }) => ({
          asin: asin,
          title: title,
          unitsPerBox,
          dateAdded: date,
          boxWidth: width,
          boxHeight: height,
          boxLength: length,
          amazonPrice: price,
          imageUrl,
          supplier: {
            _id: _id,
            onlineSupplier: supplier.onlineSupplier,
            supplierName: supplier.supplierName,
            supplierAddress: supplier.supplierAddress,
            supplierLink: supplier.supplierLink,
            contactPerson: supplier.contactPerson,
          },
        })
      );
      const supplier = shipmentPlanItems[0].supplier;
      const shipmentTitle = shipmentTitleRef.current!!.value;
      const orderNo = orderNoRef.current?.value || "";
      const payload: IPostShipmentPlanRequest = {
        email,
        shipmentTitle,
        orderNo,
        orderDate: startDate!!.toISOString(),
        products,
        supplier,
      };

      setShipmentPlanErrors("");
      postShipmentPlanMutation(payload);
    }
  };

  const onSupplierChange = (
    supplier: ISupplier | undefined,
    folderId: string,
    productId: string,
    currentItem: IItemInFolder
  ) => {
    if (supplier) {
      updateSupplierForProduct({
        supplier,
        folderId,
        productId,
      });
      updateSupplierProductDetails({
        email,
        asin: currentItem.asin,
        title: currentItem.title,
        imageUrl: currentItem.imageUrl,
        price: currentItem.price,
        folderId,
        supplier,
      });
    } else {
      toast.error("There was an error submiting your supplier update");
    }
  };

  const searchASINCode = (item: string) => {
    setSearchASINCodeTerm(item);
  };

  useEffect(() => {
    if (!isEmpty(debounceASINCode)) {
      setASINCodeResults(
        profitableProducts.filter((planItem) =>
          planItem.asin.toUpperCase().includes(debounceASINCode.toUpperCase())
        )
      );
    } else setASINCodeResults([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceASINCode]);

  useEffect(() => {
    if (data?.response?.folders) {
      data.response?.folders.forEach((item) =>
        item.folderItems.forEach((folderItem) => (folderItem.isChecked = false))
      );
      const allProfitableProducts: IItemInFolder[] = [];
      data.response?.folders.forEach((item) =>
        item.folderItems.forEach((folderItem) =>
          allProfitableProducts.push(folderItem)
        )
      );

      setProfitableProducts(allProfitableProducts);

      const selectedShipmentPlans: ILocalStorageShipmentItems = JSON.parse(
        localStorage.getItem(localStorageNames.selectedShipmentPlans) || "{}"
      );

      if (!isEmpty(selectedShipmentPlans)) {
        const updatedData: IFolder[] = [];
        const updatedShipmentPlanItems: IItemInFolder[] = [];
        data?.response?.folders.forEach((folder) => {
          folder.folderItems.forEach((item) => {
            selectedShipmentPlans.updatedItems?.forEach((selectedItem) => {
              if (selectedItem._id === item._id && item.supplier.supplierName) {
                item.isChecked = selectedItem.isChecked;
                updatedShipmentPlanItems.push(item);
              }
            });
          });
          updatedData.push(folder);
        });

        setShipmentPlanItems(updatedShipmentPlanItems);
        setFolders(updatedData);
        setSelectedFolderId(selectedShipmentPlans.selectedFolderId);
      } else {
        setFolders(data?.response?.folders);
        setSelectedFolderId(data?.response?.folders[0]._id);
      }
    }
  }, [data]);

  useEffect(() => {
    if (isEmpty(shipmentPlanItems)) {
      setShowCreateShipmentPlanModal(false);
    }
  }, [shipmentPlanItems]);

  useEffect(() => {
    const selectedShipmentPlans: ILocalStorageShipmentItems = JSON.parse(
      localStorage.getItem(localStorageNames.selectedShipmentPlans) || "{}"
    );
    selectedShipmentPlans.selectedFolderId = selectedFolderId;
    localStorage.setItem(
      localStorageNames.selectedShipmentPlans,
      JSON.stringify(selectedShipmentPlans)
    );
  }, [selectedFolderId]);

  useEffect(() => {
    localStorage.removeItem(localStorageNames.selectedShipmentPlans);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1600) {
        setItemsPerPage(18);
      } else if (window.innerWidth >= 1025) {
        setItemsPerPage(10);
      } else if (window.innerWidth >= 640) {
        setItemsPerPage(5);
      } else {
        setItemsPerPage(18);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getTotalItems = () => {
    return !isEmpty(ASINCodeResults)
      ? ASINCodeResults.length
      : folders.find(
        (folder: IFolder) =>
          JSON.stringify(folder._id) === JSON.stringify(selectedFolderId)
      )?.folderItems.length || 0;
  };

  const getFirstItemIndexOnPage = () => {
    return (currentPage - 1) * itemsPerPage + 1;
  };

  const getLastItemIndexOnPage = () => {
    return Math.min(currentPage * itemsPerPage, getTotalItems());
  };

  const toggleDropdown = () => {
    setSelectedNewAction(!selectedNewAction);
  };

  const handleAddProductsClick = () => {
    navigate("/profitable-products/add-products");
  };

  return (
    <>
      <div className="content">
        {isLoadingPostShipmentPlan && <FullScreenLoading />}

        <div className="container-fluid profitableProducts">
          <SidebarMenu
            active="profitable-products"
            setSearchTerm={searchASINCode}
          />
          <div className="w-full">
            <div className="flex justify-between items-start mb-4 gap-4">
              {isEmpty(ASINCodeResults) && (
                // TEST CODES
                <div className="folder-select-dropdown">
                  {isLoadingPostAddFolder ||
                    isLoadingDeleteFolder ||
                    isLoadingChangeDefaultFolder ? (
                    <Loading />
                  ) : folders.length === 0 ? (
                    <div className="no-folders text-center py-8">
                      <h3 className="text-xl font-semibold text-white mb-2">
                        Start Organizing
                      </h3>
                      <br />
                      <p className="text-gray-400">
                        Tap the 'Add' Button to Create a Folder and Begin
                        Organizing Your Products into Categories!
                      </p>
                    </div>
                  ) : (
                    <select
                      className="folder-dropdown bg-[#1a1a1a] text-white p-2 rounded w-full"
                      value={selectedFolderId}
                      onChange={(e) => setSelectedFolderId(e.target.value)}>
                      {folders.map((folder: IFolder) => (
                        <option
                          className="options"
                          key={folder._id}
                          value={folder._id}>
                          {folder.folderName.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                // TEST CIDE
              )}
              <div className="flex gap-2">
                {/* select dropdown for add products and new folder */}
                <div className="add-action-dropdown">
                  <button
                    onClick={toggleDropdown}
                    className="product-action-button bg-[#4F46E5] hover:bg-[#4338CA] text-white rounded-lg ">
                    Add ✚
                  </button>

                  {selectedNewAction && (
                    <div className="add-action-dropdown-content">
                      <button
                        onClick={handleAddProductsClick}
                        className="bg-[#4F46E5] hover:bg-[#4338CA] text-white rounded-lg">
                        Add Products
                      </button>
                      <button
                        onClick={() => {
                          setCreateFolderName("");
                          setShowNewFolderModal(!showNewFolderModal);
                        }}
                        className="bg-[#4F46E5] hover:bg-[#4338CA] text-white rounded-lg">
                        New Folder
                      </button>
                    </div>
                  )}
                </div>
                <button
                  onClick={() => setShowCreateShipmentPlanModal(true)}

                  className={`product-action-button rounded-lg ${
                    isShipmentPlanDisabled
                      ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                      : "bg-[#4CAF50] hover:bg-[#45a049] text-white"
                  }`}
                  disabled={isShipmentPlanDisabled}>

                  Start Shipment
                </button>
                <button className="product-action-button bg-[#4CAF50] hover:bg-[#45a049] text-white rounded-lg">
                  Actions ▶
                </button>
              </div>
            </div>
            {showNewFolderModal && (
              <Modal
                okButtonText="Create"
                modalTitle="Add new folder"
                modalTextContent="Please enter the name of the folder that you want to create"
                children={folderInput()}
                okModalClick={onCreateFolderConfirmClick}
                cancelModalClick={() => setShowNewFolderModal(false)}
              />
            )}
            {showDeleteFolderModal && (
              <Modal
                okButtonText="Delete"
                modalTitle="Are you sure you want to delete this folder?"
                modalTextContent="Please be advised that selecting 'Delete' will result in the permanent removal of the folder, along with all of its contents."
                okModalClick={onModalConfirmDeleteFolderClick}
                cancelModalClick={() => setShowDeleteFolderModal(false)}
              />
            )}
            {showDefaultFolderModal && (
              <Modal
                okButtonText="Set new default"
                modalTitle="Are you sure you want to change the default folder?"
                modalTextContent="If you change the default folder, whenever you will add a new profitable product from the chrome extension, 
              it will go directly to this default folder. Of course this will change once you select a new folder."
                okModalClick={onModalSetNewDefaultFolderClick}
                cancelModalClick={() => setShowDefaultFolderModal(false)}
              />
            )}
            {showCreateShipmentPlanModal && (
              <Modal
                modalDialogStyle={{ maxWidth: "800px" }}
                okButtonText="Create"
                okModalClick={onSubmitProductsForShipmentPlan}
                modalTitle="Confirm Shipment Plan"
                children={
                  <ShipmentPlanModalList
                    shipmentPlanItems={shipmentPlanItems}
                    handleDelete={onDeleteSelectedProductCb}
                    shipmentTitleRef={shipmentTitleRef}
                    shipmentPlanErrors={shipmentPlanErrors}
                    handleInputChange={handleInputChange}
                    orderNoRef={orderNoRef}
                    startDate={startDate}
                    setStartDateCb={setStartDate}
                  />
                }
                cancelModalClick={() => {
                  setShowCreateShipmentPlanModal(false);
                  setShipmentPlanErrors("");
                }}
                showCancelButton={true}
              />
            )}
            <div className="row">
              {isLoadingGetFolders ? (
                <Loading />
              ) : (
                <>
                  <div className="col-12">
                    {folders.find(
                      (folder: IFolder) =>
                        JSON.stringify(folder._id) ===
                        JSON.stringify(selectedFolderId)
                    )?.folderItems.length === 0 ? (
                      <div className="no-items text-center">
                        <h3>This Folder is Empty!</h3>
                        <p>Add Products To This List</p>
                        <span className="no-items-icon-wrapper">
                          <i className="fas fa-hand-holding-usd"></i>
                        </span>
                      </div>
                    ) : isLoadingDeleteItemFromFolder ? (
                      <div className="input-loading-wrapper">
                        <Loading />
                      </div>
                    ) : (
                      <>
                        <ProfitableProductsList
                          itemsInFolder={
                            !isEmpty(ASINCodeResults)
                              ? ASINCodeResults.slice(

                                  (currentPage - 1) * itemsPerPage,
                                  currentPage * itemsPerPage
                                )
                              : folders
                                  .find(
                                    (folder: IFolder) =>
                                      JSON.stringify(folder._id) ===
                                      JSON.stringify(selectedFolderId)
                                  )
                                  ?.folderItems.slice(
                                    (currentPage - 1) * itemsPerPage,
                                    currentPage * itemsPerPage
                                  )

                          }
                          onDeleteItemClickCb={onDeleteItemClickCb}
                          onSelectProductsForShipmentPlanClickCb={
                            onSelectProductsForShipmentPlanClickCb
                          }
                          onSupplierChange={(
                            supplier,
                            folderId,
                            productId,
                            currentItem
                          ) =>
                            onSupplierChange(
                              supplier,
                              folderId,
                              productId,
                              currentItem
                            )
                          }
                        />
                        <div className="flex justify-center items-center product-floating-window">
                          <button
                            onClick={() =>
                              setCurrentPage((prev) => Math.max(prev - 1, 1))
                            }
                            disabled={currentPage === 1}
                            className="px-3 py-1 bg-[#1a1a1a] hover:bg-[#333333] text-white rounded-lg mr-2">
                            <i className="fas fa-angle-double-left"></i>
                          </button>
                          {(() => {
                            const totalItems = !isEmpty(ASINCodeResults)
                              ? ASINCodeResults.length
                              : folders.find(

                                  (folder: IFolder) =>
                                    JSON.stringify(folder._id) ===
                                    JSON.stringify(selectedFolderId)
                                )?.folderItems.length || 0;
                            const totalPages = Math.ceil(
                              totalItems / itemsPerPage
                            );

                            const pageButtons = [];
                            const maxVisiblePages = 5;

                            if (totalPages <= maxVisiblePages) {
                              for (let i = 1; i <= totalPages; i++) {
                                pageButtons.push(
                                  <button
                                    key={i}
                                    onClick={() => setCurrentPage(i)}

                                    className={`px-2 py-1 mx-0.5 text-sm rounded-md ${
                                      currentPage === i
                                        ? "bg-[#25508D] text-white"
                                        : "bg-[#1A1A1A] hover:bg-[#333333] text-white"
                                    }`}>

                                    {i}
                                  </button>
                                );
                              }
                            } else {
                              // Add first page
                              pageButtons.push(
                                <button
                                  key={1}
                                  onClick={() => setCurrentPage(1)}

                                  className={`px-2 py-1 mx-0.5 text-sm rounded-md ${
                                    currentPage === 1
                                      ? "bg-[#25508D] text-white"
                                      : "bg-[#1A1A1A] hover:bg-[#333333] text-white"
                                  }`}>

                                  1
                                </button>
                              );

                              // Add ellipsis if necessary
                              if (currentPage > 3) {
                                pageButtons.push(
                                  <span key="ellipsis1" className="mx-1">
                                    ...
                                  </span>
                                );
                              }

                              // Add pages around current page
                              for (
                                let i = Math.max(2, currentPage - 1);
                                i <= Math.min(currentPage + 1, totalPages - 1);
                                i++
                              ) {
                                pageButtons.push(
                                  <button
                                    key={i}
                                    onClick={() => setCurrentPage(i)}

                                    className={`px-2 py-1 mx-0.5 text-sm rounded-md ${
                                      currentPage === i
                                        ? "bg-[#25508D] text-white"
                                        : "bg-[#1A1A1A] hover:bg-[#333333] text-white"
                                    }`}>
                                    {i}
                                  </button>
                                );
                              }

                              // Add ellipsis if necessary
                              if (currentPage < totalPages - 2) {
                                pageButtons.push(
                                  <span key="ellipsis2" className="mx-1">
                                    ...
                                  </span>
                                );
                              }

                              // Add last page
                              pageButtons.push(
                                <button
                                  key={totalPages}
                                  onClick={() => setCurrentPage(totalPages)}

                                  className={`px-2 py-1 mx-0.5 text-sm rounded-md ${
                                    currentPage === totalPages
                                      ? "bg-[#25508D] text-white"
                                      : "bg-[#1A1A1A] hover:bg-[#333333] text-white"
                                  }`}>

                                  {totalPages}
                                </button>
                              );
                            }
                            return pageButtons;
                          })()}
                          <button
                            onClick={() => {
                              const totalItems = !isEmpty(ASINCodeResults)
                                ? ASINCodeResults.length
                                : folders.find(

                                    (folder: IFolder) =>
                                      JSON.stringify(folder._id) ===
                                      JSON.stringify(selectedFolderId)
                                  )?.folderItems.length || 0;
                              const totalPages = Math.ceil(
                                totalItems / itemsPerPage
                              );

                              setCurrentPage((prev) =>
                                Math.min(prev + 1, totalPages)
                              );
                            }}
                            disabled={
                              currentPage ===
                              Math.ceil(
                                (!isEmpty(ASINCodeResults)
                                  ? ASINCodeResults.length
                                  : folders.find(

                                      (folder: IFolder) =>
                                        JSON.stringify(folder._id) ===
                                        JSON.stringify(selectedFolderId)
                                    )?.folderItems.length || 0) / itemsPerPage

                              )
                            }
                            className="px-3 py-1 bg-[#1a1a1a] hover:bg-[#333333] text-white rounded-lg ml-2">
                            <i className="fas fa-angle-double-right"></i>
                          </button>
                          <div className="ms-1">
                            <p>
                              Results:{getFirstItemIndexOnPage()} -{" "}
                              {getLastItemIndexOnPage()} of {getTotalItems()}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProfitableProducts;
