import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";
import { markPaymentAsPaidRequest } from "pages/shipmentPlan/api";

import {
  FbaAndOtherFilesUpload,
  Footer,
  FullScreenLoading,
  ShipmentPlanProduct,
  SidebarMenu,
  Stepper,
  FloatingWindow,
  Modal,
  AddOrderModal,
} from "components";
import {
  QUERY_KEYS,
  UserContext,
  getQueryParameterByName,
  pageRoutes,
} from "utils";
import {
  deleteShipmentPlanFileRequest,
  deleteShipmentPlanProductRequest,
  getShipmentPlanRequest,
  postShipmentPlanFilesRequest,
  postShipmentPlanPaymentRequest,
  updateShipmentPlanProductRequest,
} from "./api";
import { IContextUser } from "common/interfaces";
import { IShipmentPlan } from "pages/shipmentPlans/interfaces";
import { isEmpty, isEqual, set } from "lodash";
import {
  FileTypes,
  IAdditionalShipmentInputs,
  IDeleteShipmentPlanProductResponse,
  IPayShipmentPlan,
  IShipmentPlanFiles,
  IUpdateShipmentPlanProduct,
} from "./interfaces";
import { useNavigate } from "react-router-dom";
import { pricing } from "./pricing";
import "./styles.scss";
import { getAreFieldsInvalid } from "./utils";
import useShipmentPlanStore from "utils/zustandStore/useShipmentPlanStore";
import AmazonDataCollapse from "components/ShipmentPlan/AmazonDataCollapse";

const ShipmentPlan: React.FC = () => {
  const planId = getQueryParameterByName("id");
  const user: IContextUser = useContext(UserContext)!;
  const { email } = user;
  const {
    updateData: updateShipmentPlanStore,
    setData: setShipmentPlanData,
    data: shipmentPlanData,
  } = useShipmentPlanStore();

  const handleSetShipmentPlanStore = (shipmentPlanData: any) => {
    setShipmentPlanData({ shipmentPlanData });
    // updateShipmentPlanStore((prevData: any) => ({
    //   ...prevData,
    //   shipmentPlan: shipmentPlanData,
    // }));
  };

  const handleUpdateShipmentPlanStore = (shipmentPlanData: any) => {
    updateShipmentPlanStore((prevData: any) => ({
      ...prevData,
      shipmentPlan: shipmentPlanData,
    }));
  };

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [shipmentPlan, setShipmentPlan] = useState<IShipmentPlan[]>([]);
  const [shipmentAdditionalInputs, setShipmentAdditionalInputs] =
    useState<IAdditionalShipmentInputs>({});
  const [areFieldsEmpty, setAreFieldsEmpty] = useState(false);
  const [initialEnteredValues, setInitialEnteredValues] = useState<string[]>(
    []
  );
  const [fbaFiles, setFbaFiles] = useState<IShipmentPlanFiles[]>([]);
  const [otherFiles, setOthersFile] = useState<IShipmentPlanFiles[]>([]);
  const [titleInput, setTitleInput] = useState<string>("");
  const [shouldDisplayEditTitleInput, setShouldDisplayEditTitleInput] =
    useState(false);
  const [orderNoInput, setOrderNoInput] = useState<string>("");
  const [receiptNo, setReceiptNo] = useState<string>("");
  const [orderDate, setOrderDate] = useState<Date | null>(null);
  const [shouldDisplayOrderNoError, setShouldDisplayOrderNoError] =
    useState(false);
  const [packagingLimitsError, setPackagingLimitsError] = useState<
    Record<string, boolean>
  >({});
  const [showAdditionalPlanInputs, setShowAdditionalPlanInputs] = useState<
    Record<string, boolean>
  >({});
  const [shouldGreyOutPaymentBtn, setShouldGreyOutPaymentBtn] = useState(true);
  const [totalPriceCb, setTotalPriceCb] = useState<string>("-");
  const [loading, setLoading] = useState(true);
  const [activeComponent, setActiveComponent] = useState<"confirm" | "link">(
    "confirm"
  );
  const [showAddOrderModal, setShowAddOrderModal] = useState(false);
  const [showModifyOrder, setShowModifyOrder] = useState(false);

  const [orders, setOrders] = useState<
    { orderID: string; products: string[]; shipFrom: string; shipTo: string }[]
  >([]);

  const {
    data,
    error,
    isLoading: isLoadingShipmentPlan,
  } = useQuery(
    QUERY_KEYS.GET_SHIPMENT_PLAN,
    () => getShipmentPlanRequest(email, planId),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const {
    mutate: updateShipmentProduct,
    isLoading: isLoadingUpdateShipmentPlanProduct,
  } = useMutation(updateShipmentPlanProductRequest, {
    onSuccess: (data) => {
      if (data.status) {
        toast.success("Successfully updated shipment plan products.");
      }

      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.GET_SHIPMENT_PLAN,
      });
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
          "There was an error updating your plan products."
      );
    },
  });

  const {
    mutate: deleteShipmentPlanProduct,
    isLoading: isLoadingDeleteShipmentPlanProduct,
  } = useMutation(deleteShipmentPlanProductRequest, {
    onSuccess: (data: IDeleteShipmentPlanProductResponse) => {
      if (data.status) {
        toast.success("Successfully deleted product from shipment plan.");
        if (isEmpty(data?.response[0]?.products)) {
          window.location.href = pageRoutes.SHIPMENT_PLANS;
        } else
          queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.GET_SHIPMENT_PLAN,
          });
      }
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
          "There was an error deleting your product."
      );
      return;
    },
  });

  const {
    mutate: postShipmentPlanFiles,
    isLoading: isLoadingPostShipmentPlanFiles,
  } = useMutation(postShipmentPlanFilesRequest, {
    onSuccess: (data) => {
      if (data.status) {
        toast.success("Successfully uploaded file");

        const parsedFbaFiles: IShipmentPlanFiles[] =
          data?.response[0].files.fbaLabels?.map((file) => ({
            filename: file.filename,
            _id: file._id,
          }));
        const otherFiles: IShipmentPlanFiles[] =
          data?.response[0].files.otherFiles?.map((file) => ({
            filename: file.filename,
            _id: file._id,
          }));

        setFbaFiles(parsedFbaFiles);
        setOthersFile(otherFiles);
      }
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ?? "There was an error uploading your file."
      );
    },
  });

  const {
    mutate: deleteShipmentPlanFile,
    isLoading: isLoadingDeleteShipmentPlanFile,
  } = useMutation(deleteShipmentPlanFileRequest, {
    onSuccess: (data) => {
      if (data.status) {
        toast.success("Successfully deleted file");

        const parsedFbaFiles: IShipmentPlanFiles[] =
          data.response[0].files.fbaLabels?.map((file) => ({
            filename: file.filename,
            _id: file._id,
          }));
        const otherFiles: IShipmentPlanFiles[] =
          data.response[0].files.otherFiles?.map((file) => ({
            filename: file.filename,
            _id: file._id,
          }));

        setFbaFiles(parsedFbaFiles);
        setOthersFile(otherFiles);

        const updatedShipmentPlan = [...shipmentPlan];
        updatedShipmentPlan[0].files.fbaLabels = fbaFiles;
        updatedShipmentPlan[0].files.otherFiles = otherFiles;
        setShipmentPlan(updatedShipmentPlan);
      }
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ?? "There was an error deleting your file."
      );
    },
  });

  const { mutate: postShipmentPlanPayment, isLoading: isLoadingPayment } =
    useMutation(postShipmentPlanPaymentRequest, {
      onSuccess: (data) => {
        console.log(data.response.payment_intent);
        if (data.status) {
          console.log(data.response.url);
          localStorage.setItem(
            "asinmice-payment-intent-id",
            data.response.payment_intent
          );
          window.location.replace(data.response.url);
        }
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
            "There was an error trying to redirect to payment. Please try again and if the problem persists please contact us. "
        );
      },
    });

  const shouldDisplayLoader =
    isLoadingDeleteShipmentPlanProduct ||
    isLoadingShipmentPlan ||
    isLoadingUpdateShipmentPlanProduct ||
    isLoadingPostShipmentPlanFiles ||
    isLoadingDeleteShipmentPlanFile ||
    isLoadingPayment;
  const shouldRenderShipmentPlan =
    !shouldDisplayLoader && !isEmpty(shipmentPlan);
  const shouldDisplayFbaTitle =
    !isEmpty(fbaFiles) && !isLoadingPostShipmentPlanFiles;
  const shouldDisplaySkaTitle =
    !isEmpty(otherFiles) && !isLoadingPostShipmentPlanFiles;
  const shouldTriggerOrderNoError =
    (areFieldsEmpty && isEmpty(orderNoInput)) || shouldDisplayOrderNoError;

  const handleValidation = () => {
    const isWarehouseOwnerEmpty = shipmentPlan[0].warehouseOwner
      ? Object.values(shipmentPlan[0].warehouseOwner).some((values) =>
          isEmpty(values)
        )
      : true;
    const areAllFieldsEmpty = getAreFieldsInvalid(
      shipmentAdditionalInputs,
      receiptNo,
      orderNoInput,
      titleInput,
      fbaFiles,
      shouldDisplayOrderNoError
    );
    let asinMiceProfile = localStorage.getItem("asinmice-obj");
    asinMiceProfile = JSON.parse(asinMiceProfile!!);
    //@ts-ignore
    const { customerID } = asinMiceProfile;

    if (orderNoInput.length < 6) {
      setShouldDisplayOrderNoError(true);
      toast.error("Order no must be at least 6 characters long");
    } else if (areAllFieldsEmpty) {
      setAreFieldsEmpty(true);
    } else if (isWarehouseOwnerEmpty) {
      toast.error("Please find your nearest warehouse");
    } else {
      const payload: IPayShipmentPlan = {
        amount: parseFloat(totalPriceCb),
        customerId: customerID,
        shipmentPlanId: shipmentPlan[0]._id,
        email,
      };
      console.log("payload", payload);
      postShipmentPlanPayment(payload);
    }
  };

  const handleOnUpdate = () => {
    if (activeComponent === "link") {
      setActiveComponent("confirm");
    } else {
      const areAllFieldsEmpty = getAreFieldsInvalid(shipmentAdditionalInputs);

      if (areAllFieldsEmpty) {
        setAreFieldsEmpty(true);
        toast.error("Please complete all the fields");
        return;
      }

      const areValuesIdentical = isEqual(
        Object.values(shipmentAdditionalInputs),
        Object.values(initialEnteredValues)
      );
      const shouldUpdateDate = isEqual(
        orderDate,
        new Date(shipmentPlan[0].orderDate)
      );
      const shouldUpdateTitle = isEqual(
        titleInput,
        shipmentPlan[0].shipmentTitle
      );
      const shouldUpdateOrderNo = isEqual(
        orderNoInput,
        shipmentPlan[0].orderNo
      );
      const shouldUpdateReceiptNo = isEqual(
        receiptNo,
        shipmentPlan[0].receiptNo
      );
      const areAllFieldsIdentical =
        areValuesIdentical &&
        shouldUpdateTitle &&
        shouldUpdateOrderNo &&
        shouldUpdateDate &&
        shouldUpdateReceiptNo;

      if (areAllFieldsIdentical) {
        toast.error("Entered values must be different than the initial ones.");
      } else {
        const payload: IUpdateShipmentPlanProduct = {
          email,
          orderNo: orderNoInput,
          receiptNo,
          orderDate: orderDate!!.toISOString(),
          shipmentTitle: titleInput,
          shipmentPlanId: shipmentPlan[0]._id,
          products: shipmentPlan[0].products,
        };
        updateShipmentProduct(payload);
      }
    }
  };

  const handleLinkOrders = () => {
    if (activeComponent === "confirm") {
      setActiveComponent("link");
    } else {
    }
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number | undefined
  ) => {
    const result = e.target.value.replace(/[^\d.,]/g, "");
    const updatedResult: IAdditionalShipmentInputs = {
      ...shipmentAdditionalInputs,
    };
    const updatedPackagingErrors: Record<string, boolean> = {
      ...packagingLimitsError,
    };

    switch (e.target.id) {
      case "sp-title-input":
        updatedResult["sp-title-input"] = e.target.value;
        setTitleInput(e.target.value);
        break;

      case "sp-orderNo-input":
        updatedResult["sp-orderNo-input"] = e.target.value;
        setOrderNoInput(e.target.value);

        if (e.target.value.length < 6) {
          setShouldDisplayOrderNoError(true);
        } else {
          setShouldDisplayOrderNoError(false);
        }
        break;

      case `sp-unitsPerBox-${index}`:
        updatedResult[`sp-unitsPerBox-${index}`] = result;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].unitsPerBox = result;
          return updatedProduct;
        });
        break;

      case `sp-fnsku-${index}`:
        updatedResult[`sp-fnsku-${index}`] = e.target.value;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].fnsku = e.target.value;
          return updatedProduct;
        });
        break;

      case `sp-boxes-${index}`:
        updatedResult[`sp-boxes-${index}`] = result;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].boxes = result;
          return updatedProduct;
        });
        break;

      case `sp-weightPerBox-${index}`:
        updatedResult[`sp-weightPerBox-${index}`] = result;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].weightPerBox = result;
          return updatedProduct;
        });
        break;

      case `sp-upc-${index}`:
        updatedResult[`sp-upc-${index}`] = e.target.value;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].upc = e.target.value;
          return updatedProduct;
        });
        break;

      case `sp-width-${index}`:
        updatedResult[`sp-width-${index}`] = result;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].boxWidth = result;
          return updatedProduct;
        });
        break;

      case `sp-height-${index}`:
        updatedResult[`sp-height-${index}`] = result;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].boxHeight = result;
          return updatedProduct;
        });
        break;

      case `sp-length-${index}`:
        updatedResult[`sp-length-${index}`] = result;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].boxLength = result;
          return updatedProduct;
        });
        break;

      case `sp-shrink-wrap-${index}`:
        updatedResult[`sp-special-packaging-${index}`] = {
          amount: "",
          answer: false,
        };
        updatedResult[`sp-shrink-wrap-${index}`] = {
          amount: result,
          answer: true,
        };
        if (!isEmpty(result) && (Number(result) > 8 || Number(result) <= 0)) {
          updatedPackagingErrors[`sp-shrink-wrap-${index}`] = true;
        } else {
          updatedPackagingErrors[`sp-shrink-wrap-${index}`] = false;
        }

        setPackagingLimitsError(updatedPackagingErrors);
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].shrinkWrap = {
            answer: true,
            amount: result,
          };
          updatedProduct[0].products[index!!].specialPackaging = {
            answer: false,
            amount: "",
          };
          return updatedProduct;
        });
        break;

      case `sp-special-packaging-${index}`:
        updatedResult[`sp-special-packaging-${index}`] = {
          amount: "",
          answer: false,
        };
        updatedResult[`sp-special-packaging-${index}`] = {
          amount: result,
          answer: true,
        };
        if (!isEmpty(result) && (Number(result) > 8 || Number(result) <= 0)) {
          updatedPackagingErrors[`sp-special-packaging-${index}`] = true;
        } else {
          updatedPackagingErrors[`sp-special-packaging-${index}`] = false;
        }

        setPackagingLimitsError(updatedPackagingErrors);
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].specialPackaging = {
            answer: true,
            amount: result,
          };
          updatedProduct[0].products[index!!].shrinkWrap = {
            answer: false,
            amount: "",
          };
          return updatedProduct;
        });
        break;

      case `sp-comments-${index}`:
        updatedResult[`sp-comments-${index}`] = e.target.value;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].comments = e.target.value;
          return updatedProduct;
        });
        break;

      default:
        break;
    }

    const shouldEnablePayBtn = !getAreFieldsInvalid(
      updatedResult,
      receiptNo,
      orderNoInput,
      titleInput,
      fbaFiles,
      shouldDisplayOrderNoError
    );

    if (shouldEnablePayBtn) {
      setAreFieldsEmpty(false);
    }
  };

  const handleFindWarehouseRedirect = () =>
    navigate(`${pageRoutes.WAREHOUSES_MAP_PLAN_ID}?id=${planId}`);

  const onDeleteShipmentPlanProductCb = (
    shipmentPlanId: string,
    productId: string
  ) => {
    deleteShipmentPlanProduct({ email, shipmentPlanId, productId });
  };

  const handleOnFileSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    fileType: string
  ) => {
    if (e.target.files && planId) {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("fileType", fileType);
      formData.append("shipmentPlanId", planId);

      for (let i = 0; i < e.target.files.length; i++) {
        formData.append("files", e.target.files[i]);
      }
      setAreFieldsEmpty(false);
      postShipmentPlanFiles(formData);
    }
  };

  const handleOnDeleteFile = (
    file: IShipmentPlanFiles,
    fileType: FileTypes
  ) => {
    const fileToDelete = {
      email,
      fileToDelete: file._id,
      fileType,
      shipmentPlanId: planId,
    };

    deleteShipmentPlanFile(fileToDelete);
  };

  const copyToClipBoard = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      console.log("Text copied to clipboard:", textToCopy);
    } catch (err) {
      console.error("Unable to copy text to clipboard:", err);
    }
  };

  const handleRadioButtonsClick = (radioButtonInput: string, index: number) => {
    const radioButtonsInputs = { ...showAdditionalPlanInputs };
    const updatedAdditionalInputs = { ...shipmentAdditionalInputs };

    radioButtonsInputs[radioButtonInput] =
      !radioButtonsInputs[radioButtonInput];

    if (radioButtonInput.includes("sp-shrink-wrap")) {
      radioButtonsInputs[`sp-special-packaging-${index}`] = false;
    } else if (radioButtonInput.includes("sp-special-packaging")) {
      radioButtonsInputs[`sp-shrink-wrap-${index}`] = false;
    }

    updatedAdditionalInputs[radioButtonInput].answer =
      radioButtonsInputs[radioButtonInput];
    updatedAdditionalInputs[radioButtonInput].amount = "";

    setShowAdditionalPlanInputs(radioButtonsInputs);
    setShipmentAdditionalInputs(updatedAdditionalInputs);
    setShipmentPlan((prevState) => {
      prevState[0].products[index!!].specialPackaging.answer =
        radioButtonsInputs[`sp-special-packaging-${index}`];
      prevState[0].products[index!!].specialPackaging.amount = "";
      prevState[0].products[index!!].shrinkWrap.answer =
        radioButtonsInputs[`sp-shrink-wrap-${index}`];
      prevState[0].products[index!!].shrinkWrap.amount = "";
      return prevState;
    });
  };
  useEffect(() => {
    if (error) {
      window.location.href = pageRoutes.SHIPMENT_PLANS;
    } else if (data?.response) {
      const hasWarehouseLocation = data?.response[0].warehouseOwner
        ? data?.response[0].warehouseOwner?.businessAddress?.long !== "" &&
          data?.response[0].warehouseOwner?.businessAddress?.lat !== ""
        : false;

      // update teh store on first render
      handleSetShipmentPlanStore(data?.response[0]);

      const paymentDone = data?.response[0].payment.paid;

      const parsedFbaFiles: IShipmentPlanFiles[] =
        data?.response[0].files.fbaLabels?.map((file) => ({
          filename: file.filename,
          _id: file._id,
        }));
      const otherFiles: IShipmentPlanFiles[] =
        data?.response[0].files.otherFiles?.map((file) => ({
          filename: file.filename,
          _id: file._id,
        }));
      setShipmentPlan(data?.response);
      setFbaFiles(parsedFbaFiles);
      setOthersFile(otherFiles);
      setTitleInput(data?.response[0].shipmentTitle);
      setOrderNoInput(data?.response[0].orderNo);
      setReceiptNo(data?.response[0].receiptNo);
      setOrderDate(new Date(data?.response[0].orderDate));

      if (data?.response[0].orderNo) {
        setShouldDisplayOrderNoError(false);
      }

      let additionalInputs: IAdditionalShipmentInputs = {};
      const enteredValues: any = [];
      const radioButtonsInputs: Record<string, boolean> = {};
      const productInputsSection: Record<string, boolean> = {};

      data?.response[0].products.forEach((product, index) => {
        const {
          _id,
          unitsPerBox,
          boxWidth,
          boxHeight,
          boxLength,
          fnsku,
          boxes,
          weightPerBox,
          upc,
          comments,
          specialPackaging,
          shrinkWrap,
        } = product;
        if (shrinkWrap.answer) {
          radioButtonsInputs[`sp-shrink-wrap-${index}`] = true;
          setShowAdditionalPlanInputs(radioButtonsInputs);
        }

        if (specialPackaging.answer) {
          radioButtonsInputs[`sp-special-packaging-${index}`] = true;
          setShowAdditionalPlanInputs(radioButtonsInputs);
        }

        enteredValues.push(upc);
        enteredValues.push(weightPerBox);
        enteredValues.push(boxes);
        enteredValues.push(fnsku);
        enteredValues.push(unitsPerBox);
        enteredValues.push(boxWidth);
        enteredValues.push(boxHeight);
        enteredValues.push(boxLength);
        enteredValues.push(comments);
        enteredValues.push({ ...specialPackaging });
        enteredValues.push({ ...shrinkWrap });

        additionalInputs[`sp-upc-${index}`] = upc;
        additionalInputs[`sp-weightPerBox-${index}`] = weightPerBox;
        additionalInputs[`sp-boxes-${index}`] = boxes;
        additionalInputs[`sp-fnsku-${index}`] = fnsku;
        additionalInputs[`sp-unitsPerBox-${index}`] = unitsPerBox;
        additionalInputs[`sp-width-${index}`] = boxWidth;
        additionalInputs[`sp-height-${index}`] = boxHeight;
        additionalInputs[`sp-length-${index}`] = boxLength;
        additionalInputs[`sp-comments-${index}`] = comments;
        additionalInputs[`sp-special-packaging-${index}`] = {
          ...specialPackaging,
        };
        additionalInputs[`sp-shrink-wrap-${index}`] = { ...shrinkWrap };
      });

      const areAllFieldsEmpty = getAreFieldsInvalid(
        additionalInputs,
        data?.response[0].receiptNo,
        data?.response[0].orderNo,
        titleInput,
        parsedFbaFiles,
        shouldDisplayOrderNoError
      );

      if (!areAllFieldsEmpty) {
        setShouldGreyOutPaymentBtn(false);
      }

      setInitialEnteredValues(enteredValues);
      setShipmentAdditionalInputs(additionalInputs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  useEffect(() => {
    if (areFieldsEmpty) {
      toast.error(
        "Please complete all the fields, including upload of FBA files from amazon"
      );
    }
  }, [areFieldsEmpty]);

  useEffect(() => {
    const shouldDisableGreyOutBtn = !getAreFieldsInvalid(
      shipmentAdditionalInputs,
      receiptNo,
      orderNoInput,

      titleInput,
      fbaFiles,
      shouldDisplayOrderNoError
    );

    if (shouldDisableGreyOutBtn) {
      setShouldGreyOutPaymentBtn(false);
    } else setShouldGreyOutPaymentBtn(true);
  }, [
    titleInput,
    shipmentAdditionalInputs,
    fbaFiles,
    receiptNo,
    orderNoInput,
    shouldDisplayOrderNoError,
  ]);

  const { mutate: markPaymentAsPaid } = useMutation(markPaymentAsPaidRequest, {
    onSuccess: (data) => {
      if (data.status) {
        toast.success("Successfully marked payment as paid.");
      }
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.GET_SHIPMENT_PLAN,
      });
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
          "There was an error updating your plan products."
      );
    },
  });

  const handlePaymentStatus = () => {
    const payload = {
      email,
      shipmentPlanId: shipmentPlan[0]._id,
      paymentStatus: true,
      totalAmount: Number(totalPriceCb),
    };

    localStorage.setItem("planId", shipmentPlan[0]._id);

    markPaymentAsPaid(payload);
    navigate(pageRoutes.SHIPMENT_PLAN_LOADING);
  };

  const handleorderConfirmationRedirect = () => {
    localStorage.setItem("planId", shipmentPlan[0]._id);

    navigate(pageRoutes.SHIPMENT_PLAN_PAYMENT_THANK_YOU);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <FullScreenLoading />;
  }

  const receiveSelectedProductFromModal = (
    selectedProdcut: string[],
    orderID: string,
    shipFrom: string,
    shipTo: string
  ) => {
    setOrders([...orders, { orderID, products: selectedProdcut, shipFrom, shipTo }]);
  };

  const handleDeleteOrder = (orderID: string) => {
    const updatedOrders = orders.filter((order) => order.orderID !== orderID);
    setOrders(updatedOrders);
  };

  return (
    <>
      <div className="content">
        {shouldDisplayLoader && <FullScreenLoading />}
        <div className="container-fluid shipment-plans">
          <div className="sidebar">
            <SidebarMenu
              active="shipment-plans"
              sidebarOnly={true}
              shipmentPlanTitle={shipmentPlan[0]?.shipmentTitle}
            />
          </div>
          <div className="d-flex">
            {activeComponent === "link" && (
              <div className="ms-auto me-14">
                <button
                  className="add-order-btn w-30 h-10"
                  onClick={() => setShowAddOrderModal(true)}
                >
                  + Add Order
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="shipment-plan-stepper-container">
          <Stepper darkMode={true} shipmentPlanData={shipmentPlan[0]} />
        </div>
        {shouldRenderShipmentPlan && (
          <div className="row">
            <div className="d-flex justify-content-center align-items-center dark-input mx-3 mb-4">
              <div
                className={`d-flex flex-column ms-0 ms-md-3 secondary-title orderNo-container ${
                  shouldTriggerOrderNoError && "form-error"
                }`}
              >
                <label htmlFor="sp-orderNo-input">Order No</label>
                <input
                  value={orderNoInput}
                  placeholder="Order No"
                  onChange={(e) => handleOnChange(e, undefined)}
                  id="sp-orderNo-input"
                />

                {shouldDisplayOrderNoError && (
                  <span className="form-error">Minimum 6 chars long</span>
                )}
              </div>
              <div className=" secondary-title d-flex flex-column ms-0 ms-md-3 ">
                <label htmlFor="sp-order-date">Order Date</label>

                <DatePicker
                  id="sp-order-date"
                  selected={orderDate}
                  minDate={new Date()}
                  onChange={(date: Date | null) => {
                    setOrderDate(date);
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    e.preventDefault();
                  }}
                />
              </div>

              <div
                data-toggle="tooltip"
                data-placement="top"
                title="Minimum 6 chars long"
                className={`secondary-title d-flex flex-column mx-0 mx-md-3 orderNo-container
                `}
              >
                <label htmlFor="sp-receiptNo-input">PO No</label>

                <input
                  value={receiptNo}
                  placeholder="PO No"
                  onChange={(e) => handleOnChange(e, undefined)}
                  id="sp-receiptNo-input"
                />
                {/* <span className="form-error">Minimum 6 chars long</span> */}
              </div>

              <div
                className={`d-flex flex-column mx-0 mx-md-3 orderNo-container  secondary-title
                `}
              >
                {activeComponent === "confirm" && (
                  <div className="col-12 d-flex file-upload justify-content-end  secondary-title">
                    {!shouldDisplayLoader && (
                      <FbaAndOtherFilesUpload
                        handleOnFileSelect={handleOnFileSelect}
                        handleOnDeleteFile={handleOnDeleteFile}
                        shouldDisplayFbaTitle={shouldDisplayFbaTitle}
                        fbaFiles={fbaFiles}
                        shouldDisplaySkaTitle={shouldDisplaySkaTitle}
                        otherFiles={otherFiles}
                        activeComponent={activeComponent}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>

            <div>
              <AmazonDataCollapse
                shipmentPlanData={shipmentPlanData}
                shipmentPlan={shipmentPlan[0]}
                totalPriceCb={totalPriceCb}
                pricing={pricing}
                activeComponent={activeComponent}
              />
            </div>

            {activeComponent === "confirm" && (
              <div>
                {shipmentPlan[0].products.length !== 0 &&
                  shipmentPlan[0].products.map((product, index) => (
                    <div key={index}>
                      <ShipmentPlanProduct
                        product={product}
                        index={index}
                        shouldGreyOutPaymentBtn={shouldGreyOutPaymentBtn}
                        onDeleteShipmentPlanProductCb={
                          onDeleteShipmentPlanProductCb
                        }
                        shipmentPlan={shipmentPlan[0]}
                        areFieldsEmpty={areFieldsEmpty}
                        shipmentAdditionalInputs={shipmentAdditionalInputs}
                        handleOnChange={handleOnChange}
                        handleRadioButtonsClick={handleRadioButtonsClick}
                        showAdditionalPlanInputs={showAdditionalPlanInputs}
                        packagingLimitsError={packagingLimitsError}
                        onPayShipmentPlanCb={handlePaymentStatus}
                        onUpdateShipmentPlanCb={handleOnUpdate}
                        getTotalPriceCb={setTotalPriceCb}
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}

        {showAddOrderModal && (
          <Modal
            modalDialogStyle={{ maxWidth: "800px" }}
            okButtonText="Add"
            modalTitle="Add Order"
            children={
              <AddOrderModal
                shipmentPlan={shipmentPlan[0]}
                warehouseOwner={shipmentPlan[0].warehouseOwner}
                sendSelectedProductToParent={receiveSelectedProductFromModal}
              />
            }
            cancelModalClick={() => {
              setShowAddOrderModal(false);
            }}
          />
        )}

        {showModifyOrder && (
          <Modal
            modalDialogStyle={{ maxWidth: "800px" }}
            okButtonText="Modify"
            modalTitle="Modify Order"
            children={
              <AddOrderModal
                shipmentPlan={shipmentPlan[0]}
                warehouseOwner={shipmentPlan[0].warehouseOwner}
                sendSelectedProductToParent={receiveSelectedProductFromModal}
              />
            }
            cancelModalClick={() => {
              setShowModifyOrder(false);
            }}
          />
        )}
        {/* Floating Window */}
        <FloatingWindow
          handleFindWarehouseRedirect={handleFindWarehouseRedirect}
          handleOnUpdate={handleOnUpdate}
          handlePaymentStatusRedirect={handlePaymentStatus}
          handleorderConfirmationRedirect={handleorderConfirmationRedirect}
          handleLinkOrders={handleLinkOrders}
          shipmentPlanData={shipmentPlan[0]}
          totalPriceCb={totalPriceCb}
        />

        {/* display orders */}
        <div className="orders-container">
          {/* check if order is not empty */}
          {orders.length > 0 && (
            <table className="table table-sm">
              <thead className="sticky-header">
                <tr>
                  <th className="text-center">OrderNo</th>
                  <th className="text-center">Ship From</th>
                  <th className="text-center">Ship To</th>
                  <th className="text-center">Products Count</th>
                  <th className="text-center">Total Units</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order, index) => (
                  <tr key={index}>
                    <td className="text-center">{order.orderID}</td>
                    <td className="text-center">{order.shipFrom}</td>
                    <td className="text-center">{order.shipTo}</td>
                    <td className="text-center">{order.products.length}</td>
                    <td className="text-center">
                    </td>
                    <td className="text-center">
                      <span
                        className="icon-wrapper me-3"
                        onClick={(e) => {
                          setShowModifyOrder(true);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      </span>
                      <span
                        className="icon-wrapper me-3"
                        onClick={(e) => handleDeleteOrder(order.orderID)}
                      >
                        <i
                          className="fa fa-trash-o text-danger"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ShipmentPlan;
