import "./styles.scss";
import Logo from "assets/images/logo.svg";
import LogoBig from "assets/images/LogoPrincipal.png";
import About from "pages/about/About";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WorldImage from "assets/images/world2.jpg";


const faqItems = [
  {
    question:
      "What is UnieLogics, and how can it benefit my Ecommerce business?",
    answer:
      "UnieLogics is a SaaS platform that connects eCommerce sellers with a nationwide network of warehouses, offering streamlined logistics, inventory management, and fulfillment services. Our platform helps you save on shipping costs, optimize inventory placement, and scale your operations seamlessly.",
  },
  {
    question: "How do I get started with UnieLogics?",
    answer:
      "Simply sign up on our platform, provide your logistics requirements, and our system will match you with the best-suited warehouse partners. You can start creating shipment plans and managing orders immediately.",
  },
  {
    question:
      "Are there minimum order quantities or long-term contracts?",
    answer:
      "No, UnieLogics offers flexible solutions with no minimum order quantity or binding contracts. You only pay for the services you use.",
  },
  {
    question:
      "Can UnieLogics help with Amazon FBA fulfillment?",
    answer:
      "Yes, our platform supports Amazon sellers by automating order fulfillment and providing shipment plans compatible with Amazon’s requirements, ensuring smooth and hassle-free operations.",
  },
  {
    question:
      "What are the requirements to join the UnieLogics network?",
    answer:
      "To qualify, your warehouse should meet our operational standards, including adequate storage space, a loading dock, and proximity to major shipping routes. We also ask for transparency in your pricing for prep services, such as labeling, bundling, and reboxing.",
  },
  {
    question: "What is the cost of joining the network?",
    answer:
      "Qualified warehouses can join for free, gaining access to our WMS and eCommerce seller network. Non-qualified facilities can sign up for $499 per month to access our software and services.",
  },
  {
    question:
      "How does the WMS provided by UnieLogics work?",
    answer:
      "Our WMS streamlines your warehouse operations with features like barcode scanning, inventory tracking, automated rate shopping, and workflow management. It’s designed to enhance efficiency and reduce overhead costs.",
  },
];

const Home: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  return (
    <div className="min-h-screen flex flex-col">
      {/* Top Navigation */}
      <nav
        role="navigation"
        aria-label="Main Navigation"
        className="fixed top-0 left-0 right-0 p-3 z-10 flex items-center justify-between"
        style={{ backgroundColor: 'black' }}>
        {/* Logo */}
        <picture>
          <source
            srcSet={LogoBig}
            media="(min-width: 768px)"
          />
          <img
            src={Logo}
            alt="Unielogics Logo"
            className="logo-img"
          />
          <style>{`
 
              .logo-img {
 
                width: 12rem; /* Default size for larger screens */
 
              }
 
              @media (max-width: 768px) {
 
                .logo-img {
 
                  width: 12rem; /* Change size when LogoSmall is visible */
 
                }
 
              }
            `}</style>
        </picture>

        {/* Desktop Menu */}
        <ul className="hidden lg:flex space-x-6 navigation-title text-white-300">

          <li>
            <a href="/warehouse-owners" className="text-white hover:text-gray-200">
              Warehouse Owners
            </a>
          </li>
          <li className="text-white">/</li>
          <li>
            <Link to="/team" className="text-white hover:text-gray-200">
              Our Team
            </Link>
          </li>
          <li className="text-white">/</li>
          <li>
            <Link to="/faq" className="text-white hover:text-gray-200">
              FAQ
            </Link>
          </li>
        </ul>


        {/* Burger Menu */}
        <button
          className="lg:hidden text-white-300 hover:text-gray-200"
          style={{ position: 'fixed', top: '10px', right: '16px' }}
          onClick={toggleMenu}
          aria-expanded={isMenuOpen}
          aria-label="Toggle menu">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex flex-col items-center justify-center">
            <button
              className="absolute top-4 right-4 text-white-300 hover:text-gray-200"
              onClick={toggleMenu}
              aria-label="Close menu">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <ul className="flex flex-col items-center space-y-4 text-white-300 text-xl">
              <li>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSflYitbApQARcFWUZqslwjzQYlxcClc0lwK7QmALH6W_Ke5iw/viewform"
                  className="hover:text-gray-200">
                  Warehouse Owners
                </a>
              </li>
              <li>
                <Link to="/team" className="hover:text-gray-200">
                  Our Team
                </Link>
              </li>
              <li>
                <Link to="/faq" className="hover:text-gray-200">
                  FAQ
                </Link>
              </li>
            </ul>
            <img
              src={LogoBig}
              alt="Unielogics Logo"
              className="w-52 h-22 mt-8"
            />
          </div>
        )}
      </nav>



      <div //backgroundsection for header (dissabled for now)
        className="landing-page-container"
        style={{
          //backgroundImage: `url(${WorldImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundColor: "black"

        }}
      >
        <div className="jumbotron text-center">
          <div className="display-4 jumbotron-title">

            <h1>
              Gain A Competitive Advantage For Your
              E-commerce.
            </h1>
          </div>
          <div className="sub-title">
            <h1>
              SCALE FASTER WITH DECENTRALIZED LOGISTICS
            </h1>
          </div>
          <div className="title2">
            <h1>
              -On demand services without MOQ, contracts and
              surcharges-
            </h1>
          </div>
          <div className="home-button">
            <div className="get-started">
              <a
                className="get-started-button btn"
                href="/Login"
                role="button">
                Log In
              </a>
            </div>
            <div className="get-started">
              <a
                className="get-started-button btn"
                href="/Register"
                role="button">
                Sign Up
              </a>
            </div>
          </div>

          <h1 className="jumbotron-no-credit">
            **No Credit Card Required**
          </h1>
        </div>
      </div>
      <section>
        <About />
      </section>
      <section>
        <footer className="flex flex-col items-center text-gray-400 gap-1 pb-4 footer-landing-page">
          <img
            src={Logo}
            alt="Unielogics Logo"
            className="w-24"
          />
          <div className="flex flex-row gap-4">
            <a
              href="/terms-and-conditions"
              className="hover:text-gray-300"
              target="blank">
              Terms of Use
            </a>
            <span>|</span>
            <a
              href="/privacy-policy"
              className="hover:text-gray-300"
              target="blank">
              Privacy Policy
            </a>
          </div>
        </footer>
        {/* <FAQPage/> */}
      </section>
    </div>
  );
};

export default Home;
