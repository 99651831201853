import { Link } from "react-router-dom";

interface MobileMenuProps {
  toggleMenu: () => void;
}

const MobileMenu: React.FC<MobileMenuProps> = (props) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex flex-col items-center justify-center">
      <button
        className="absolute top-4 right-4 text-purple-300 hover:text-gray-200"
        onClick={props.toggleMenu}
        aria-label="Close menu"
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <ul className="flex flex-col items-center space-y-4">
        <li>
          <Link to="/" className="text-purple-300 hover:text-gray-200 text-xl">
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/team"
            className="text-purple-300 hover:text-gray-200 text-xl"
          >
            Our Team
          </Link>
        </li>
        <li>
          <Link
            to="/faq"
            className="text-purple-300 hover:text-gray-200 text-xl"
          >
            FAQ
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default MobileMenu;
