// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-shipment-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: auto;
  width: 100%;
}

.order-ship-from {
  width: 35%;
}

.order-ship-to {
  width: 35%;
}

.order-shipment-label {
  font-size: 0.7rem;
  font-weight: bold;
}

.order-shipment-input {
  width: 100%;
  height: 0.5rem;
  border: 0.5px solid #ccc;
  border-radius: 5px;
  padding: 0.5rem 0.2rem;
  color: #0b0b0b;
}

.inventory-item-table-container {
  margin-top: 10px;
  max-height: 250px;
  overflow-y: scroll;
}
.inventory-item-table-container tr {
  border-bottom: transparent;
}

.order-shipment-span {
  display: block;
  color: #ffffff;
  height: 0.7rem;
  margin-top: 0.4rem;
}

.create-order-btn {
  width: 30%;
  height: 1.3rem;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.create-order-btn:hover {
  background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/components/AddOrderModalList/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,oBAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,iBAAA;EACA,iBAAA;AACF;;AAEA;EACE,WAAA;EACA,cAAA;EACA,wBAAA;EACA,kBAAA;EACA,sBAAA;EACA,cAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;AACF;AAAE;EACE,0BAAA;AAEJ;;AAEA;EACE,cAAA;EACA,cAAA;EACA,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,UAAA;EACA,cAAA;EACA,yBAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,yBAAA;AACF","sourcesContent":[".order-shipment-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: stretch;\n  height: auto;\n  width: 100%;\n}\n\n.order-ship-from {\n  width: 35%;\n}\n\n.order-ship-to {\n  width: 35%;\n}\n\n.order-shipment-label {\n  font-size: 0.7rem;\n  font-weight: bold;\n}\n\n.order-shipment-input {\n  width: 100%;\n  height: 0.5rem;\n  border: 0.5px solid #ccc;\n  border-radius: 5px;\n  padding: 0.5rem 0.2rem;\n  color: #0b0b0b;\n}\n\n.inventory-item-table-container {\n  margin-top: 10px;\n  max-height: 250px;\n  overflow-y: scroll;\n  tr {\n    border-bottom: transparent;\n  }\n}\n\n.order-shipment-span{\n  display: block;\n  color: #ffffff;\n  height: 0.7rem;\n  margin-top: 0.4rem;\n}\n\n.create-order-btn{\n  width: 30%;\n  height: 1.3rem;\n  background-color: #007bff;\n  color: #ffffff;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.create-order-btn:hover{\n  background-color: #0056b3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
