import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { pageRoutes } from "utils";
import { Link } from "react-router-dom";
import { IAutocompleteDrodown, IOptions } from "./interfaces";

const AutocompleteDropdown = ({
  isDropdownDisabled,
  options,
  onSelectCb,
  defaultOption,
}: IAutocompleteDrodown) => {
  const [showDropDown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState(
    defaultOption?.label || "Select Supplier"
  ); // Initialize with default value
  const [filteredOptions, setFilteredOptions] = useState(options);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        inputRef.current &&
        !dropdownRef.current.contains(event.target as Element) &&
        !inputRef.current.contains(event.target as Element)
      ) {
        setShowDropdown(false);
      }
    }

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setInputValue(searchText);

    const filtered = options.filter((option: any) =>
      option.label.toLowerCase().includes(searchText.toLowerCase())
    );

    setShowDropdown(searchText !== "");
    setFilteredOptions(filtered);
  };

  const handleInputFocus = () => {
    setShowDropdown(true);
    setFilteredOptions(options);
  };

  const handleOptionClick = (option: IOptions) => {
    setShowDropdown(false);
    onSelectCb(option);

    // Set the selected option's label as the input value
    setInputValue(option.label);
  };

  return (
    <div
      ref={dropdownRef}
      className={`${isDropdownDisabled
        ? "autocomplete-dropdown-disabled"
        : "autocomplete-dropdown btn-group"
        } ${showDropDown && "show"}`}
    >
      <div className="autocomplete-dropdown-input-and-button">
        {!isDropdownDisabled ? (
          <>
            <input
              ref={inputRef}
              type="text"
              className="autocomplete-dropdown-input"
              placeholder="Type to search"
              value={inputValue}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
            <button
              type="button"
              className="btn dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded={showDropDown}
              onClick={() => {
                setFilteredOptions(options);
                setShowDropdown(!showDropDown);
              }}
            >
              <span className="sr-only">Toggle Dropdown</span>
            </button>
          </>
        ) : (
          <span>{inputValue}</span>
        )}
      </div>
      <div className={`autocomplete-dropdown-menu ${showDropDown && "show"}`}>
        {options[0].value === "" ? (
          <>
            <span
              className="dropdown-item"
              onClick={() =>
                handleOptionClick({
                  label: "No supplier",
                  value: "no-supplier",
                })
              }
            >
              No supplier
            </span>
            <span>
              You have no suppliers added. To add a supplier click{" "}
              <Link to={pageRoutes.SUPPLIERS}>here.</Link>
            </span>
          </>
        ) : (
          [
            ...filteredOptions.map((option: any) => ({
              label: option.label,
              value: option.value,
            })),
          ].map((option: any) => (
            <span
              key={option.value}
              className={`dropdown-item${inputValue === option.label ? " active" : ""
                }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </span>
          ))
        )}
      </div>
    </div>
  );
};

export default AutocompleteDropdown;