import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { IProfileSettingsFormType } from "common/interfaces";
import { IProfileAccountSettings } from "./interfaces";

import "./styles.scss";

const ProfileAccountSettings = ({
  user,
  onUpdateSubmitParent,
}: IProfileAccountSettings) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IProfileSettingsFormType>({
    defaultValues: useMemo(() => {
      return user;
    }, [user]),
  });

  useEffect(() => {
    (Object.keys(errors) as (keyof typeof errors)[]).forEach((key, index) => {
      toast.error(errors[key]?.message);
    });
  }, [errors]);

  useEffect(() => {
    reset(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onUpdateSubmit = ({
    firstName,
    lastName,
    phoneNumber,
    notifications,
  }: IProfileSettingsFormType) => {
    if (Object.keys(errors).length === 0) {
      onUpdateSubmitParent(firstName, lastName, phoneNumber, notifications);
    }
  };

  return (
    <div className="col-xl-8 py-4 profile-account-card-right-side">
      <div className="mb-4">
        <h2 className="profile-account-card-right-side-title">
          Account Settings
          <i className="fa fa-user ms-3"></i>
        </h2>
        <div className="col-12 d-flex notification-container mb-5 justify-content-end">
          <span className="me-3">Notifications</span>
          <label className="profile-switch">
            <input type="checkbox" {...register("notifications")} />
            <span className="profile-switch-slider round"></span>
          </label>
        </div>
        <form onSubmit={handleSubmit(onUpdateSubmit)}>
          <div className="row gx-3 mb-md-3 mb-sm-0">
            <div className="col-md-5 mb-md-0 mb-sm-3 input-wrapper">
              <label className="small mb-1" htmlFor="inputFirstName">
                First name
              </label>
              <input
                className={`form-control ${errors.firstName && "input-error"}`}
                id="inputFirstName"
                type="text"
                placeholder=""
                {...register("firstName", {
                  required: "First name field cannot be empty",
                })}
              />
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5 mb-md-0 mb-sm-3 input-wrapper">
              <label className="small mb-1" htmlFor="inputLastName">
                Last name
              </label>
              <input
                className={`form-control ${errors.lastName && "input-error"}`}
                id="inputLastName"
                type="text"
                placeholder=""
                {...register("lastName", {
                  required: "Last name field cannot be empty",
                })}
              />
            </div>
          </div>
          <div className="row gx-3 mb-md-3 mb-sm-0">
            <div className="col-md-5 mb-md-0 mb-sm-3 input-wrapper">
              <label className="small mb-1" htmlFor="inputPhone">
                Phone Number
              </label>
              <input
                className={`form-control ${
                  errors.phoneNumber && "input-error"
                }`}
                id="inputPhone"
                type="tel"
                placeholder=""
                {...register("phoneNumber", {
                  required: "Phone number field cannot be empty",
                })}
              />
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5 mb-md-0 mb-sm-3 input-wrapper">
              <label className="small mb-1" htmlFor="inputUsername">
                Username
              </label>
              <input
                className="form-control disabled"
                id="inputUsername"
                type="email"
                defaultValue={user.email}
                disabled
              />
            </div>
          </div>
          <div className="row gx-3 mb-md-3 mb-sm-0">
            <div className="col-md-5 mb-md-0 mb-sm-3 input-wrapper">
              <label className="small mb-1" htmlFor="inputEmail">
                Email
              </label>
              <input
                className="form-control disabled"
                id="inputEmail"
                type="email"
                placeholder=""
                defaultValue={user.email}
              />
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5 mb-md-0 mb-sm-3 input-wrapper">
              <label className="small mb-1" htmlFor="inputSubscriptionStatus">
                Subscription Status
              </label>
              <input
                className="form-control disabled"
                id="inputSubscriptionStatus"
                type="text"
                name="subscriptionStatus"
                placeholder=""
                defaultValue={
                  user.hasActiveSubscription ? "ACTIVE" : "INACTIVE"
                }
                disabled
              />
            </div>
          </div>
          <div className="row gx-3 mb-5">
            <div className="col-md-5 mb-md-0 mb-sm-3 input-wrapper">
              <label className="small mb-1" htmlFor="inputSubscriptionInfo">
                Subscription Info
              </label>
              <input
                className="form-control disabled"
                id="inputSubscriptionInfo"
                type="tel"
                name="subscriptionInfo"
                placeholder=""
                defaultValue={user.hasTrial ? "7 DAYS FREE TRIAL" : user.plan.toUpperCase()}
                disabled
              />
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5 mb-md-0 mb-sm-3 input-wrapper">
              <label className="small mb-1" htmlFor="trialInfo">
                Trial Active
              </label>
              <input
                className="form-control disabled"
                id="trialInfo"
                type="text"
                name="trialInfo"
                placeholder="Trial Info"
                value = {user?.hasTrial?.toString()?.toUpperCase()}
                disabled
              />
            </div>
          </div>
          <button className="btn btn-light-blue mt-md-5 mt-sm-0" type="submit">
            Update
          </button>
          <button
            className="btn btn-dark-blue mt-md-5 mt-sm-0 btn-danger"
            type="button"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};
export default ProfileAccountSettings;
