export const MAX_ALLOWED_SELECTED_STATES = 3

export const STATE_CODES: Record<string, string> = {
  "Alabama": "AL", "Alaska": "AK", "Arizona": "AZ", "Arkansas": "AR", "California": "CA",
  "Colorado": "CO", "Connecticut": "CT", "Delaware": "DE", "District of Columbia": "D.C.", "Florida": "FL", "Georgia": "GA",
  "Hawaii": "HI", "Idaho": "ID", "Illinois": "IL", "Indiana": "IN", "Iowa": "IA",
  "Kansas": "KS", "Kentucky": "KY", "Louisiana": "LA", "Maine": "ME", "Maryland": "MD",
  "Massachusetts": "MA", "Michigan": "MI", "Minnesota": "MN", "Mississippi": "MS", "Missouri": "MO",
  "Montana": "MT", "Nebraska": "NE", "Nevada": "NV", "New Hampshire": "NH", "New Jersey": "NJ",
  "New Mexico": "NM", "New York": "NY", "North Carolina": "NC", "North Dakota": "ND", "Ohio": "OH",
  "Oklahoma": "OK", "Oregon": "OR", "Pennsylvania": "PA", "Rhode Island": "RI", "South Carolina": "SC",
  "South Dakota": "SD", "Tennessee": "TN", "Texas": "TX", "Utah": "UT", "Vermont": "VT",
  "Virginia": "VA", "Washington": "WA", "West Virginia": "WV", "Wisconsin": "WI", "Wyoming": "WY",
  "Puerto Rico": "PR",
};


export const customStyles = {
  control: (base: any) => ({
    ...base,
    backgroundColor: '#fff', // White background for the select control
    borderColor: '#ccc', // Border color
    color: '#333', // Text color in the control
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? 'white' : '#333', // Text color when selected
    backgroundColor: state.isSelected
      ? '#007bff' // Standard blue for selected option
      : state.isFocused
        ? '#e0f7fa' // Light blue when hovering
        : 'white', // Default background color
    padding: '10px', // Padding for each option
    cursor: 'pointer', // Change cursor to pointer on hover
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#333', // Text color for the selected value
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#fff', // White background for the dropdown menu
  }),
};