import React, { useRef } from "react";
import { customStyles, STATE_CODES } from "../constants";
import Select from "react-select";

const options = Object.entries(STATE_CODES).map(([stateName, stateCode]) => ({
  label: `${stateName} (${stateCode})`,
  value: `${stateName}`,
}));

export const StateDropdown: React.FC<{ selectedState: string; setHighlightedStates: (stateName: string) => void }> = ({
  selectedState,
  setHighlightedStates,
}) => {
  const prevSelected = useRef<string | null>(null); // Store the previous selection

  const handleChange = (selectedOption: any) => {
    if (selectedOption) {
      // Track the previously selected value before updating
      prevSelected.current = selectedOption.value;
      setHighlightedStates(prevSelected.current as string);
      // console.log("Selected State:", selectedOption.value);
    } else {
      // console.log("Selection cleared, previous selection was:", prevSelected.current);
      // Handle clearing logic here
      setHighlightedStates(prevSelected.current as string);
      prevSelected.current = null; // Reset the previous selection
    }
  };
  const selectedOption = options.find((option) => option.value === selectedState);
  if (selectedOption) {
    prevSelected.current = selectedOption.value;
  }
  return (
    <Select
      options={options}
      placeholder="Select a state"
      styles={customStyles}
      isClearable={true}
      onChange={handleChange} // Detect changes
      value={selectedOption || null} // Set the selected state dynamically

    />
  );
};
