import { isEmpty } from "lodash";
import { IAdditionalShipmentInputs, IShipmentPlanFiles } from "./interfaces";

export const getAreFieldsInvalid = (
  additionalInputs: IAdditionalShipmentInputs,
  receiptNo?: string,
  orderNoInput?: string,
  titleInput?: string,
  fbaFiles?: IShipmentPlanFiles[],
  shouldDisplayOrderNoError?: boolean
) => {
  return (
    Object.entries(additionalInputs).some(([key, value]) => {
      if (key.startsWith("sp-comments-")) {
        return false;
      }
      if (typeof value === "object" && value!!.answer) {
        return isEmpty(value!!.amount);
      }
      return isEmpty(value);
    }) ||
    (orderNoInput && isEmpty(orderNoInput)) ||
    (receiptNo && isEmpty(receiptNo)) ||
    (titleInput && isEmpty(titleInput)) ||
    (fbaFiles && isEmpty(fbaFiles)) ||
    (shouldDisplayOrderNoError && shouldDisplayOrderNoError === true )
  );
};
