import Logo from "assets/images/logo.svg";

const Footer: React.FC = () => {
  return (
    <footer className="flex flex-col items-center text-gray-400 gap-4 mt-8">
      <img src={Logo} alt="Unielogics Logo" className="w-24" />
      <div className="flex flex-row gap-4">
        <a href="/terms-and-conditions" className="hover:text-gray-300">
          Terms of Use
        </a>
        <span>|</span>
        <a href="/privacy-policy" className="hover:text-gray-300">
          Privacy Policy
        </a>
      </div>
    </footer>
  );
};

export default Footer;
