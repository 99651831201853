import { IModal } from "./interfaces";

import "./styles.scss";

const Modal = ({
  modalTitle,
  modalTextContent,
  children,
  okButtonText,
  cancelButtonText,
  okModalClick,
  cancelModalClick,
  modalDialogStyle,
  showCancelButton,
}: IModal) => {
  return (
    <div
      className="modal fade dark-modal show"
      id="verticallyCentered"
      aria-labelledby="verticallyCenteredModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={modalDialogStyle && modalDialogStyle}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="verticallyCenteredModalLabel">
              {modalTitle}
            </h5>
            <span
              className="modal-header-close-button"
              onClick={() => cancelModalClick()}
            >
              X
            </span>
          </div>
          <div className="modal-body">
            <p className="text-700 lh-lg mb-0">{modalTextContent}</p>
            {children}
          </div>
          <div className="modal-footer">
            {okModalClick && (
              <button
                className="btn btn-dark-blue btn-success btn-generic"
                type="button"
                onClick={() => okModalClick()}
              >
                {okButtonText || "Okay"}
              </button>
            )}

            {showCancelButton && (
              <button
                className="btn btn-dark-blue btn-danger"
                type="button"
                data-bs-dismiss="modal"
                onClick={() => cancelModalClick()}
              >
                {cancelButtonText || "Cancel"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
