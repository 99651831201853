import {
  Footer,
  Loading,
  Modal,
  PdfContent,
  SidebarMenu,
  Table,
} from "components";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { QUERY_KEYS, UserContext } from "utils";
import { useContext, useEffect, useState } from "react";
import { getProfitableProductDetails } from "./api";
import { useSearchParams } from "react-router-dom";
import {
  IGetProfitableProductDetailsQueryError,
  IGetProfitableProductDetailsResponse,
  IProductDetails,
} from "./interfaces";
import { toast } from "react-toastify";
import { PDFViewer } from "@react-pdf/renderer";

import "./styles.scss";
import dayjs from "dayjs";
import { IContextUser } from "common/interfaces";
import { set } from "lodash";
import { updateSupplierProductDetailsRequest } from "pages/profitableProducts/api";

type ContentType =
  | "calculator"
  | "notes"
  | "supplier"
  | "variations view"
  | "buy box statistics"
  | null;
type DisplayType = "detail" | "chart";

const ProfitableProductsDetails = () => {
  let user: IContextUser = useContext(UserContext)!;
  const { email } = user;
  const [searchParams] = useSearchParams();
  const [productDetails, setProductDetails] = useState<any>([]);
  const folderId = searchParams.get("folderId");
  const asin = searchParams.get("asin");
  const [pdfPreviewModal, setShowPdfPreviewModal] = useState(false);
  // const shipmentPlanId = searchParams.get("shipmentPlanId");
  // const productId = searchParams.get("productId");

  //display for calculator, notes, supplier, buy box statistics
  const [content, setContent] = useState<ContentType>(null);

  //display for detial, chart, and export
  const [display, setDisplay] = useState<DisplayType>("detail");

  // notes state
  const [notes, setNotes] = useState<string>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editText, setEditText] = useState<string>(notes!);

  const queryClient = useQueryClient();

  // Get Profitable Product Details
  const {
    data,
    error,
    isLoading: isLoadingGetProfitableProductDetails,
    refetch: getProfProductsQuery,
  } = useQuery<
    IGetProfitableProductDetailsResponse,
    IGetProfitableProductDetailsQueryError
  >(
    QUERY_KEYS.GET_PROFITABLE_PRODUCT_DETAILS,
    () => getProfitableProductDetails({ email, folderId, asin }),
    {
      enabled: false,
      retry: false,
    }
  );

  useEffect(() => {
    if (email && folderId && asin) {
      getProfProductsQuery();
    }
  }, [email, folderId, asin, getProfProductsQuery]);

  useEffect(() => {
    toast.error(error?.response.data.message);
  }, [error]);

  useEffect(() => {
    if (data) {
      setProductDetails(data?.response.productDetails);
    }
  }, [data]);

  // pull notes from the database
  useEffect(() => {
    if (productDetails.length > 0) {
      setNotes(productDetails[0].note);
      console.log("productDetails[0].note ", productDetails);
    }
  }, [productDetails]);

  const handleButtonClick = (type: ContentType) => {
    setContent(type);
  };

  const handleLinkClick = (type: DisplayType) => {
    setDisplay(type);
  };

  const handleEditNotes = () => {
    setIsEditing(true);
  };

  const mutation = useMutation(
    updateSupplierProductDetailsRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: QUERY_KEYS.GET_FOLDERS });
      },
      onError: (error: any) => {
        console.log("error updating prodcut detail " + error.response.data.message);
        return;
      },
    }
  );

  const submitNotes = async () => {
    try {
      productDetails[0].note = editText;
      setNotes(editText);
      setIsEditing(false);
      console.log("productDetails[0] ", productDetails[0]);
      await mutation.mutateAsync(productDetails[0]);
    } catch (error) {
      console.log("error updating prodcut detail " + error);
    }
  };

  const closeEditNotes = () => {
    setIsEditing(false);
  };

  return (
    <>
      <div className="content profitable-products-details">
        {productDetails.length > 0 && pdfPreviewModal && (
          <Modal
            okButtonText="Create"
            modalTitle="Add new folder"
            modalTextContent="Please enter the name of the folder that you want to create"
            children={
              <PDFViewer style={{ width: "100%", height: "65vh" }}>
                <PdfContent productDetails={productDetails} />
              </PDFViewer>
            }
            cancelModalClick={() => setShowPdfPreviewModal(false)}
          />
        )}
        <div className="container-fluid">
          <SidebarMenu active="profitable-products" sidebarOnly={true} profittableProductASIN={productDetails[0]?.asin}/>
        </div>
        {isLoadingGetProfitableProductDetails && (
          <div className="row">
            <div className="col-12 d-flex justify-content-center loading-wrapper">
              <Loading />
            </div>
          </div>
        )}

        {data?.response && (
          <>
            {/* display view */}
            <div className="row ps-4">
              {productDetails.length > 0 && (
                <div className="col-12 top-chart-part">
                  {/* <h4 className="mb-5">{productDetails[0]?.title}</h4> */}
                  <div className="row product-detail-header">
                    <div>
                      <button
                        className="text-link-button"
                        onClick={() => setShowPdfPreviewModal(true)}
                      >
                        Export
                      </button>
                      <button
                        className="text-link-button"
                        onClick={() => handleLinkClick("chart")}
                      >
                        Chart
                      </button>
                      <button
                        className="text-link-button"
                        onClick={() => handleLinkClick("detail")}
                      >
                        Detail
                      </button>
                    </div>
                    <div className="col-3">
                      <div className="d-flex justify-content-center max-h-250px">
                        <img src={productDetails[0]?.imageUrl} alt="" />
                      </div>
                    </div>

                    <div className="col-9">
                      {display === "detail" && (
                        <div>
                          <h1 className="mb-5 detail-title">
                            {productDetails[0]?.title}
                          </h1>
                          <div className="col-6 detail-description">
                            <ul>
                              <li>
                                <span className="title-text">Asin: </span>
                                {productDetails[0].asin}
                              </li>
                              <li>
                                <span className="title-text">
                                  Buybox is FBA:{" "}
                                </span>
                                {productDetails[0].buyboxIsFBA ? "Yes" : "No"}
                              </li>
                              <li>
                                <span className="title-text">FBA: </span>
                                {productDetails[0].offerCountFBA}
                              </li>
                              <li>
                                <span className="title-text">FBM: </span>
                                {productDetails[0].offerCountFBM}
                              </li>
                              <li>
                                <span className="title-text">
                                  Quantity Per Set:{" "}
                                </span>
                                {productDetails[0].qtyPerSet}
                              </li>
                              <li>
                                <span className="title-text">
                                  Product group:{" "}
                                </span>
                                {productDetails[0].productGroup}
                              </li>
                              <li>
                                <span className="title-text">Brand: </span>
                                {productDetails[0].brand}
                              </li>
                              <li>
                                <span className="title-text">Ian: </span>
                                {productDetails[0].ian}
                              </li>
                              <li>
                                <span className="title-text">
                                  Last Price Change:{" "}
                                </span>
                                {dayjs(
                                  productDetails[0].lastPriceChange
                                ).format("MM/DD/YY")}
                              </li>
                              <li>
                                <span className="title-text">Weight: </span>
                                {productDetails[0].weight}
                              </li>
                              <li>
                                <span className="title-text">W x H x L: </span>
                                {productDetails[0].WxHxL}
                              </li>
                              <li>
                                <span className="title-text">Hazmat: </span>
                                {productDetails[0].isHazmat ? "YES" : "NO"}
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}

                      {display === "chart" && (
                        <div className="chart">
                          <iframe
                            width="100%"
                            height="437px"
                            src={`https://www.unielogics.com/charts2?asin=${productDetails[0]?.asin}`}
                            title="asinmice-charts"
                          ></iframe>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
                    <PdfContent productDetails={productDetails} />
                  </PDFViewer> */}
                </div>
              )}
            </div>

            {/* content view */}
            <div className="row pb-5 info-row">
              <div className="button-container d-flex justify-content-around">
                {/* Calculator Button */}
                <button
                  className="content-button btn-icon"
                  onClick={() => handleButtonClick("calculator")}
                >
                  <i className="fa fa-calculator"></i>
                </button>

                {/* Notes Button */}
                <button
                  className="content-button btn-icon"
                  onClick={() => handleButtonClick("notes")}
                >
                  <i className="fa fa-sticky-note"></i>
                </button>

                {/* Truck for Supplier Button */}
                <button
                  className="content-button btn-icon"
                  onClick={() => handleButtonClick("supplier")}
                >
                  <i className="fa fa-truck"></i>
                </button>

                {/*variations view Button */}
                <button
                  className="content-button btn-icon"
                  onClick={() => handleButtonClick("variations view")}
                >
                  <i className="fa fa-info-circle"></i>
                </button>

                {/* Buy Box Statistics Button */}
                <button
                  className="content-button btn-icon"
                  onClick={() => handleButtonClick("buy box statistics")}
                >
                  <i className="fa fa-bar-chart"></i>
                </button>
              </div>

              <div>
                {content === "calculator" && (
                  <div>
                    <h3 className="my-3 text-decoration-underline info-header">
                      Profit Calculator
                    </h3>
                    <ul className="ul-info">
                      <li className="li-info">
                        <span className="title-text">Price: </span>$
                        {productDetails[0].price}
                      </li>
                      <li className="li-info">
                        <span className="title-text">Amazon fees: </span>$
                        {productDetails[0].amazonFees}
                      </li>
                      <li className="li-info">
                        <span className="title-text">Pick & Pack: </span>$
                        {productDetails[0].pickPack}
                      </li>
                      <li className="li-info">
                        <span className="title-text">Total Fees: </span>
                        {productDetails[0].totalFees}
                      </li>
                      <li className="li-info">
                        <span className="title-text">Break Even: </span>
                        {productDetails[0].breakEven}
                      </li>
                      <li className="li-info">
                        <span className="title-text">Cost Per Item: </span>
                        {productDetails[0].costPerItem}
                      </li>
                      <li className="li-info">
                        <span className="title-text">Misc Expenses: </span>
                        {productDetails[0].miscExpenses}
                      </li>
                      <li className="li-info">
                        <span className="title-text">
                          Total Cost Per Sale:{" "}
                        </span>
                        {productDetails[0].totalCostPerSale}
                      </li>
                      <li className="li-info">
                        <span className="title-text">Net Profit: </span>
                        {productDetails[0].netProfit}
                      </li>
                      <li className="li-info">
                        <span className="title-text">Units: </span>
                        {productDetails[0].units}
                      </li>
                      <li className="li-info">
                        <span className="title-text">Total Profit: </span>
                        {productDetails[0].totalProfit}
                      </li>
                      <li className="li-info">
                        <span className="title-text">Net Sales Margin: </span>
                        {productDetails[0].netSalesMargin}
                      </li>
                      <li className="li-info">
                        <span className="title-text">Net ROI: </span>
                        {productDetails[0].netROI}
                      </li>
                    </ul>
                  </div>
                )}

                {content === "notes" && (
                  <div>
                    <ul>
                      <h3 className="my-3 text-decoration-underline info-header">
                        Notes
                      </h3>
                      <li>
                        <span className="title-text">Note: </span>
                        {notes ? notes : "No notes for this product"}
                        <button
                          className="edit-note-button"
                          onClick={() => handleEditNotes()}
                        >
                          Edit
                        </button>

                        {isEditing && (
                          <div className="note-edit-container">
                            <textarea
                              className="edit-note-textarea"
                              value={editText}
                              onChange={(e) => setEditText(e.target.value)}
                            ></textarea>
                            <button
                              className="edit-note-submit-button"
                              onClick={() => submitNotes()}
                            >
                              Save
                            </button>
                            <button
                              className="edit-note-close-button"
                              onClick={() => closeEditNotes()}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </li>
                    </ul>
                  </div>
                )}
                {content === "supplier" && (
                  <div>
                    <h3 className="my-3 text-decoration-underline info-header">
                      Supplier Data
                    </h3>
                    <ul className="ul-info">
                      <li className="li-info">
                        <span className="title-text">Supplier Details: </span>
                        {productDetails[0]?.supplier?._id ? (
                          <ul className="p-0 ul-info">
                            <li className="li-info ">
                              <span className="text-decoration-underline">
                                Supplier
                              </span>
                              <br />
                              <span className="title-text">Name: </span>
                              {productDetails[0]?.supplier.supplierName}
                            </li>
                            <li className="li-info">
                              <span className="title-text">URL: </span>
                              <br />
                              <a
                                href={productDetails[0]?.supplier.supplierLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Go To URL
                              </a>
                            </li>
                            <br />

                            <li className="li-info">
                              <span className="text-decoration-underline">
                                Contact Person:
                              </span>
                              <br />
                              {productDetails[0]?.supplier.contactPerson?.name}
                            </li>
                            <li className="li-info">
                              <span className="title-text">Email: </span>
                              {productDetails[0]?.supplier.contactPerson?.email}
                            </li>
                            <li className="li-info">
                              <span className="title-text">Phone Number: </span>
                              <br />
                              {
                                productDetails[0]?.supplier.contactPerson
                                  ?.phoneNumber
                              }
                            </li>
                            <li className="li-info">
                              <span className="title-text">
                                Extension Code:{" "}
                              </span>
                              {
                                productDetails[0]?.supplier.contactPerson
                                  ?.extensionCode
                              }
                            </li>
                            <br />

                            <li className="li-info">
                              <span className="title-text">City: </span>
                              {
                                productDetails[0]?.supplier.supplierAddress
                                  ?.city
                              }
                            </li>
                            <li className="li-info">
                              <span className="title-text">State: </span>
                              {
                                productDetails[0]?.supplier.supplierAddress
                                  ?.state
                              }
                            </li>
                            <li className="li-info">
                              <span className="title-text">Street: </span>
                              {
                                productDetails[0]?.supplier.supplierAddress
                                  ?.street
                              }
                            </li>
                            <li className="li-info">
                              <span className="title-text">Zip Code: </span>
                              {
                                productDetails[0]?.supplier.supplierAddress
                                  ?.zipCode
                              }
                            </li>
                          </ul>
                        ) : (
                          "No supplier selected for this product"
                        )}
                      </li>
                    </ul>
                  </div>
                )}
                {content === "variations view" && (
                  <div>
                    <h3 className="my-3 text-decoration-underline mt-3 info-header">
                      Variations View
                    </h3>
                    {productDetails[0]?.variations.length > 0 ? (
                      <Table
                        maxHeightClass="max-h-250px"
                        tableData={productDetails[0]?.variations.map(
                          (item: IProductDetails) => {
                            // Create a copy of the item without the _id key
                            const { _id, ...rest } = item;
                            return rest;
                          }
                        )}
                      />
                    ) : (
                      <div className="d-flex title-text ">
                        No Variations View for this product
                      </div>
                    )}
                  </div>
                )}

                {content === "buy box statistics" && (
                  <div>
                    <h3 className="my-3 text-decoration-underline info-header">
                      Buy Box Statistics
                    </h3>
                    {productDetails[0]?.buyboxStatistics.length > 0 ? (
                      <Table
                        maxHeightClass="max-h-250px"
                        tableData={productDetails[0]?.buyboxStatistics.map(
                          (item: IProductDetails) => {
                            // Create a copy of the item without the _id key
                            const { _id, ...rest } = item;
                            return rest;
                          }
                        )}
                        noMaxHeight={true}
                      />
                    ) : (
                      <div className="d-flex title-text">
                        No BuyBox statistics for this product
                      </div>
                    )}
                  </div>
                )}

                {content === null && <div>Select a button to view content</div>}
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ProfitableProductsDetails;
