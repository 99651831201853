import { IShipmentPlan } from "pages/shipmentPlans/interfaces";
import { useEffect, useState } from "react";
import "./styles.scss";
import ShipmentPlanCheckout from "../ShipmentPlan/ShipmentPlanCheckout";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { STRIPE_KEYS } from "utils/stripe";

interface FloatingWindowProps {
	handleFindWarehouseRedirect: () => void;
	handleOnUpdate: () => void;
	handlePaymentStatusRedirect: () => void;
	handleorderConfirmationRedirect: () => void;
	handleLinkOrders: () => void;
	shipmentPlanData?: IShipmentPlan;
	totalPriceCb?: string;
}
const publishableKey = STRIPE_KEYS.STRIPE_PUBLISHABLEKEY

if (!publishableKey) {
  throw new Error('Stripe publishable key is not defined');
}

const stripePromise = loadStripe(
	publishableKey
);

const FloatingWindow = ({
	handleFindWarehouseRedirect,
	handleOnUpdate,
	handlePaymentStatusRedirect,
	handleorderConfirmationRedirect,
	handleLinkOrders,
	totalPriceCb,
	shipmentPlanData,
}: FloatingWindowProps) => {
	const [isWarehouseSelected, setWarehouseSelected] =
		useState(false);
	const [isInventorySelected, setInventorySelected] =
		useState<boolean>(false);
	const [closeUserEdit, setCloseUserEdit] = useState(false);
	const [isOrdersLinked, setOrdersLinked] = useState(false);

	// check if any shipment plan product is empty
	useEffect(() => {
		const areAllProductFieldsFilled =
			shipmentPlanData?.products?.every((product) => {
				return (
					product.boxHeight &&
					product.boxLength &&
					product.boxWidth &&
					product.boxes &&
					product.unitsPerBox &&
					product.fnsku &&
					product.upc &&
					product.weightPerBox
				);
			}) ?? false;

		setInventorySelected(areAllProductFieldsFilled);
	}, [shipmentPlanData]);

	useEffect(() => {
		if (shipmentPlanData?.warehouseOwner?._id) {
			setWarehouseSelected(true);
		}

		if (shipmentPlanData?.payment.paid) {
			setCloseUserEdit(true);
		}
	}, [shipmentPlanData]);

	return (
		<div>
			{!closeUserEdit && (
				<div className="floating-window">
					<div className="floating-header">
						<button
							type="button"
							onClick={handleFindWarehouseRedirect}
							disabled={closeUserEdit}
							className={`stepper-button`}>
							Confirm Warehouse
						</button>
						<button
							className={`stepper-button btn-disabled`}
							disabled={
								!isWarehouseSelected || closeUserEdit
							}
							onClick={handleOnUpdate}>
							Confirm ASINS
						</button>
						<button
							className={`stepper-button btn-disabled`}
							disabled={
								!isWarehouseSelected || closeUserEdit || !isInventorySelected
							}
							onClick={handleLinkOrders}>
							Link Orders
						</button>
						<button
							data-bs-toggle="modal"
							data-bs-target="#checkoutModal"
							className={`stepper-button btn-disabled`}
							disabled={
								!isInventorySelected || closeUserEdit
							}>
							Pay Order
						</button>
					</div>
				</div>
			)}
			{closeUserEdit && (
				<div className="floating-window">
					<button
						className={`stepper-button-confirmation btn-disabled `}
						onClick={handleorderConfirmationRedirect}
						disabled={!closeUserEdit}>
						Order Confirmation
					</button>
				</div>
			)}
			<Elements stripe={stripePromise}>
				<ShipmentPlanCheckout
					totalPriceCb={totalPriceCb}
					handlePaymentStatusRedirect={
						handlePaymentStatusRedirect
					}
				/>
			</Elements>
		</div>
	);
};

export default FloatingWindow;
