import LogoBig from "assets/images/LogoPrincipal.png";
import { Link } from "react-router-dom";

interface NavbarProps {
  toggleMenu: () => void;
  isMenuOpen: boolean;
}

const Navbar: React.FC<NavbarProps> = (props) => {
  return (
    <nav
      role="navigation"
      aria-label="Main Navigation"
      className="absolute top-0 left-0 right-0 p-4 z-10 flex items-center justify-between"
    >
      {/* Logo */}
      <Link to="/">
        <picture>
          <source srcSet={LogoBig} media="(min-width: 768px)" />
          <img src={LogoBig} alt="Unielogics Logo" className="logo-img" />
          <style>{`
              .logo-img {
                width: 12rem; /* Default size for larger screens */
              }
              @media (max-width: 768px) {
                .logo-img {
                  width: 12rem; /* Change size when LogoSmall is visible */
                }
              }
            `}</style>
        </picture>
      </Link>

      {/* Desktop Menu */}
      <ul className="hidden lg:flex space-x-6">
        <li>
          <Link to="/" className="text-purple-300 hover:text-gray-200">
            Home
          </Link>
        </li>
        <li className="text-purple-500">/</li>
        <li>
          <Link to="/team" className="text-purple-300 hover:text-gray-200">
            Our Team
          </Link>
        </li>
        <li className="text-purple-500">/</li>
        <li>
          <Link to="/faq" className="text-purple-300 hover:text-gray-200">
            FAQ
          </Link>
        </li>
      </ul>

      {/* Burger Menu */}
      <button
        className="lg:hidden text-purple-300 hover:text-gray-200"
        onClick={props.toggleMenu}
        aria-expanded={props.isMenuOpen}
        aria-label="Toggle menu"
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </nav>
  );
};

export default Navbar;
