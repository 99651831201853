import {
  IAmazonDataShipmentPlanDetails,
  IUpdateShipmentPlanAmazonData,
} from "./interfaces";
import React, { useEffect, useState, useContext } from "react";
import { updateShipmentPlanAmazonDataRequest } from "./api";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { QUERY_KEYS } from "utils";
import { getFormattedAddress, reformatAddress } from "utils/globals";
import { isEqual } from "lodash";
import { IContextUser } from "common/interfaces";
import { UserContext } from "utils/context";

import "./styles.scss";

interface EditableFieldProps {
  label: string;
  name: string;
  value: string;
  isEditing: boolean;
  type?: string;
  [key: string]: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditableField: React.FC<EditableFieldProps> = ({
  label,
  name,
  value,
  isEditing,
  type = "text",
  onChange,
}) => {
  return (
    <div className={isEditing ? "mb-2" : ""}>
    <label className="secondary-title">{label}:</label>
      {isEditing ? (
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          className="bg-black text-white border border-gray-600 rounded p-0.5"
        />
      ) : type === "url" ? (
        <a href={value} className="text-blue-400 hover:underline">
          {value || "No URL Provided"}
        </a>
      ) : (
        <span className="amazon-data-value">{value || "N/A"}</span>
      )}
    </div>
  );
};

const AmazonDataShipmentPlanDetails = ({
  amazonData,
  shipmentPlanId,
}: IAmazonDataShipmentPlanDetails) => {
  const [isEditing, setIsEditing] = useState(false);
  const addressString = amazonData.shipments[0]?.shipTo;
  const data = amazonData;
  const { shipDate, trackShipmentUrl, trackShipmentValue, workFlowId } = data;
  const user: IContextUser = useContext(UserContext)!;
  const { email } = user;

  const { shipToName, address, city, state, zipCode, country } =
    getFormattedAddress(addressString);
  const [editedData, setEditedData] = useState({
    shipToName,
    address,
    city,
    state,
    zipCode,
    country,
    shipDate,
    trackShipmentUrl,
    trackShipmentValue,
    workFlowId,
  });

  const [shipmentDisplay, setShipmentDisplay] = useState(false);

  const queryClient = useQueryClient();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedData((prev) => ({ ...prev, [name]: value }));
  };

  const toggleEditing = () => {
    if (isEditing) {
      handleSubmitToDatabase();
    }

    setIsEditing(!isEditing);
  };

  const handleSubmitToDatabase = () => {
    const reformatAddressString = reformatAddress(
      editedData.shipToName,
      editedData.address,
      editedData.city,
      editedData.state,
      editedData.zipCode,
      editedData.country
    );

    const shouldUpdateName = isEqual(editedData.shipToName, shipToName);
    const shouldUpdateAddress = isEqual(editedData.address, address);
    const shouldUpdateCity = isEqual(editedData.city, city);
    const shouldUpdateState = isEqual(editedData.state, state);
    const shouldUpdateZipCode = isEqual(editedData.zipCode, zipCode);
    const shouldUpdateCountry = isEqual(editedData.country, country);
    const shouldUpdateDate = isEqual(editedData.shipDate, shipDate);
    const shouldUpdateTrackShipmentUrl = isEqual(
      editedData.trackShipmentUrl,
      trackShipmentUrl
    );
    const shouldUpdateTrackShipmentValue = isEqual(
      editedData.trackShipmentValue,
      trackShipmentValue
    );
    const shouldUpdateWorkFlowId = isEqual(editedData.workFlowId, workFlowId);

    const areValuesIdentical =
      shouldUpdateName &&
      shouldUpdateAddress &&
      shouldUpdateCity &&
      shouldUpdateState &&
      shouldUpdateZipCode &&
      shouldUpdateCountry &&
      shouldUpdateDate &&
      shouldUpdateTrackShipmentUrl &&
      shouldUpdateTrackShipmentValue &&
      shouldUpdateWorkFlowId;

    if (areValuesIdentical) {
      toast.error("No changes detected.");
      return;
    } else {
      const payload: IUpdateShipmentPlanAmazonData = {
        email,
        shipmentPlanId: shipmentPlanId,
        amazonData: {
          customerName: editedData.shipToName,
          customerCity: editedData.city,
          customerCountry: editedData.country,
          customerState: editedData.state,
          customerZipcode: editedData.zipCode,
          customerNumber: amazonData.customerNumber,
          customerStreet: editedData.address,
          feeSummary: amazonData.feeSummary,
          placementFees: amazonData.placementFees,
          prepLabelFees: amazonData.prepLabelFees,
          shipDate: editedData.shipDate,
          shipFrom: amazonData.shipFrom,
          shippingCharges: amazonData.shippingCharges,
          trackShipmentUrl: editedData.trackShipmentUrl,
          trackShipmentValue: editedData.trackShipmentValue,
          workFlowId: editedData.workFlowId,
          shipments: [
            {
              amazonReferenceId:
                amazonData.shipments?.[0]?.amazonReferenceId || "",
              boxes: amazonData.shipments?.[0]?.boxes || "",
              contents: amazonData.shipments?.[0]?.contents || "",
              shipFrom: amazonData.shipments?.[0]?.shipFrom || "",
              shipTo: reformatAddressString,
              shipmentId: amazonData.shipments?.[0]?.shipmentId || "",
              shipmentName: amazonData.shipments?.[0]?.shipmentName || "",
              skus: amazonData.shipments?.[0]?.skus || "",
              thumbnailUrls: amazonData.shipments?.[0]?.thumbnailUrls || "",
              units: amazonData.shipments?.[0]?.units || "",
            },
          ],
        },
      };

      updateShipmentAmazonData(payload);
    }
  };

  const { mutate: updateShipmentAmazonData } = useMutation(
    updateShipmentPlanAmazonDataRequest,
    {
      onSuccess: (data) => {
        if (data.status) {
          toast.success("Successfully updated shipment plan products.");
        }
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.GET_SHIPMENT_PLAN,
        });
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
            "There was an error updating your plan products."
        );
      },
    }
  );

  const handleShipmentDisplay = () => {
    setShipmentDisplay(!shipmentDisplay);
  };

  return (
    <div className="d-flex flex-column align-items-md-start align-items-sm-center amazon-shipment-plan-details me-5 text-white p-2 rounded shadow-lg">
      <h2 className="secondary-title">Customer Information</h2>
      <div className="flex gap-8">
        <div>
          {/* New Fields for Name, Address, City, State, and Zip Code */}

          <EditableField
            label="Name"
            name="shipToName"
            //@ts-ignore
            value={editedData.shipToName}
            isEditing={isEditing}
            onChange={handleChange}
          />
          <EditableField
            label="Address"
            name="address"
            //@ts-ignore
            value={editedData.address}
            isEditing={isEditing}
            onChange={handleChange}
            className="wide-input"
          />
          <EditableField
            label="City"
            name="city"
            //@ts-ignore
            value={editedData.city}
            isEditing={isEditing}
            onChange={handleChange}
          />
          <EditableField
            label="State"
            name="state"
            //@ts-ignore
            value={editedData.state}
            isEditing={isEditing}
            onChange={handleChange}
          />
          <EditableField
            label="Zip Code"
            name="zipCode"
            //@ts-ignore
            value={editedData.zipCode}
            isEditing={isEditing}
            onChange={handleChange}
          />
        </div>

        <div>
          {shipmentDisplay && (
            <div>
              <EditableField
                label="Ship date"
                name="shipDate"
                value={editedData.shipDate}
                isEditing={isEditing}
                type="date"
                onChange={handleChange}
              />
              <EditableField
                label="Tracking URL"
                name="trackShipmentUrl"
                value={editedData.trackShipmentUrl}
                isEditing={isEditing}
                type="url"
                onChange={handleChange}
              />
              <EditableField
                label="Track Shipment Value"
                name="trackShipmentValue"
                value={editedData.trackShipmentValue}
                isEditing={isEditing}
                onChange={handleChange}
              />
              <EditableField
                label="Workflow ID"
                name="workFlowId"
                value={editedData.workFlowId}
                isEditing={isEditing}
                onChange={handleChange}
              />
            </div>
          )}

          <div className="d-flex flex-column align-items-md-start align-items-sm-center ml-9 pl-10">
            <i className="fa fa-expand " onClick={handleShipmentDisplay}></i>
            <button
              onClick={toggleEditing}
              className="bg-blue-600 text-white px-10 rounded hover:bg-blue-700 mt-1 ml-2 bi bi-save"
            >
              {isEditing ? "Save" : "Edit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmazonDataShipmentPlanDetails;
