
const API_URL = "https://unielogics-backend.onrender.com";
const KEEPA_URL = "https://api.keepa.com";
// const corsLocal = "https://cors-anywhere.herokuapp.com/";
const corsLocal = "";

export const apiRoutes = {
  LOGIN: `${API_URL}/users/login`,
  GOOGLE_LOGIN: `${API_URL}/users/googleLogin`,
  ALL_USERS: `${API_URL}/users/getAll`,
  REGISTER: `${API_URL}/users/register`,
  RESEND_VERIFY_EMAIL: `${API_URL}/users/resendVerifyEmail`,
  VERIFY_EMAIL: `${API_URL}/users/verifyEmail`,
  CHECKOUT: `${API_URL}/users/checkout`,
  PROFILE: `${API_URL}/users/profile`,
  CHECK_TOKEN: `${API_URL}/users/checkAuthentication`,
  SURVEY: `${API_URL}/users/survey`,
  SIMPLE_PROFILE: `${API_URL}/users/profileBasedOnEmail`,
  UPDATE_PROFILE: `${API_URL}/users/profileUpdate`,
  BILLING: `${API_URL}/users/billing`,
  CREATE_PAYMENT: `${API_URL}/users/handle-checkout`,
  STRIPE_SUBSCRIPTION: `${API_URL}/users/create-subscription`,
  BUCKETS_PRODUCTS: `${API_URL}/buckets/getProducts`,
  BLACKLIST: `${API_URL}/brands/getAllBlacklistBrands`,
  DELETE_BUCKETS_PRODUCT: `${API_URL}/buckets/delete`,
  FORGOT_PASSWORD: `${API_URL}/users/forgotPassword`,
  RESET_PASSWORD: `${API_URL}/users/resetPassword`,
  KEEPA_PRODUCT: `${corsLocal}${KEEPA_URL}/product?key=doer4spts438qteukukrm0uc6rdg4l873hhj9veb1g4m2856160j8n3gs7edgm89&domain=1&stats=365&buybox=1&history=1&offers=20`,
  POST_BLACKLIST_BRANDS: `${API_URL}/brands/addBlacklistBrand`,
  DELETE_BLACKLIST_BRAND: `${API_URL}/brands/deleteBlacklistBrand`,
  EDIT_BLACKLIST_BRAND: `${API_URL}/brands/editBlacklistBrand`,
  EDIT_FEEDBACK_URL: `${API_URL}/fLinks/editFLink`,
  SEARCH_BLACKLIST_BRAND: `${API_URL}/brands/searchBlacklistBrand`,
  GET_FEEDBACK_URL: `${API_URL}/fLinks/getAllFLinks`,
  GET_FOLDERS: `${API_URL}/folders/get`,
  GET_SUPPLIERS: `${API_URL}/suppliers/getAll`,
  POST_ADD_FOLDER: `${API_URL}/folders/add`,
  DELETE_FOLDER: `${API_URL}/folders/delete`,
  EDIT_FOLDER_NAME: `${API_URL}/folders/update`,
  DELETE_ITEM_FROM_FOLDER: `${API_URL}/folders/itemFromFolder`,
  CHANGE_DEFAULT_FOLDER: `${API_URL}/folders/editDefault`,
  PROFITABLE_PRODUCT_DETAILS: `${API_URL}/profProducts/profProductDetails`,
  ADD_PROFITABLE_PRODUCT_DETAILS: `${API_URL}/profProducts/addProfProductDetails`,
  UPDATE_PROFITABLE_PRODUCT_DETAILS: `${API_URL}/profProducts/updateProfProductDetails`,
  GET_KEEPA_CHART_DATA: `${API_URL}/keepa/getChartData`,
  GET_SAVED_SEARCHES: `${API_URL}/savedSearches/getAll`,
  DELETE_SAVED_SEARCHES: `${API_URL}/savedSearches/delete`,
  POST_SUPPLIERS: `${API_URL}/suppliers/add`,
  DELETE_SUPPLIERS: `${API_URL}/suppliers/delete`,
  PUT_SUPPLIER: `${API_URL}/folders/updateSupplierForItemInFolder`,
  POST_ADD_WAREHOUSE_OWNER: `${API_URL}/wOwners/add`,
  EDIT_WAREHOUSE_OWNER: `${API_URL}/wOwners/edit`,
  GET_WAREHOUSE_OWNERS: `${API_URL}/wOwners/getAll`,
  DELETE_WAREHOUSE_OWNER: `${API_URL}/wOwners/delete`,
  POST_ADD_SHIPMENT_PLAN: `${API_URL}/shipmentPlan/add`,
  GET_ALL_SHIPMENT_PLAN: `${API_URL}/shipmentPlan/getAll`,
  DELETE_SHIPMENT_PLAN: `${API_URL}/shipmentPlan/delete`,
  GET_SHIPMENT_PLAN: `${API_URL}/shipmentPlan/getById`,
  DELETE_SHIPMENT_PLAN_PRODUCT: `${API_URL}/shipmentPlan/deleteProduct`,
  UPDATE_SHIPMENT_PLAN_PRODUCT: `${API_URL}/shipmentPlan/update`,
  POST_SHIPMENT_PLAN_UPLOAD_FILES: `${API_URL}/shipmentPlan/upload-files`,
  POST_PAY_SHIPMENT_PLAN: `${API_URL}/wPayment/paymentIntent`,
  DELETE_SHIPMENT_PLAN_FILE: `${API_URL}/shipmentPlan/delete-file`,
  POST_CONFIRM_PAYMENT_INTENT: `${API_URL}/wPayment/confirmPayment`,
  UPDATE_PAYMENT_STATUS: `${API_URL}/shipmentPlan/update-payment-status`,
  GET_CUSTOMERS: `${API_URL}/customers/getAll`,
};

export const pageRoutes = {
  // MAIN: "/",
  LANDINGPAGE: "/home",
  CustomPage404: "/404",
  ThanksPage: "/thank-you",
  OurTeam: "/team",
  FAQPage: "/faq",
  PrivacyPolicyPage: "/privacy-policy",
  TermsConditionPage: "/terms-and-conditions",
  REGISTER: "/register",
  LOGIN: "/login",
  RESEND_VERIFY_EMAIL: "/resend-verify-email",
  VERIFY_EMAIL: "/verify-email",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  PROFILE: "/profile",
  PROFILE_EDIT: "/profile-edit",
  BUCKETS: "/buckets",

  CHARTS: "/charts2",
  THANK_YOU: "/thank-you-page",
  CHECKOUT_ERROR: "/checkout-error",
  PROFITABLE_PRODUCTS: "/profitable-products",
  PROFITABLE_PRODUCTS_DETAILS: "/profitable-products/details",
  SAVED_SEARCHES: "/saved-searches",
  SUPPLIERS: "/suppliers",
  TEAMS:"/teams",
  SHIPMENT_PLANS: "/shipment-plans",
  SHIPMENT_PLAN_ID: "/shipment-plan",
  WAREHOUSES_MAP_PLAN_ID: "/warehouses-map",
  SHIPMENT_PLAN_PAYMENT_THANK_YOU: `/w-payment-thank-you`,
  SHIPMENT_PLAN_PAYMENT_ERROR: `/w-payment-checkout-error`,
  SHIPMENT_PLAN_LOADING: "/shipment-plan-loading",
  CUSTOMER_VIEW: "/customer-view",
  TECH_SUPPORT: "/tech-support",

  // ADMIN ROUTES
  ADMIN: "/admin/users/a61366e5-c437-45ab-ba17-4e10025abfa7",
  ADMIN_BLACKLIST: "/admin/blacklist",
  FEEDBACK_URL: "/admin/feedback-url",
  WAREHOUSE_OWNERS: "/admin/warehouse-owners",

  // infoplus
  INFO_PLUS: "/infoplus",
  INFO_PLUS_DETAILS: "/infoplus/details",
  INFO_PLUS_DETAILS_ID: "/infoplus/details/:id",
  INFO_PLUS_PROCESS_ORDER: "/infoplus/process-order",
};
