import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import { LatLng, Path, Popup, Tooltip } from "leaflet";
import { GeoJsonObject } from "geojson";
import "leaflet/dist/leaflet.css";
import usStates from "./us-states.json";
import { TooltipManager } from "./subComponent/TooltipManager";
import { ResetButton } from "./subComponent/ResetButton";
import { HighlightedStatesDisplay } from "./subComponent/HighlightedStatesDisplay";
import { MAX_ALLOWED_SELECTED_STATES } from "./constants";
import { updateHighlightedStates } from "./helper";
import useSurveyStateStore from "components/SurveyModal/useSurveyStore";


export const SurveyMap = () => {
  const [currentStateName, setStateName] = useState<string | null>(null);
  const [position, setPosition] = useState<LatLng | null>(null);
  const tooltipRef = useRef<Tooltip | null>(new Tooltip());
  const { setSurveyStates } = useSurveyStateStore()
  const [highlightedStates, setHighlightedStates] = useState<string[]>(new Array(MAX_ALLOWED_SELECTED_STATES).fill(""));

  const handleStateClick = (stateName: string) => {

    // const newStates = updateHighlightedStates(highlightedStates, stateName);
    // // Logs the old state and the new state
    // console.log(newStates);
    setHighlightedStates((prev) => {
      // If the state is already selected, remove it
      if (prev.includes(stateName)) {
        const updatedStates = prev.map((state) =>
          state === stateName ? "" : state
        );
        return updatedStates;
      } else {
        // Find the first empty string and replace it with the stateName
        const index = prev.findIndex((state) => state === "");
        if (index !== -1) {
          // Replace the first empty string with the stateName
          const updatedStates = [...prev];
          updatedStates[index] = stateName;
          return updatedStates;  // Return updated state
        }
      }
      return prev
    });

  };

  useEffect(() => {
    // console.log("highlightedStates updated:", highlightedStates);
    setSurveyStates(highlightedStates);
  }, [highlightedStates, setSurveyStates]);



  const onStateHover = (stateName: string, latlng: LatLng) => {
    if (currentStateName !== stateName || currentStateName === null) {
      setStateName(stateName);
    }
    setPosition(latlng);
  };

  const onHoverOut = () => {
    setStateName(null);
    setPosition(null);
  };

  const getStateStyle = (stateName: string) => ({
    fillColor: highlightedStates.includes(stateName)
      ? "#84ADE9"
      : currentStateName === stateName
        ? "lightblue"
        : "6b7280",
    weight: 0.5,
    opacity: 1,
    color: highlightedStates.includes(stateName) ? "white" : "#B4B4B7",
    fillOpacity: highlightedStates.includes(stateName) ? 1 : 0.4,
  });

  const handleEachFeature = (feature: any, layer: Path) => {
    const stateName = feature.properties.name;
    const stateCode = feature.properties.stateCode;

    const tooltip = new Tooltip({
      permanent: true,
      direction: "center",
      className: "state-code-tooltip"
    }).setContent(stateCode);

    layer.bindTooltip(tooltip);

    layer.on({
      click: () => handleStateClick(stateName),
      mouseover: (e) => {
        onStateHover(stateName, e.latlng);
        layer.setStyle({ fillOpacity: 0.6 });
      },
      mousemove: (e) => {
        onStateHover(stateName, e.latlng);
      },
      mouseout: () => {
        onHoverOut();
        layer.setStyle({
          fillOpacity: highlightedStates.includes(stateName) ? 0.8 : 0.4,
        });
      },
    });
  };
  const resetHighlightedStates = () => {
    setHighlightedStates(new Array(MAX_ALLOWED_SELECTED_STATES).fill(""));

  };

  return (
    <div style={{ display: "flex", width: "100%", height: "500px",overflow: "hidden"}}>
      <div style={{ flex: "0 0 70%",  }}>
        <MapContainer style={{ height: "600px", width: "100%" }} zoom={4} center={[37.8, -96]}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <GeoJSON
            data={usStates as GeoJsonObject}
            style={(feature) => getStateStyle((feature as any).properties.name)}
            onEachFeature={(feature, layer) => handleEachFeature(feature, layer as Path)}
          />
          {(
            <TooltipManager position={position!} stateName={currentStateName!} tooltipRef={tooltipRef} />
          )}
        </MapContainer>
      </div>
      <div style={{ flex: "0 0 30%", padding: "10px" }}>

        <HighlightedStatesDisplay highlightedStates={highlightedStates} setHighlightedStates={handleStateClick} />
        <ResetButton resetHighlightedStates={resetHighlightedStates} />

      </div>

    </div>
  );
};
