import "./styles.scss";
import { Footer, Loading, SidebarMenu } from "components";
import { customerTableHeaders } from "utils/configs";
import { ICustomers } from "./interfaces";
import { useContext, useEffect, useState } from "react";
import { IContextUser } from "common/interfaces";
import { QUERY_KEYS, UserContext } from "utils";
import { getCustomersRequest } from "./api";
import { useQuery } from "react-query";
import React from "react";
import { FaCaretDown, FaCaretUp, FaEquals } from "react-icons/fa";

const CustomerView = () => {
  const [allCustomers, setAllCustomers] = useState<ICustomers[]>([]);
  const [displayedCustomers, setDisplayedCustomers] =
    useState<ICustomers[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [sortNoDirection, setSortNoDirection] = useState<string>("neutral");

  // Fetch Customer Data
  const { data, isLoading: isLoadingCustomers } = useQuery(
    QUERY_KEYS.GET_CUSTOMERS,
    () => getCustomersRequest(),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    console.log(data);
    if (data === undefined) {
      return;
    }
    setAllCustomers(data);
    setDisplayedCustomers(data);
  }, [data])

  // Search By Customer No
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setSearchKeyword(keyword);

    if (keyword) {
      const filtered = allCustomers.filter((customer) =>
        customer.customerNo.toLowerCase().includes(keyword.toLowerCase())
      );
      setDisplayedCustomers(filtered);
    } else {
      setDisplayedCustomers(allCustomers);
    }
  };

  const clearSearch = () => {
    setSearchKeyword("");
    setDisplayedCustomers(allCustomers);
  };

  // Sort Customer No.s
  const sortCustomerNos = () => {
    let sortedData;
    if (sortNoDirection === "neutral") {
      sortedData = [...displayedCustomers].sort((a, b) =>
        a.customerNo.localeCompare(b.customerNo)
      );
      setSortNoDirection("asc");
    } else if (sortNoDirection === "asc") {
      sortedData = [...displayedCustomers].sort((a, b) =>
        b.customerNo.localeCompare(a.customerNo)
      );
      setSortNoDirection("desc");
    } else {
      sortedData = [...allCustomers];
      setSortNoDirection("neutral");
    }
    setDisplayedCustomers(sortedData);
  };

  const customerDataTable = displayedCustomers.map((customer, index) => {
    return (
      <tr key={index}>
        <td className="align-middle text-center white-space-nowrap">
          {customer.customerNo}
        </td>
        <td className="align-middle text-center white-space-nowrap">
          {customer.customerName}
        </td>
        <td className="align-middle text-center white-space-nowrap">
          {customer.customerStreet}
        </td>
        <td className="align-middle text-center white-space-nowrap">
          {customer.customerCity}
        </td>
        <td className="align-middle text-center white-space-nowrap">
          {customer.customerState}
        </td>
        <td className="align-middle text-center white-space-nowrap">
          {customer.customerZipcode}
        </td>
      </tr>
    );
  });

  return (

    <>
      <div className="content">
        <div className="container-fluid">
          {/* navigation sidebar on the left */}
          <SidebarMenu active="customer-view" sidebarOnly={true} />

          {/* Search and Sort */}
          <div className="flex flex-row items-center justify-left mb-3 gap-5">
            {/* Search Input */}
            <div className="flex flex-row gap-2">
              <input
                type="text"
                value={searchKeyword}
                onChange={handleSearchChange}
                placeholder="Search Customer No"
                className="text-black px-3 py-1 placeholder-gray-700"
              />
              <button
                onClick={clearSearch}
                className="bg-[#3B82F6] text-white text-md font-medium px-2 hover:bg-blue-600 transition-colors"
              >
                Clear
              </button>
            </div>
            {/* Sort Buttons */}
            <button onClick={sortCustomerNos} className="flex items-center gap-1">
              Sort Customer No.{" "}
              {sortNoDirection === "asc" ? (
                <FaCaretUp />
              ) : sortNoDirection === "desc" ? (
                <FaCaretDown />
              ) : (
                <FaEquals />
              )}
            </button>
          </div>

          {/* loading shipment plans */}
          {isLoadingCustomers ? (
            <div className="row">
              <div className="col-12 d-flex justify-content-center loading-wrapper">
                <Loading />
              </div>
            </div>
          ) : (
            allCustomers.length === 0 && (
              <div className="no-items text-center">
                <h3>You have no customers yet!</h3>
                <span className="no-items-icon-wrapper">
                  <i className="fas fa-paper-plane"></i>
                </span>
              </div>
            )
          )}
          {/* end of loading customers */}

          {/* table of customers */}
          {!isLoadingCustomers && allCustomers.length > 0 && (
            <div className="row">
              <div className="col-sm">
                <div className="table-responsive max-h-78vh">
                  <table className="table table-sm">
                    <thead className="sticky-header">
                      <tr>
                        {customerTableHeaders.map((header) => (
                          <th className="text-center" key={header.name}>
                            {header.name}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>{customerDataTable}</tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>

  );
};

export default CustomerView;
