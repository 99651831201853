const OrderInformation = ({
}) => {
  return (
      <div className='p-2'>
            <h2 className="secondary-title">Order(s)</h2>
            {/* <p>
                <span className='secondary-title'>Name:</span> {warehouseOwner?.businessName}
            </p> */}
        </div>
  );
};

export default OrderInformation;
