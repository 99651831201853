
import { AddressAndContact, ISupplier } from '../interfaces';
import { useState } from 'react';
import { Modal } from 'components';
import { AddressDetails } from 'utils/typesUtils/addressTypes';

interface SupplierRowProps {
    supplier: ISupplier;
    index: number;
    setSupplierCoordinates: React.Dispatch<React.SetStateAction<{ lat: number; long: number; name: string }>>;
    setShouldDisplayLocationModal: React.Dispatch<React.SetStateAction<boolean>>;
    setSupplierIdToDelete: React.Dispatch<React.SetStateAction<string>>;
    setShowDeleteSupplierModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleEdit: (supplier: ISupplier) => void;
}

const SupplierRow: React.FC<SupplierRowProps> = ({
    supplier,
    index,
    setSupplierCoordinates,
    setShouldDisplayLocationModal,
    setSupplierIdToDelete,
    setShowDeleteSupplierModal,
    handleEdit,
}) => {
    const [viewLink, setViewLink] = useState(false);
    const { street, city, state, zipCode, } = supplier.supplierAddress;

    const supplierAddress: AddressDetails = { street, city, state, zipCode };
    const contactPerson = supplier.contactPerson;
    const iconSize = "2x";
    const renderFields = (fields: AddressAndContact) => { return (<td className="align-middle text-center white-space-nowrap"> {Object.entries(fields).map(([key, value]) => (<div key={key}> <span className="light-gray">{key.charAt(0).toUpperCase() + key.slice(1)}: </span> {value} {/* Ensure the value is rendered as a string */} </div>))} </td>); };
    const handleView = () => {
        setViewLink(true); // Show the modal
    };

    const handleClose = () => {
        setViewLink(false); // Close the modal
    };
    return (
        <tr key={supplier._id}>
            <td className="align-middle text-center white-space-nowrap" style={{ width: '1%' }}>
                {index + 1}
            </td>
            <td className="align-middle text-center white-space-nowrap">{supplier.supplierName}</td>
            {renderFields(supplierAddress)}
            {renderFields(contactPerson)}
            <td className="align-middle text-center white-space-nowrap">
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        setSupplierCoordinates({
                            lat: supplier.supplierAddress.lat,
                            long: supplier.supplierAddress.long,
                            name: supplier.supplierName,
                        });
                        setShouldDisplayLocationModal(true);
                    }}
                >
                    View location
                </button>
            </td>
            <td className="align-middle text-center">
                <span className="icon-wrapper" >
                    <i
                        className="fa fa-pen"
                        onClick={() => handleEdit(supplier)}
                        style={{ fontSize: iconSize }}
                    ></i>
                    <i
                        className="fa fa-trash-alt cursor-pointer ml-2 mr-2 text-red-500"
                        onClick={() => {
                            setSupplierIdToDelete(supplier._id);
                            setShowDeleteSupplierModal(true);
                        }}
                        style={{ fontSize: iconSize, color: '#ef4444' }}
                    ></i>

                    <i
                        className="fa fa-eye"
                        aria-hidden="true"
                        onClick={handleView}
                        style={{ fontSize: iconSize }}
                    ></i>
                    {viewLink && (
                        <Modal
                            modalTitle="Supplier Link"
                            modalTextContent={`${supplier.supplierLink}`}
                            cancelButtonText="OK"
                            cancelModalClick={handleClose}
                        />
                    )}
                </span>
            </td>
        </tr>
    );
};

export default SupplierRow;
