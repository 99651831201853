import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import GoogleSignInButton from "components/GoogleSignInButton";
import { apiRoutes, cookiesNames, localStorageNames, pageRoutes } from "utils";
import { ILoginResponse } from "./interfaces";
import { onLoginSuccess } from "utils/auth";
import { checkRememberMeCookie, setRememberMeCookie } from "utils/auth/cookie";
import Navbar from "./components/navbar";
import MobileMenu from "./components/mobileMenu";
import Footer from "./components/footer";

const updates = [
  {
    text: "Amazon Order Fulfillment Automations",
    date: "Feb, 18th 2025",
  },
  {
    text: "Shopify & Ebay Inventory and Order Management",
    date: "May 9th, 2025",
  },
  {
    text: "Team Management and Accounting Systems",
    date: "May 26th, 2025",
  },
];

const LoginPage: React.FC = () => {
  const [rememberMeChecked, setRememberMeChecked] = useState(
    checkRememberMeCookie()
  );
  const userEmail = rememberMeChecked
    ? Cookies.get(cookiesNames.userEmail)
    : "";

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const iconProps = {
    onClick: togglePasswordVisibility,
    className:
      "absolute right-3 top-1/2 transform -translate-y-1/2 w-6 h-6 cursor-pointer text-black bg-white",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; password: string }>();

  const { mutate, isLoading } = useMutation(
    async (data: { email: string; password: string }) => {
      const response = await fetch(apiRoutes.LOGIN, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        const result = await response.json();
        console.log(result);
        if (response.status === 401) {
          window.location.href = pageRoutes.RESEND_VERIFY_EMAIL;
        }

        throw new Error(result.error.message || "Login Failed");
      }
      return response.json();
    }
  );

  const onSubmit = async (data: { email: string; password: string }) => {
    mutate(data, {
      onSuccess: async (user: ILoginResponse) => {
        toast.success("Login successful!");
        setRememberMeCookie(rememberMeChecked, user.email);
        onLoginSuccess(user);
      },
      onError: (error: any) => {
        toast.error(error.message || "Invalid credentials");
      },
    });
  };

  useEffect(() => {
    Object.keys(errors).forEach((key) => {
      const errorMessage =
        errors[key as keyof typeof errors]?.message || "Invalid input";
      toast.error(String(errorMessage));
    });
  }, [errors]);

  const onRememberMeClick = () => {
    setRememberMeChecked(!rememberMeChecked);
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Top Navigation */}
      <Navbar toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />

      {/* Mobile Menu */}
      {isMenuOpen && <MobileMenu toggleMenu={toggleMenu} />}

      <div className="flex-grow grid grid-cols-1 md:grid-cols-2">
        {/* Left Section */}
        <div
          className="relative flex flex-col p-8 bg-[#0f0c29] text-white left-section"
          style={{ minHeight: "575px" }}
        >
          <div className="flex flex-col justify-center h-full">
            <div className="flex flex-col gap-2">
              <h1 className="font-bold bg-gradient-to-r from-purple-300 to-white bg-clip-text text-transparent text-[28px] lg:text-[44px]">
                Welcome Back, Expert!
              </h1>
              <p className="text-[26px] text-purple-200">
                Here are some of the new features publishing soon..
              </p>
              <div className="flex flex-col gap-2 mt-4">
                {updates.map(({ text, date }, index) => (
                  <div key={index} className="flex items-baseline">
                    <span className="text-purple-200 text-[16px] lg:text-[20px]">
                      - {text}
                    </span>
                    <span className="flex-grow border-dotted border-b border-purple-200 mx-4"></span>
                    <span className="text-purple-200 text-[16px] lg:text-[20px]">
                      {date}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="relative flex flex-col items-center justify-center p-4 sm:p-8 bg-black text-white">
          <div className="flex-1 flex flex-col items-center justify-center w-full max-w-sm gap-6 lg:mt-[90px] mb-6">
            {/* Login Form */}
            <h2 className="text-2xl font-bold mb-6">Log Back In</h2>
            <form
              className="space-y-6 w-full max-w-md"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <label htmlFor="email" className="block text-sm font-medium">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="welcome@valuedcustomer.com"
                  className="w-full px-3 py-2 rounded-md text-black"
                  defaultValue={userEmail}
                  {...register("email", { required: "Email is required" })}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">
                    {String(errors.email.message)}
                  </p>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="password" className="block text-sm font-medium">
                  Password
                </label>
                <div className="flex relative items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Enter Your Password"
                    className="w-full px-3 py-2 rounded-md text-black"
                    {...register("password", {
                      required: "Password is required",
                    })}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 px-2 flex items-center"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {" "}
                    {showPassword ? (
                      <i
                        className="fa fa-eye-slash text-primary"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i className="fa fa-eye text-dark" aria-hidden="true"></i>
                    )}
                  </button>
                </div>
                {errors.password && (
                  <p className="text-red-500 text-sm">
                    {String(errors.password.message)}
                  </p>
                )}
              </div>
              <div className="form-group flex items-center">
                <input
                  type="checkbox"
                  id="remember-me"
                  checked={rememberMeChecked}
                  onChange={onRememberMeClick}
                  className="mr-2"
                />
                <label htmlFor="remember-me" className="text-sm">
                  Remember me
                </label>
                <Link
                  to={pageRoutes.FORGOT_PASSWORD}
                  className="text-sm ml-auto"
                >
                  Forgot Password?
                </Link>
              </div>
              <button
                type="submit"
                className="btn w-full mt-3 bg-[#3B82F6] text-white text-lg font-medium px-16 hover:bg-blue-600 transition-colors"
              >
                {isLoading ? "Logging in..." : "Log In"}
              </button>
            </form>
            <div className="mt-6">
              <GoogleSignInButton />
            </div>
            <div className="mt-6 text-sm">
              Don't have an account?{" "}
              <Link
                to={{ pathname: "/register", search: "?plan=plan37" }}
                className="text-blue-400 hover:underline"
              >
                Register Now
              </Link>
            </div>
          </div>

          {/* Footer */}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
