// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floating-window {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(22, 22, 22);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 15px;
  z-index: 1000;
  width: auto;
  max-width: 1200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #31373c 3px solid;
}
.floating-window .stepper-button-confirmation {
  align-items: center;
  position: center;
  left: 50%;
  width: 200px;
  height: 20px;
  background-color: #08b33e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.floating-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.floating-header strong {
  font-size: 20px;
  color: #000000;
}
.floating-header .stepper-button {
  width: 180px;
  margin: 40px;
  height: 30px;
  padding: 8px 12px;
  background-color: #08b33e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.floating-header .stepper-button:hover {
  background-color: #04683b;
}
.floating-header .stepper-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/components/FloatingWindow/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,SAAA;EACA,2BAAA;EACA,iCAAA;EACA,wCAAA;EACA,mBAAA;EACA,aAAA;EACA,aAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;AACF;AACE;EACE,mBAAA;EACA,gBAAA;EACA,SAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,sCAAA;AACJ;;AAGA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAAF;AACE;EACE,eAAA;EACA,cAAA;AACJ;AACE;EACE,YAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,sCAAA;AACJ;AAEE;EACE,yBAAA;AAAJ;AAGE;EACE,yBAAA;EACA,mBAAA;AADJ","sourcesContent":[".floating-window {\n  position: fixed;\n  bottom: 10px;\n  left: 50%;\n  transform: translateX(-50%);\n  background-color: rgb(22, 22, 22);\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n  border-radius: 10px;\n  padding: 15px;\n  z-index: 1000;\n  width: auto;\n  max-width: 1200px;\n  height: 50px; \n  display: flex;\n  justify-content: center;\n  align-items: center; \n  border: #31373c 3px solid;\n\n  .stepper-button-confirmation {\n    align-items: center;\n    position: center;\n    left: 50%;\n    width: 200px;\n    height: 20px;\n    background-color: #08b33e;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n}\n\n.floating-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  strong {\n    font-size: 20px;\n    color: #000000;\n  }\n  .stepper-button {\n    width: 180px;\n    margin: 40px;\n    height: 30px;\n    padding: 8px 12px;\n    background-color: #08b33e;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n\n  .stepper-button:hover {\n    background-color: #04683b;\n  }\n\n  .stepper-button:disabled {\n    background-color: #6c757d;\n    cursor: not-allowed;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
