import React from 'react';
import { useEffect } from 'react';


const AmazonVendorDetails = ({ vendor }: any) => {
    
    return (
        <div className='p-2'>
            <h2 className="secondary-title">Vendor Information</h2>
            <p>
                <span className='secondary-title'>Name:</span> {vendor?.supplierName}
            </p>
            <p>
                <span className='secondary-title'>Street:</span> {vendor?.supplierAddress?.street || 'N/A'}
            </p>
            <p>
                <span className='secondary-title'>City:</span> {vendor?.supplierAddress?.city || 'N/A'}
            </p>
            <p>
                <span className='secondary-title'>State:</span> {vendor?.supplierAddress?.state || 'N/A'}
            </p>
            <p>
                <span className='secondary-title'>Zip Code:</span> {vendor?.supplierAddress?.zipCode || 'N/A'}
            </p>
        </div>
    );
};

export default AmazonVendorDetails;