// Define the image names as a readonly tuple (using 'as const' to ensure literal types)
export const MarketplaceNames = ["Amazon", "Ebay", "Etsy", "Shopify", "Walmart"] as const;

// Create a mapped type for the images object
type MarketplaceImageNames = typeof MarketplaceNames[number]; // "Amazon" | "Ebay" | "Etsy" | "Shopify" | "Walmart"

// Create the images object with keys of type ImageNames
const images: { [key in MarketplaceImageNames]: string } = {} as { [key in MarketplaceImageNames]: string };

// Dynamically require images based on the names
MarketplaceNames.forEach(name => {
    images[name] = require(`./assets/${name}.png`);
});

export { images as MarketplaceImages };
 const iconSize = 48;
 export const sizeClass = `w-${iconSize} h-${iconSize}`;
