import {
	useMutation,
	useQuery,
	useQueryClient,
} from "react-query";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IoIosArrowDropdown } from "react-icons/io";

import {
	Footer,
	FullScreenLoading,
	SidebarMenu,
} from "components";
import WarehouseOwnersForm from "components/WarehouseOwnersForm";
import { QUERY_KEYS, UserContext, users } from "utils";

import {
	deleteWarehouseOwners,
	getWarehouseOwnersRequest,
} from "./api";
import { warehouseOwnersTableHeaders } from "utils/configs";
import { IContextUser } from "common/interfaces";
import {
	IWarehouseOwnerFormData,
	IWarehouseOwners,
} from "./interfaces";

import "./styles.scss";
import EditWarehouse from "./editWarehouse/EditWarehouse";

const WarehouseOwners: React.FC = () => {
	const [
		shouldDisplaySignupForm,
		setShouldDisplaySignupForm,
	] = useState(false);
	const [warehouseOwners, setWarehouseOwners] = useState<
		IWarehouseOwners[]
	>([]);

	const [selectedOwner, setSelectedOwner] =
		useState<any>(null);

	const user: IContextUser = useContext(UserContext)!;
	const { email } = user;

	const queryClient = useQueryClient();
	const { data, isLoading: isLoadingGetWarehouseOwners } =
		useQuery(QUERY_KEYS.GET_WAREHOUSE_OWNERS, () =>
			getWarehouseOwnersRequest(email)
		);

	const {
		mutate: deleteWarehouseOwnerMutate,
		isLoading: isLoadingDeleteWarehouseOwner,
	} = useMutation(deleteWarehouseOwners, {
		onSuccess: (successData) => {
			toast.success(successData.message);
		},
		onError: (error: any) => {
			toast.error(
				error.response.data.message ??
					"There was an error deleting the warehouse owner."
			);
		},
		onSettled: () => {
			queryClient.invalidateQueries(
				QUERY_KEYS.GET_WAREHOUSE_OWNERS
			);
		},
	});

	const shouldDisplayLoader =
		isLoadingGetWarehouseOwners ||
		isLoadingDeleteWarehouseOwner;
	const shouldDisplayWOTable =
		!isLoadingGetWarehouseOwners &&
		!shouldDisplaySignupForm;

	const onDeleteWarehouseOwner = (
		email: string,
		_id: string
	) => {
		deleteWarehouseOwnerMutate({ email, _id });
	};

	useEffect(() => {
		if (data?.response) {
			setWarehouseOwners(data.response);
		}
	}, [data]);

	useEffect(() => {
		if (user.email !== users.ADMIN_USER) {
			window.location.href = "/";
		}
	}, [user]);

	return (
		<>
			<div className="content warehouse-owners">
				<div className="container-fluid">
					<div className="sidebar">
						<SidebarMenu
							active={"warehouse-owners"}
							sidebarOnly={true}
						/>
					</div>

					{warehouseOwners.length !== 0 &&
						shouldDisplayWOTable && (
							<div className="row">
								<div className="col-sm">
									<div className="table-responsive max-h-85vh">
										<table className="table table-sm">
											<thead className="sticky-header">
												<tr>
													{warehouseOwnersTableHeaders.map(
														(header) => (
															<th
																className="text-center align-center warehouse-table-header"
																key={header.name}>
																{header.name}
															</th>
														)
													)}
												</tr>
											</thead>
											<tbody>
												{warehouseOwners.map(
													(wOwner: IWarehouseOwners) =>
														wOwner.warehouses.map(
															(
																warehouse: IWarehouseOwnerFormData,
																index
															) => (
																<tr
																	key={`${wOwner._id}-${index}`}>
																	<td
																		key={`${wOwner._id}-${
																			index + 2
																		}`}
																		className="text-center align-middle white-space-nowrap">
																		<div className="d-flex align-items-center">
																			<IoIosArrowDropdown
																				style={{
																					fontSize: "20px",
																					cursor: "pointer",
																				}}
																				className="me-2"
																				data-bs-toggle="collapse"
																				data-bs-target={`#collapse-${wOwner._id}-${index}`}
																				aria-expanded="false"
																				aria-controls={`collapse-${wOwner._id}-${index}`}
																			/>
																			<div>
																				<span className="light-gray">
																					Lob Id:{" "}
																				</span>
																				{warehouse.lobId}
																				<br />
																				<span className="light-gray">
																					Warehouse Id:{" "}
																				</span>
																				{
																					warehouse.warehouseId
																				}
																				<br />
																				<span className="light-gray">
																					Unielogics Id:{" "}
																				</span>
																				{
																					warehouse.unielogicsId
																				}
																			</div>
																		</div>
																	</td>
																	<td className="text-center align-middle white-space-nowrap">
																		<span className="light-gray">
																			Name:{" "}
																		</span>
																		{warehouse.name}
																		<br />
																		<span className="light-gray">
																			Phone Number:{" "}
																		</span>
																		{warehouse.phoneNumber}
																		<br />
																		<span className="light-gray">
																			LLC Name:{" "}
																		</span>
																		{warehouse.llcName}
																		<br />
																		<span className="light-gray">
																			Email:{" "}
																		</span>
																		{wOwner.email}
																		<br />
																	</td>
																	<td className="text-center align-middle white-space-nowrap">
																		<span className="light-gray">
																			Business Name:{" "}
																		</span>
																		{warehouse.businessName}
																		<br />
																		<span className="light-gray">
																			Business Phone Number:{" "}
																		</span>
																		{
																			warehouse.businessPhoneNumber
																		}
																		<br />
																		<div
																			id={`collapse-${wOwner._id}-${index}`}
																			className="collapse">
																			<span className="light-gray">
																				Business Customer
																				Service Email:{" "}
																			</span>
																			{
																				warehouse.customerServiceEmailAddress
																			}
																			<br />
																			<span className="light-gray">
																				Business Address:{" "}
																			</span>
																			<ul className="warehouse-business-ul">
																				<li>
																					<span className="light-gray">
																						Address:{" "}
																					</span>
																					{
																						warehouse
																							.businessAddress
																							.address
																					}
																				</li>
																				<li>
																					<span className="light-gray">
																						{" "}
																						City:{" "}
																					</span>
																					{
																						warehouse
																							.businessAddress
																							.city
																					}
																				</li>
																				<li>
																					<span className="light-gray">
																						{" "}
																						State:{" "}
																					</span>
																					{
																						warehouse
																							.businessAddress
																							.state
																					}
																				</li>
																				<li>
																					<span className="light-gray">
																						{" "}
																						Zip Code:{" "}
																					</span>
																					{
																						warehouse
																							.businessAddress
																							.zipCode
																					}
																				</li>
																			</ul>
																		</div>
																		<br />
																	</td>
																	<td className="text-center align-middle white-space-nowrap">
																		<span className="light-gray">
																			Cost Per Labeling:{" "}
																		</span>
																		$
																		{
																			warehouse.costPerItemLabeling
																		}
																		<br />
																	</td>
																	<td className=" text-center align-middle white-space-nowrap">
																		<span className="light-gray">
																			Cost Per Box Closing:{" "}
																		</span>
																		{
																			warehouse.costPerBoxClosing
																		}
																		<br />
																	</td>
																	<td
																		key={`${wOwner._id}-${
																			index + 1
																		}`}
																		className=" text-center align-middle white-space-nowrap">
																		{warehouse.costPerBox.map(
																			(box: any) => (
																				<>
																					<span className="light-gray">
																						Name:
																					</span>{" "}
																					{box.type} <br />
																					<span className="light-gray">
																						Type:
																					</span>{" "}
																					{box.type} <br />
																					<span className="light-gray">
																						Size (WxHxL):
																					</span>
																					{box.size.width}x
																					{box.size.height}x
																					{box.size.length}
																					<div
																						id={`collapse-${wOwner._id}-${index}`}
																						className="collapse">
																						<span className="light-gray">
																							Price:
																						</span>{" "}
																						{box.price}{" "}
																					</div>{" "}
																					<br />
																				</>
																			)
																		)}
																		<br />
																	</td>
																	<td className=" text-center align-middle white-space-nowrap">
																		<span className="light-gray">
																			Answer:{" "}
																		</span>
																		{
																			warehouse.handleShrink
																				.answer
																		}
																		<br />
																		<span className="light-gray">
																			Small:{" "}
																		</span>
																		$
																		{
																			warehouse.handleShrink
																				.small.price
																		}
																		<br />
																		<span className="light-gray">
																			Medium:{" "}
																		</span>
																		$
																		{
																			warehouse.handleShrink
																				.medium.price
																		}
																		<br />
																		<span className="light-gray">
																			Large:{" "}
																		</span>
																		$
																		{
																			warehouse.handleShrink
																				.large.price
																		}
																		<br />
																	</td>

																	<td className=" text-center align-middle white-space-nowrap">
																		<span className="light-gray">
																			Answer:{" "}
																		</span>
																		{
																			warehouse.handleHazmat
																				.answer
																		}
																		<br />
																		{warehouse.handleHazmat
																			.answer === "yes" && (
																			<>
																				<span className="light-gray">
																					Price Per Item:{" "}
																				</span>
																				$
																				{
																					warehouse
																						.handleHazmat
																						.pricePerItem
																				}
																			</>
																		)}
																	</td>
																	<td className=" text-center align-middle white-space-nowrap">
																		<span className="light-gray">
																			Answer:{" "}
																		</span>
																		{
																			warehouse
																				.bubbleWrapping
																				.answer
																		}
																		<br />
																		{warehouse
																			.bubbleWrapping
																			.answer === "yes" && (
																			<>
																				<span className="light-gray">
																					Price Per Item:{" "}
																				</span>
																				$
																				{
																					warehouse
																						.bubbleWrapping
																						.pricePerItem
																				}
																			</>
																		)}
																	</td>
																	<td className=" text-center align-middle white-space-nowrap">
																		<span className="light-gray">
																			Answer:{" "}
																		</span>
																		{
																			warehouse.offerStorage
																				.answer
																		}
																		<br />
																		{warehouse.offerStorage
																			.answer === "yes" && (
																			<>
																				<span className="light-gray">
																					Price Per Cubic
																					Feet:{" "}
																				</span>
																				$
																				{
																					warehouse
																						.offerStorage
																						.pricePerCubicFeet
																				}
																				<br />
																				<span className="light-gray">
																					Price Per Palet:{" "}
																				</span>
																				$
																				{
																					warehouse
																						.offerStorage
																						.pricePerPalet
																				}
																				<br />
																			</>
																		)}
																	</td>
																	<td className="text-center align-middle">
																		<button
																			className="custom-button edits"
																			data-bs-toggle="modal"
																			data-bs-target="#exampleModal">
																			<i
																				className="fa fa-pencil text-success"
																				aria-hidden="true"
																				onClick={() =>
																					setSelectedOwner(
																						wOwner
																					)
																				}></i>
																		</button>
																		<button className="custom-button trash">
																			<i
																				className="fa fa-trash-o text-danger"
																				aria-hidden="true"
																				onClick={() =>
																					onDeleteWarehouseOwner(
																						wOwner.email,
																						warehouse._id!
																					)
																				}></i>
																		</button>
																	</td>
																</tr>
															)
														)
												)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						)}

					{warehouseOwners.length === 0 &&
						!isLoadingGetWarehouseOwners &&
						!shouldDisplaySignupForm && (
							<div className="no-items text-center">
								<h3>No Warehouse Owners!</h3>
								<p>
									You don't have any Warehouse Owners yet.
								</p>
								<p>Start adding Warehouse Owners.</p>
								<button
									type="submit"
									className="btn btn-primary mb-3"
									data-bs-toggle="modal"
									data-bs-target="#addWarehouseModals"
									onClick={() =>
										setShouldDisplaySignupForm(
											!shouldDisplaySignupForm
										)
									}>
									Add Warehouse Owner
								</button>
								<br />
								<span className="no-items-icon-wrapper">
									<i className="fas fa-warehouse"></i>
								</span>
							</div>
						)}
					{shouldDisplayLoader && <FullScreenLoading />}
				</div>
			</div>
			<WarehouseOwnersForm />
			<EditWarehouse wOwner={selectedOwner} />
			<Footer />
		</>
	);
};

export default WarehouseOwners;
