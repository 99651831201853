import "./styles.scss";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { loadStripe } from "@stripe/stripe-js";
import { Link } from "react-router-dom";

import {
  IRedirectToStripe,
  IRegisterEmail,
  IRegisterNamePassword,
  IRegisterResponse,
} from "./interfaces";
import { postCheckoutRequest, postRegisterRequest } from "./api";

import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { getQueryParameterByName, plans, QUERY_KEYS, pageRoutes } from "utils";
import GoogleSignInButton from "components/GoogleSignInButton";
import { STRIPE_KEYS } from "utils/stripe";
import Navbar from "./components/navbar";
import MobileMenu from "./components/mobileMenu";
import Footer from "./components/footer";

// Define Schemas
const emailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const namePasswordSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.{}|<>]/,
      "Password must contain at least one speecial character"
    )
    .test(
      "password-not-email",
      "Password cannot contain parts of the email",
      function (value) {
        const email = this.options.context;
        if (email && value) {
          const emailParts: string[] = email.split("@")[0].split(/[\W_]+/);
          const containsEmail = emailParts.some((part) => value.includes(part));
          return !containsEmail;
        }
        return true;
      }
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Password and Confirm Password do not match"),
  termsAndConditions: Yup.bool()
    .required("You must accept the terms and conditions")
    .oneOf([true], "You must accept the terms and conditions"),
});

const Register = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmVisibility = () => {
    setShowConfirm(!showConfirm);
  };
  const iconProps = (toggleFunction: () => void) => {
    return {
      onClick: toggleFunction,
      className:
        "absolute right-3 top-1/2 transform -translate-y-1/2 w-6 h-6 cursor-pointer text-black bg-white",
    };
  };

  const queryClient = useQueryClient();
  const referral = ""; // To investigate rewardful integration as sometimes it crashes

  // UseForm hooks for each step
  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    formState: { errors: errorsEmail },
  } = useForm<IRegisterEmail>({
    resolver: yupResolver(emailSchema),
  });

  const {
    register: registerNamePassword,
    handleSubmit: handleSubmitNamePassword,
    formState: { errors: errorsNamePassword },
  } = useForm<IRegisterNamePassword>({
    context: email,
    resolver: yupResolver(namePasswordSchema),
  });

  useEffect(() => {
    if (errorsEmail.email) {
      toast.error(errorsEmail.email.message);
    }
  }, [errorsEmail]);

  useEffect(() => {
    (
      Object.keys(errorsNamePassword) as (keyof typeof errorsNamePassword)[]
    ).forEach((key) => {
      toast.error(errorsNamePassword[key]?.message);
    });
  }, [errorsNamePassword]);

  const fetchStripeAndRedirectToCheckout = async ({
    sessionId,
  }: IRedirectToStripe) => {
    const publishableKey = STRIPE_KEYS.STRIPE_PUBLISHABLEKEY;
    if (!publishableKey) {
      throw new Error("Stripe publishable key is not defined");
    }
    console.log(publishableKey);
    const stripe = await loadStripe(publishableKey);
    stripe?.redirectToCheckout({ sessionId });
  };

  // Mutation hooks
  const { mutate: postRegisterMutate, isLoading: isLoadingRegister } =
    useMutation(postRegisterRequest, {
      onSuccess: (data: IRegisterResponse) => {
        toast.success("Registration successful!");
        const customerID = data.user?.customerID;
        const product = getQueryParameterByName("plan") ?? plans.PLAN_37;

        window.location.href = pageRoutes.RESEND_VERIFY_EMAIL;
        postCheckoutMutate({
          customerID,
          product,
        });
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ?? "There was an error signing you in."
        );
      },
    });

  const { mutate: postCheckoutMutate, isLoading: isLoadingCheckout } =
    useMutation(postCheckoutRequest, {
      onSuccess: (data) => {
        if (data.status === "success") {
          const { sessionId } = data;
          fetchStripeAndRedirectToCheckout({ sessionId });
        }
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ?? "There was an error signing you in."
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(QUERY_KEYS.POST_CHECKOUT);
      },
    });

  // Form handlers
  const handlePrevStep = () => {
    setStep(1);
  };

  const handleNextStep = (data: IRegisterEmail) => {
    setEmail(data.email);
    setStep(2);
  };

  const handleRegisterSubmit = async (data: IRegisterNamePassword) => {
    const userDetails = { email, ...data };
    const selectedPlan = getQueryParameterByName("plan");

    if (selectedPlan !== plans.PLAN_37 && selectedPlan !== plans.PLAN_287) {
      toast.error(
        "You have to select a plan before registering. Please go to home page and select the desired plan."
      );
      return;
    }

    postRegisterMutate({
      ...userDetails,
      redirectURL: `https://unielogics.com${pageRoutes.VERIFY_EMAIL}`, // This URL is sent in the email body
      referral,
    });
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Top Navigation */}
      <Navbar toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />

      {/* Mobile Menu */}
      {isMenuOpen && <MobileMenu toggleMenu={toggleMenu} />}

      <div className="flex-grow grid grid-cols-1 md:grid-cols-2">
        {/* Left Section */}
        <div
          className="relative flex flex-col p-8 bg-[#0f0c29] text-white left-section"
          style={{ minHeight: "575px" }}
        >
          <div className="flex flex-col justify-center h-full">
            <div className="flex flex-col gap-2">
              <h1 className="font-bold bg-gradient-to-r from-purple-300 to-white bg-clip-text text-transparent text-[28px] lg:text-[44px]">
                Try Us Now, Try Us Forever!
              </h1>
              <p className="text-[26px] text-purple-200">
                Send your first 20 units for FREE! Plus get unlimited access to
                product research and other tools for 7 days.
              </p>
            </div>
          </div>
        </div>
        {/* Right Section */}
        <div className="relative flex flex-col items-center justify-center p-4 sm:p-8 bg-black text-white">
          <div className="flex-1 flex flex-col items-center justify-center w-full max-w-sm gap-6 lg:mt-[90px] mb-6">
            {/* Login Form */}
            <h2 className="text-2xl font-bold mb-6">Create Your Account</h2>
            {/* Form Fields */}
            {step === 1 && (
              <form
                className="space-y-6 w-full max-w-md"
                onSubmit={handleSubmitEmail(handleNextStep)}
              >
                <div className="form-group">
                  <label htmlFor="email" className="block text-sm font-medium">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Your Email"
                    className="w-full px-3 py-2 rounded-md text-black"
                    {...registerEmail("email", {
                      required: "Email is required",
                    })}
                  />
                  {errorsEmail.email && (
                    <p className="text-red-500 text-sm">
                      {String(errorsEmail.email.message)}
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn w-full mt-3 bg-[#3B82F6] text-white text-lg font-medium px-16 hover:bg-blue-600 transition-colors"
                  >
                    Continue
                  </button>
                  {(isLoadingRegister || isLoadingCheckout) && (
                    <div
                      className="spinner-border register-form-submit-loading"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </div>
              </form>
            )}
            {step === 2 && (
              <form
                className="space-y-6 w-full max-w-md"
                onSubmit={handleSubmitNamePassword(handleRegisterSubmit)}
              >
                <div className="form-group">
                  <label
                    htmlFor="fullName"
                    className="block text-sm font-medium"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    placeholder="Your Full Name"
                    className="w-full px-3 py-2 rounded-md text-black"
                    {...registerNamePassword("fullName")}
                  />
                  {errorsNamePassword.fullName && (
                    <p className="text-red-500 text-sm">
                      {String(errorsNamePassword.fullName.message)}
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium"
                  >
                    Password
                  </label>
                  <div className="flex relative items-center">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      placeholder="Your Password"
                      className="w-full px-3 py-2 rounded-md text-black"
                      {...registerNamePassword("password")}
                    />
                    <div>
                      {showPassword ? (
                        <FaEyeSlash {...iconProps(togglePasswordVisibility)} />
                      ) : (
                        <FaEye {...iconProps(togglePasswordVisibility)} />
                      )}
                    </div>
                  </div>
                  {errorsNamePassword.password && (
                    <p className="text-red-500 text-sm">
                      {String(errorsNamePassword.password.message)}
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <label
                    htmlFor="confirmPassword"
                    className="block text-sm font-medium"
                  >
                    Confirm Password
                  </label>
                  <div className="flex relative items-center">
                    <input
                      type={showConfirm ? "text" : "password"}
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      className="w-full px-3 py-2 rounded-md text-black"
                      {...registerNamePassword("confirmPassword")}
                    />
                    <div>
                      {showConfirm ? (
                        <FaEyeSlash {...iconProps(toggleConfirmVisibility)} />
                      ) : (
                        <FaEye {...iconProps(toggleConfirmVisibility)} />
                      )}
                    </div>
                  </div>
                  {errorsNamePassword.confirmPassword && (
                    <p className="text-red-500 text-sm">
                      {String(errorsNamePassword.confirmPassword.message)}
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <div className="flex relative items-center">
                    <input
                      type="checkbox"
                      id="termsAndConditions"
                      className="mr-2"
                      {...registerNamePassword("termsAndConditions")}
                    />
                    <label htmlFor="termsAndConditions" className="text-sm">
                      I accept the{" "}
                      <Link to={pageRoutes.TermsConditionPage} target="_blank">
                        Terms and Conditions
                      </Link>
                    </label>
                    {errorsNamePassword.termsAndConditions && (
                      <p className="text-red-500 text-sm">
                        {String(errorsNamePassword.termsAndConditions.message)}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group flex flex-row mt-3 gap-3 justify-between">
                  <button
                    type="button"
                    onClick={handlePrevStep}
                    className="btn flex-1 mt-3 bg-[#3B82F6] text-white text-lg font-medium px-8 hover:bg-blue-600 transition-colors"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn flex-grow-[3] mt-3 bg-[#3B82F6] text-white text-lg font-medium px-16 hover:bg-blue-600 transition-colors"
                  >
                    Register
                  </button>
                  {(isLoadingRegister || isLoadingCheckout) && (
                    <div
                      className="spinner-border register-form-submit-loading"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </div>
              </form>
            )}

            {/* Easy Sign In */}
            <div className="mt-6">
              <GoogleSignInButton />
            </div>
            <div className="mt-6 text-sm">
              Already have an account? <Link to={pageRoutes.LOGIN}>Log In</Link>
            </div>
          </div>

          {/* Footer */}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Register;
