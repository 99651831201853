
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddressFields } from "utils/typesUtils/addressTypes";
import { ContactPersonFields } from "utils/typesUtils/contactPersonTypes";
import { VendorInfoFields } from "utils/typesUtils/vendorInfoTypes";
import { IBaseSupplierDetails, ISupplier} from "pages/suppliers/interfaces";
import { OnlineSupplierFields } from 'utils/typesUtils/onlineSupplierType';
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { VENDOR_TITLE } from "../constant";




export const validationScheme = Yup.object().shape({
  name: Yup.string()
    .min(3, "Name must be at least 3 characters long")
    .required("Name cannot be empty"),
  link: Yup.string()
    .transform((value, originalValue) => {
      if (
        value &&
        !value.startsWith("http://") &&
        !value.startsWith("https://")
      ) {
        return `https://${value}`;
      }
      return value;
    })
    .min(6, "Link must be at least 6 characters long")
    .required("Link cannot be empty")
    .url("Invalid URL format"),
  onlineSupplier: Yup.string()
    .required("This field cannot be empty"),
  city: Yup.string()
    .min(3, "City must be at least 3 characters long")
    .required("City cannot be empty"),
  street: Yup.string()
    .min(3, "Street must be at least 3 characters long")
    .required("Street cannot be empty"),
  state: Yup.string()
    .min(2, "State must be at least 2 characters long")
    .required("State cannot be empty"),
  zipCode: Yup.string()
    .min(5, "Zip Code must be at least 5 characters long")
    .max(6, "Zip Code must be max 6 characters long")
    .required("Zip Code cannot be empty"),
  contactName: Yup.string()
    .min(3, "Name must be at least 3 characters long")
    .required("Name cannot be empty"),
  contactEmail: Yup.string()
    .email("Invalid email format") // Built-in email validation
    .required("Email cannot be empty"),
  contactPhoneNumber: Yup.string()
    .matches(
      /^[0-9-]*[0-9]+[0-9-]*$/,
      "Phone number must contain only numeric digits"
    )
    .test(
      "is-at-least-10-digits",
      "Phone number must be at least 10 characters long",
      (value) => {
        const numericValue = value?.replace(/-/g, "");
        return numericValue ? numericValue.length >= 10 : false;
      }
    )
    .required("Phone number cannot be empty"),
  contactExtensionCode: Yup.string()
    .matches(/^[0-9]+$/, "Extension code must contain only numeric digits")
    .min(1, "Extension code must be at least 1 characters long")
    .max(3, "Extension code cannot be longer than 3 digits")
    .required("Extension code cannot be empty"),
});







export const defaultSupplierFormValues: IBaseSupplierDetails = {
  name: "",
  link: "",
  onlineSupplier: "No",
  city: "",
  state: "",
  street: "",
  zipCode: "",
  contactName: "",
  contactEmail: "",
  contactPhoneNumber: "",
  contactExtensionCode: "",
};
export const formOptions = {
  resolver: yupResolver(validationScheme),
  defaultValues: defaultSupplierFormValues,
};





export interface SubFormField {
  label: string;
  id: string;
  placeholder: string;
  fieldName: string;
}
export interface SubFormProps {
  title: string,
  subFormFields: SubFormField[];
  supplierFields: Record<string, string> | undefined,
  register: UseFormRegister<any>; // Replace 'any' with your form type if needed
  errors: any, // Replace 'any' with your form type if needed
  setValue: UseFormSetValue<any>; 
}
export enum SubFormTitles {
  VENDOR_INFO = `${VENDOR_TITLE} info`,
  ONLINE_SUPPLIER = `Online ${VENDOR_TITLE}?`,
  ADDRESS = "Address",
  CONTACT = "Contact",
}
export const subFormConfigs = [
  {
    title: SubFormTitles.VENDOR_INFO,
    fields: VendorInfoFields,
  },
  {
    title: SubFormTitles.CONTACT,
    fields: ContactPersonFields,
  },
  {
    title: SubFormTitles.ADDRESS,
    fields: AddressFields,
  },
  {
    title: SubFormTitles.ONLINE_SUPPLIER,
    fields: OnlineSupplierFields,
  },
];




export const supplierMap = {
  [SubFormTitles.VENDOR_INFO]: ['supplierName', 'supplierLink'],
  [SubFormTitles.CONTACT]: ['contactPerson'],
  [SubFormTitles.ADDRESS]: ['supplierAddress'],
  [SubFormTitles.ONLINE_SUPPLIER]: ['onlineSupplier'],
};