import axios from "axios";
import {
  ICustomers,
} from "../interfaces";
import { apiRoutes } from "utils";

export const getCustomersRequest = async () => {
    const { data } = await axios.get<ICustomers[]>(
      `${apiRoutes.GET_CUSTOMERS}`
    );
    return data;
  };