import { UserContext } from "utils";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

import { QUERY_KEYS } from "utils";
import { getProfileRequest, goToBilling } from "./api";
import {
  Footer,
  ProfileAccountSettings,
  ProfilePasswordSettings,
  SidebarMenu,
} from "components";
import { postUpdateProfileRequest } from "./api";

import "./styles.scss";
import { getSimpleProfile } from "common";
import { IContextUser } from "common/interfaces";
import { IGoToBillingResponse, IUpdateProfileResponse } from "./interfaces";

const Profile = () => {
  const [selectedMenu, setSelectedMenu] = useState("account");
  let user: IContextUser = useContext(UserContext)!;
  const { email, customerID, role } = user;
  const {
    data,
    refetch: getProfileQuery,
    isLoading: isLoadingGetProfile,
  } = useQuery(
    QUERY_KEYS.GET_PROFILE,
    () =>
      role !== "warehouseOwner" || role === "warehouseOwner"
        ? getProfileRequest({ email, customerID })
        : getSimpleProfile(email),
    {
      refetchOnWindowFocus: false,
      enabled: false, // turned off by default, manual refetch is needed so I can call it on cick
      retry: false,
      cacheTime: 10 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    }
  );

  const { mutate: postUpdateProfile, isLoading: isLoadingUpdateProfile } =
    useMutation(postUpdateProfileRequest, {
      onSuccess: (data: IUpdateProfileResponse) => {
        if (data.status === "success") {
          getProfileQuery();
          toast.success("Successfully updated your profile.");
        } else {
          toast.error("There was an error updating your profile.");
        }
        return;
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
            "There was an error updating your profile."
        );
        return;
      },
    });

  const { mutate: goToBillingMutate, isLoading: isLoadingBilling } =
    useMutation(goToBilling, {
      onSuccess: (data: IGoToBillingResponse) => {
        //redirect to Stripe billing page
        window.location.href = data.url;
        return;
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.error.message ??
            "There was an error going to billing page."
        );
        return;
      },
    });

  useEffect(() => {
    getProfileQuery();
  }, [getProfileQuery]);

  const onUpdateSubmitParent = (
    firstName: string,
    lastName: string,
    phoneNumber: string,
    notifications: boolean
  ) => {
    const password = "";
    const email = data?.user.email ?? "";

    postUpdateProfile({
      email,
      firstName,
      lastName,
      phoneNumber,
      notifications,
      password,
    });
  };

  const onPasswordChangeSubmitParent = (password: string) => {
    postUpdateProfile({
      email: data?.user.email,
      firstName: data?.user.firstName,
      lastName: data?.user.lastName,
      phoneNumber: data?.user.phoneNumber,
      notifications: data?.user.notifications,
      password,
    });
  };
  const onUpgradeClick = () => {
    setSelectedMenu("upgrade");
    goToBillingMutate(customerID);
  };

  return (
    <>
      <div className="content">
        <div className="profile container-fluid d-flex flex-column">
          <div className="navigation mb-md-5">
            <SidebarMenu active="profile" sidebarOnly={true} />
          </div>
          <div className="container">
            <div className="row profile-card">
              <div className="col-xl-3 py-5 profile-card-left-side">
                <div className="mb-4 mb-xl-0">
                  <div className="profile-picture-container text-center">
                    <img
                      className="profile-account-img rounded-circle mb-2"
                      src={`https://eu.ui-avatars.com/api/?name=${data?.user.firstName}+${data?.user.lastName}`}
                      alt=""
                    />
                    <p className="mb-4 profile-user-name">
                      {data?.user.firstName} {data?.user.lastName || "-"}{" "}
                    </p>
                  </div>
                  <div className="profile-card-menu text-center">
                    <ul>
                      <li
                        className={`${selectedMenu === "account" && "active"}`}
                        onClick={() => setSelectedMenu("account")}
                      >
                        Account
                      </li>
                      <li
                        className={`${selectedMenu === "password" && "active"}`}
                        onClick={() => setSelectedMenu("password")}
                      >
                        Password
                      </li>
                      <li
                        className={`${selectedMenu === "upgrade" && "active"}`}
                        onClick={() => onUpgradeClick()}
                      >
                        Upgrade
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {isLoadingGetProfile ||
              isLoadingUpdateProfile ||
              isLoadingBilling ? (
                <div className="col-xl-8 text-center align-items-center justify-content-center d-flex">
                  <div
                    className="spinner-border login-form-submit-loading align-self-center"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : data?.user && selectedMenu === "account" ? (
                <ProfileAccountSettings
                  user={data?.user ?? {}}
                  onUpdateSubmitParent={onUpdateSubmitParent}
                />
              ) : data?.user && selectedMenu === "password" ? (
                <ProfilePasswordSettings
                  email={data?.user?.email ?? ""}
                  onPasswordChangeSubmitParent={onPasswordChangeSubmitParent}
                />
              ) : (
                selectedMenu === "upgrade" && (
                  <div className="col-xl-8 py-4 profile-password-card-right-side justify-content-center d-flex align-items-center flex-column">
                    <div className="row gx-3">
                      <div className="col-md-12">
                        <p className="mb-4">Redirecting to billing...</p>
                      </div>
                    </div>
                    <div className="row gx-3">
                      <div className="col-md-12">
                        <p className="mb-4">
                          If there are issues redirecting you to billing, please
                          correct them or contact our support team.
                        </p>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Profile;
