import { useContext, useEffect, useState } from "react";
import {
  Footer,
  FullScreenLoading,
  Modal,
  SidebarMenu,
  SuppliersForm,
} from "components";
import "./styles.scss";
import { QUERY_KEYS, UserContext } from "utils";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteSuppliers,
  getSuppliersRequest,
  postRegisterSupplier,
} from "./api";
import { suppliersTableHeaders } from "utils";
import { IBaseSupplierDetails, IDeleteSupplierRequest, ISupplier } from "./interfaces";
import { toast } from "react-toastify";
import { IContextUser } from "common/interfaces";
import SupplierMap from "./subComponent/SupplierMap";
import SupplierRow from "./subComponent/SupplierRow";
import { useSupplierStore } from "./supplierStore";
import { VENDOR_TITLE } from "./constant";

const Suppliers = () => {
  const user: IContextUser = useContext(UserContext)!;
  const { email } = user;
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const [showSupplierForm, setShowSupplierForm] = useState(false);
  const [showDeleteSupplierModal, setShowDeleteSupplierModal] = useState(false);
  const [shouldDisplayLocationModal, setShouldDisplayLocationModal] =
    useState(false);
  const [supplierIdToDelete, setSupplierIdToDelete] = useState("");
  const { currentSupplier, setCurrentSupplier } = useSupplierStore.getState();
  const [supplierCoordinates, setSupplierCoordinates] = useState({
    lat: 0,
    long: 0,
    name: "",
  });

  const queryClient = useQueryClient();
  const { data, isLoading: isLoadingGetSuppliers } = useQuery(
    QUERY_KEYS.GET_SUPPLIERS,
    () => getSuppliersRequest(email)
  );

  const { mutate: postSupplierMutate, isLoading: isLoadingPostSupplier } =
    useMutation(postRegisterSupplier, {
      onSuccess: (successData) => {
        // console.log(currentSupplier);
        if (currentSupplier) {
          toast.success("Vendor info has been updated successfully")
          setCurrentSupplier(null);
          const supplierDeleteRequest: IDeleteSupplierRequest = {
            email,
            _id: currentSupplier._id,  // Ensure the property name matches the interface
          };

          // Call deleteSuppliers with the request object
          deleteSuppliers(supplierDeleteRequest);
        }
        else {
          toast.success(successData.message);
        }


        setShowSupplierForm(false);
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
          "There was an error saving your supplier."
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(QUERY_KEYS.GET_SUPPLIERS);
      },
    });

  const { mutate: deleteSuppliersMutate, isLoading: isLoadingDeleteSuppliers } =
    useMutation(deleteSuppliers, {
      onSuccess: (successData) => {
        toast.success(successData.message);
        setSuppliers(successData?.response?.suppliers);
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
          "There was an error deleting your supplier."
        );
      },
    });

  useEffect(() => {
    if (data?.response?.suppliers) {
      setSuppliers(data?.response?.suppliers);
    }
  }, [data, currentSupplier]);

  const onSubmitSupplierFormCb = ({ onlineSupplier = "No", ...supplierData }: IBaseSupplierDetails) => {

    // setCurrentSupplier(null);
    postSupplierMutate({ ...supplierData, email, onlineSupplier });
  };


  const onModalConfirmDeleteFolderClick = () => {
    setShowDeleteSupplierModal(false);
    deleteSuppliersMutate({ email, _id: supplierIdToDelete });
  };

  function handleEdit(supplier: ISupplier) {
    // console.log(supplier)
    setShowSupplierForm(true);
    setCurrentSupplier(supplier);
  }



  return (
    <>
      <div className="content">
        <div className="container-fluid suppliers">
          <div className="sidebar">
            <SidebarMenu active="suppliers" sidebarOnly={true} />
          </div>
          {showDeleteSupplierModal && (
            <Modal
              okButtonText="Delete"
              modalTitle="Are you sure you want to delete this supplier?"
              modalTextContent="Please be advised that selecting 'Delete' will result in the permanent removal of the supplier associated with this user. As a consequence, all products linked to this supplier will remain unassociated with any supplier."
              okModalClick={onModalConfirmDeleteFolderClick}
              cancelModalClick={() => setShowDeleteSupplierModal(false)}
            />
          )}
          {shouldDisplayLocationModal && (
            <Modal
              modalDialogStyle={{ maxWidth: "600px" }}
              modalTitle={supplierCoordinates.name}
              cancelModalClick={() => setShouldDisplayLocationModal(false)}
              children={
                <div style={{ height: "500px", width: "100%" }}>
                  <SupplierMap supplierCoordinates={supplierCoordinates} />
                </div>
              }
              cancelButtonText="Close"
            />
          )}
          {(isLoadingGetSuppliers ||
            isLoadingPostSupplier ||
            isLoadingDeleteSuppliers) && <FullScreenLoading />}
          {suppliers.length !== 0 &&
            !isLoadingGetSuppliers &&
            !isLoadingPostSupplier &&
            !isLoadingDeleteSuppliers && (
              <button
                type="submit"
                className="btn btn-primary mb-3"
                onClick={() => setShowSupplierForm(!showSupplierForm)}
              >
                Add {VENDOR_TITLE}
              </button>
            )}
          {showSupplierForm && (
            <SuppliersForm
              submitCb={(options: IBaseSupplierDetails) => onSubmitSupplierFormCb(options)}
              cancelCb={() => setShowSupplierForm(false)}
            // supplierData={currentSupplier}
            />
          )}
          {suppliers.length !== 0 &&
            !isLoadingGetSuppliers &&
            !isLoadingPostSupplier &&
            !isLoadingDeleteSuppliers &&
            !showSupplierForm && (
              <div className="row">
                <div className="col-sm">
                  <div className="table-responsive max-h-78vh">
                    <table className="table table-sm">
                      <thead className="sticky-header">
                        <tr>
                          {suppliersTableHeaders.map((header) => (
                            <th className="text-center" key={header.name}>
                              {header.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {suppliers.map((supplier, index) => (
                          <SupplierRow
                            key={supplier._id}
                            supplier={supplier}
                            index={index}
                            setSupplierCoordinates={setSupplierCoordinates}
                            setShouldDisplayLocationModal={setShouldDisplayLocationModal}
                            setSupplierIdToDelete={setSupplierIdToDelete}
                            setShowDeleteSupplierModal={setShowDeleteSupplierModal}
                            handleEdit={handleEdit}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          {suppliers.length === 0 &&
            !isLoadingGetSuppliers &&
            !showSupplierForm && (
              <div className="no-items text-center">
                <h3>No {VENDOR_TITLE}s!</h3>
                <p>You don't have any {VENDOR_TITLE}s added yet.</p>
                <p>Start adding {VENDOR_TITLE}and link them to your products.</p>
                <button
                  type="submit"
                  className="btn btn-primary mb-3"
                  onClick={() => setShowSupplierForm(!showSupplierForm)}
                >
                  Add {VENDOR_TITLE}
                </button>
                <br />
                <span className="no-items-icon-wrapper">
                  <i className="fas fa-archive"></i>
                  {/* <img src={emptyFolderPng} alt="" /> */}
                </span>
              </div>
            )}
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Suppliers;
