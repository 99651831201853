import { defaultSupplierFormValues, formOptions, subFormConfigs, SubFormTitles, supplierMap } from "./interfaces";
import { useForm } from "react-hook-form";
import "bootstrap/dist/js/bootstrap.bundle";
import "./styles.scss";
import SupplierSubForm from "./subComponent/SupplierSubForm";
import { useEffect } from "react";
import { useSupplierStore } from "../supplierStore";
import { ButtonText, VENDOR_TITLE } from "../constant";
import { IBaseSupplierDetails, ISupplierFormProps } from "../interfaces";

const SuppliersForm = ({ submitCb, cancelCb }: ISupplierFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm(formOptions);
  // const { currentSupplier } = useSupplierStore(state => state.currentSupplier);
  const currentSupplier = useSupplierStore((state) => state.currentSupplier);
  const { setCurrentSupplier } = useSupplierStore.getState();
  const resetForm = () => {
    const { name, link } = getValues();
    reset({ ...defaultSupplierFormValues, name, link });
  };

  const onSubmit = (formOptions: IBaseSupplierDetails) => {
    if (Object.keys(errors).length === 0) {
      submitCb(formOptions);
      
    }
  };
  const handleCancel = () => {
    // console.log("cancel")
    setCurrentSupplier(null);
    cancelCb();
  }
  useEffect(() => {
    // Listen for changes in errors for this subform
    if (Object.keys(errors).length > 0) {
      console.log('Form Errors:', errors);
    }
  }, [errors]);

  const createSupplierFields = (supplierFields: string[], title: string) => {
    let suupplierFields: Record<string, string> = {};
    if (currentSupplier) {
      let values: string[] = []; // Initialize an array to store values
      for (let field of supplierFields) {
        if (currentSupplier[field]) { // Check if the field exists in supplierData
          values.push(currentSupplier[field]); // Push the value from supplierData
        }
      }
      if (values.length > 1) {
        suupplierFields["name"] = values[0];
        suupplierFields["link"] = values[1];
      }
      if (title === SubFormTitles.CONTACT) {
        values.forEach((item) => {
          Object.entries(item).forEach(([key, value]) => {
            const contactKey = `contact${key.charAt(0).toUpperCase()}${key.slice(1)}`; // Prefix "contact" and capitalize the key
            suupplierFields[contactKey] = value; // Set the value as it is
          });
        });
      }
      if (title === SubFormTitles.ADDRESS) {
        values.forEach((item) => {
          Object.entries(item).forEach(([key, value]) => {
            if (key === "lat" || key === "long") return;
            suupplierFields[key] = value; // Set the value as it is
          });
        });
      }
      return suupplierFields;
    }
  };

  return (
    <div>
      <div className="modal edit-editWarehouse" style={{ display: 'block' }} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false">

        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Enter {VENDOR_TITLE} Details
              </h5>
            </div>
            <div className="modal-body">
              <div className="container">
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="nope" noValidate>
                  {subFormConfigs.map((config) => {
                    const subFormTitle = config.title;
                    const supplierFields = supplierMap[subFormTitle]; // Access supplierMap using the title
                    return (
                      <SupplierSubForm
                        key={config.title}
                        title={config.title}
                        subFormFields={config.fields}
                        register={register}  // Pass register to subform
                        errors={errors}
                        setValue={setValue}  // Pass setValue to subform
                        supplierFields={createSupplierFields(supplierFields, config.title)} />
                    );
                  })}
                  <div className="form-group d-flex justify-content-center mb-5">
                    <button type="submit" className="btn btn-success me-3">
                      {currentSupplier ? ButtonText.UPDATE : ButtonText.REGISTER}
                      {" " + VENDOR_TITLE}
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuppliersForm;
