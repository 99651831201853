import React, { useEffect } from 'react';
import { SubFormProps, SubFormTitles } from '../interfaces';
import RadioButtonGroup from './RadioButtonGroup';
import { capitalizeSpace } from 'utils/typesUtils/helper';

const SupplierSubForm: React.FC<SubFormProps> = ({
  title,
  subFormFields,
  register,
  errors,
  setValue,
  supplierFields,
}) => {
  const isOnline = SubFormTitles.ONLINE_SUPPLIER.includes(title);
  // console.log(supplierData!["contactPerson"])
  // console.log(supplierData!["contactPerson"])
  useEffect(() => {
    if (supplierFields) {
      // Set initial values for the form fields
      Object.keys(supplierFields).forEach((key) => {
        setValue(key, supplierFields[key]);
      });
    }
  }, [supplierFields, setValue, errors]);

  return (
    <div>
      <h1 className="text-center title">{title}</h1>
      <div className={"row"}>
        {isOnline ? (
          <RadioButtonGroup register={register} />
        ) : (
          subFormFields.map((field, index) => (
            <div key={index} className={"col-6"}>
              <label htmlFor={field.id} className={"form-check-label"}>
                {capitalizeSpace(field.label)}
                {/* <div>{supplierFields![field.id]}</div> */}
              </label>
              {/* <div>{field.id}</div> */}
              <input
                type="text"
                className="form-control"
                id={field.id}
                // defaultValue={'123'}
                // onChange={(e) => handleChange(e, field.id)}  // Handle input change

                defaultValue={supplierFields && supplierFields[field.id] ? supplierFields[field.id] : ''} // Conditional default value
                placeholder={field.placeholder}
                {...register(field.id, { required: 'This field is required' })}  // Add validation rule for testing
              />
              {errors[field.id] && (
                <span className="text-danger">
                  {errors[field.id] && (
                    <span className="text-danger">
                      {errors[field.id]?.message || 'This field is required'}
                    </span>
                  )}
                  {/* <span></span> */}
                </span>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SupplierSubForm;
