import { create } from 'zustand';
import { ISupplier } from './interfaces';



// Define the Zustand store state and actions
interface SupplierStore {
  currentSupplier: ISupplier | null;
  setCurrentSupplier: (supplier: ISupplier | null) => void;
}

// Create the Zustand store
export const useSupplierStore = create<SupplierStore>((set) => ({
  currentSupplier: null, // Initial state
  setCurrentSupplier: (supplier) => set({ currentSupplier: supplier }),
}));
