import { generateFieldsFromEnum } from "./helper";

export enum AddressEnum {
  STREET = "street",
  CITY = "city",
  STATE = "state",
  ZIP_CODE = "zipCode",
}

export const AddressFields = generateFieldsFromEnum(AddressEnum);
export type AddressDetails = { [key in AddressEnum]: string; };


