import React, { Fragment, useCallback } from "react";
import { IItemInFolder } from "./interfaces";
import dayjs from "dayjs";
import { ellipsis } from "utils";
import AutocompleteDropdown from "components/AutocompleteDropdown";
import Loading from "components/Loading";
import Badge from "./Badge";

interface ProductRowProps {
  item: IItemInFolder;
  suppliers: any[];
  isLoadingGetSuppliers: boolean;
  onGoToDetailsPage: (params: { folderId: string; asin: string }) => void;
  onDeleteItemClick: (params: {
    folderId: string;
    folderItemId: string;
  }) => void;
  onSelectProductsForShipmentPlan: (item: IItemInFolder) => void;
  onAutocompleteSelect: (
    option: any,
    productId: string,
    folderId: string,
    currentItem: IItemInFolder
  ) => void;
}

const ProductRow: React.FC<ProductRowProps> = ({
  item,
  suppliers,
  isLoadingGetSuppliers,
  onGoToDetailsPage,
  onDeleteItemClick,
  onSelectProductsForShipmentPlan,
  onAutocompleteSelect,
}) => {
  const handleGoToDetailsPage = useCallback(() => {
    onGoToDetailsPage({ folderId: item.folderId, asin: item.asin });
  }, [item.folderId, item.asin, onGoToDetailsPage]);

  const handleDeleteItemClick = useCallback(() => {
    onDeleteItemClick({ folderId: item.folderId, folderItemId: item._id });
  }, [item.folderId, item._id, onDeleteItemClick]);

  const handleSelectForShipmentPlan = useCallback(() => {
    onSelectProductsForShipmentPlan(item);
  }, [item, onSelectProductsForShipmentPlan]);

  return (
    <Fragment key={item._id}>
      <tr>
        <td
          className="align-middle text-center white-space-nowrap"
          style={{ width: "1%" }}
          rowSpan={2}
        >
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id={`check-${item._id}`}
            onChange={handleSelectForShipmentPlan}
            checked={item.isChecked || false}
          />
        </td>
      </tr>
      <tr className="hover-row">
        <td
          onClick={handleGoToDetailsPage}
          className="align-middle text-center white-space-nowrap"
          style={{ width: "3%" }}
        >
          {item.date ? dayjs(item.date).format("MM/DD/YY") : ""}
        </td>
        <td
          onClick={handleGoToDetailsPage}
          className="align-middle text-center white-space-nowrap"
          style={{ width: "3%" }}
        >
          <p>{item.asin}</p>
        </td>

        <td
          className="align-middle"
          style={{ width: "20%" }}
          onClick={handleGoToDetailsPage}
        >
          {ellipsis(item.title, 100)}
          <div className="hover-detail">
            <img src={item.imageUrl} alt="product-thumb" />
            <p>{item.title}</p>
        </div>
        </td>
        <td
          className="align-middle text-center white-space-nowrap"
          onClick={handleGoToDetailsPage}
        >
          ${item.price}
        </td>
        <td
          className="align-middle text-center white-space-nowrap"
          onClick={handleGoToDetailsPage}
        >
          {item.isHazmat ? "YES" : "NO"}
        </td>
        <td
          className="align-middle text-center white-space-nowrap"
          onClick={handleGoToDetailsPage}
        >
          <Badge date={item.date} />
        </td>
        <td className="align-middle text-center white-space-no-wrap">
          <a
            href={`https://www.amazon.com/dp/${item.asin}`}
            target="_blank"
            rel="noreferrer"
          >
            <span className="icon-wrapper me-3">
              <i className="fa fa-eye" aria-hidden="true"></i>
            </span>
          </a>
          <span className="icon-wrapper me-3" onClick={handleDeleteItemClick}>
            <i className="fa fa-trash-o text-danger" aria-hidden="true"></i>
          </span>
        </td>
        <td className="align-middle text-center white-space-no-wrap">
          {isLoadingGetSuppliers && <Loading />}
          {!isLoadingGetSuppliers && (
            <div className="d-flex justify-content-center ">
              <AutocompleteDropdown
                isDropdownDisabled={item.isChecked}
                options={suppliers}
                onSelectCb={(option: any) =>
                  onAutocompleteSelect(option, item._id, item.folderId, item)
                }
                defaultOption={{
                  label: item.supplier?.supplierName || "",
                  value: item.supplier?._id || "",
                }}
              />
            </div>
          )}
        </td>
      </tr>
    </Fragment>
  );
};

export default ProductRow;
