import Cookies from "js-cookie";
import { cookiesNames } from "utils/storage";

export const setRememberMeCookie = (remeberMe: boolean, userEmail: string) => {
    if (remeberMe) {
        Cookies.set(cookiesNames.userEmail, userEmail, { expires: 30 });
    }
    else {
        Cookies.remove(cookiesNames.userEmail);
    }
}

export const checkRememberMeCookie = () => {
    const userEmail = Cookies.get(cookiesNames.userEmail);
    return userEmail !== undefined; // returns true if cookie exists, false otherwise
}