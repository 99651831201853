import { MarketplaceNames, MarketplaceImages } from './constants';
import useSurveyStateStore from 'components/SurveyModal/useSurveyStore';

export default function Marketplaces() {
  const { selectedMarketplaces, setSelectedMarketplaces } = useSurveyStateStore();
  
  const handleClick = (name: string) => {
    // If the marketplace is already selected, remove it from the array
    if (selectedMarketplaces.includes(name)) {
      setSelectedMarketplaces(selectedMarketplaces.filter((item) => item !== name));
    } else {
      // Otherwise, add it to the array
      setSelectedMarketplaces([...selectedMarketplaces, name]);
    }
  };

  return (
    <div className="flex justify-center items-center space-x-4 bg-gray-600 h-[500px]">
      {MarketplaceNames.map((name) => (
        <div
          key={name}
          className={`flex flex-col items-center p-4 cursor-pointer ${selectedMarketplaces.includes(name) ? 'bg-blue-500' : ''
            } hover:bg-blue-300`} // Apply background color when selected and hover effect
          onClick={() => handleClick(name)}
        >
          <img
            src={MarketplaceImages[name]}
            alt={name}
            className="w-48 h-48"
          />
          <span className="text-white">{name}</span>
        </div>
      ))}
    </div>
  );
}

