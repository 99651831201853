import React from "react";

export const ResetButton = ({ resetHighlightedStates }: { resetHighlightedStates: () => void }) => {  return (
    <button
      onClick={resetHighlightedStates}
      className="px-4 py-2 bg-blue-500 text-white rounded-lg cursor-pointer z-10 mt-2"
    >
      Reset Selected States
    </button>
  );
};
