import video from "assets/videos/tanks.mp4";
import Logo from "assets/images/logo.svg";
import React, { useEffect, useRef } from "react";

const Thanks: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Error attempting to play video:", error);
      });
    }
  }, []);

  const handleBack = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = "/"; // Fallback to home if there's no history
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-black">
      <main className="flex-grow flex flex-col justify-center items-center px-4 py-8">
        <div className="w-full max-w-3xl mx-auto">
          <video
            ref={videoRef}
            className="w-full aspect-video object-cover rounded-lg shadow-lg mb-8"
            autoPlay
            loop
            muted
            playsInline>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="text-center text-white mt-8">
          <h1 className="text-4xl font-bold mb-4">
            Thank you for choosing us!!🎉
          </h1>
          <p className="mb-8">
            Thank you for your trust. We are committed to providing you with the
            best service.
          </p>
          <button
            onClick={handleBack}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300">
            Back
          </button>
        </div>
      </main>
      <footer className="flex flex-col items-center text-gray-400 gap-4 mt-8 pb-8">
        <img src={Logo} alt="Unielogics Logo" className="w-24" />
        <div className="flex flex-row gap-4">
          <a href="/terms-and-conditions" className="hover:text-gray-300">
            Terms of Use
          </a>
          <span>|</span>
          <a href="/privacy-policy" className="hover:text-gray-300">
            Privacy Policy
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Thanks;
