import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import NewPassword from "assets/images/newPassword.png";
import { apiRoutes, pageRoutes } from "utils";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IResendFormType } from "./interfaces";
import Navbar from "./components/navbar";
import MobileMenu from "./components/mobileMenu";
import Footer from "./components/footer";

// Define Schemas
const emailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const ResendVerifyEmailPage: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IResendFormType>({
    resolver: yupResolver(emailSchema),
  });

  const { mutate, isLoading } = useMutation(async (data: IResendFormType) => {
    const redirectURL = `https://unielogics.com${pageRoutes.VERIFY_EMAIL}`;
    const newData = {
      email: data.email,
      redirectURL: redirectURL,
    };
    const response = await fetch(apiRoutes.RESEND_VERIFY_EMAIL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newData),
    });
    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.error.message);
    }
    return response.json();
  });

  const onSubmit = async (data: IResendFormType) => {
    mutate(data, {
      onSuccess: () => {
        toast.success("A verification link was sent to your email!");
      },
      onError: (error: any) => {
        toast.error(error.message || "Invalid Email");
      },
    });
  };

  useEffect(() => {
    Object.keys(errors).forEach((key) => {
      const errorMessage =
        errors[key as keyof typeof errors]?.message || "Invalid input";
      toast.error(String(errorMessage));
    });
  }, [errors]);

  return (
    <div className="min-h-screen flex flex-col">
      {/* Top Navigation */}
      <Navbar toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />

      {/* Mobile Menu */}
      {isMenuOpen && <MobileMenu toggleMenu={toggleMenu} />}

      <div className="flex-grow grid grid-cols-1 md:grid-cols-2">
        {/* Left Section */}
        <div
          className="relative flex flex-col p-8 bg-[#0f0c29] text-white left-section"
          style={{ minHeight: "575px" }}
        >
          <div className="flex flex-col justify-center h-full">
            <div className="flex flex-col gap-2">
              <div className="flex justify-center">
                <img
                  src={NewPassword}
                  alt="Reset Password"
                  className="w-3/12 sm:w-4/12 md:w-5/12 lg:w-6/12 object-contain"
                />
              </div>
              <h1 className="font-bold bg-gradient-to-r from-purple-300 to-white bg-clip-text text-center text-transparent text-[28px] lg:text-[44px]">
                Please Verify Your Email
              </h1>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="relative flex flex-col items-center justify-center p-4 sm:p-8 bg-black text-white">
          <div className="flex-1 flex flex-col items-center justify-center w-full max-w-sm gap-6 lg:mt-[90px] mb-6">
            {/* Verify Form */}
            <h2 className="text-2xl font-bold mb-6">
              Please Verify Your Email
            </h2>
            <h3 className="text-1xl mb-1">
              Didn't receive an email? Fill out the form below
            </h3>
            <form
              className="space-y-6 w-full max-w-md"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <label htmlFor="email" className="block text-sm font-medium">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="verify@valuedcustomer.com"
                  className="w-full px-3 py-2 rounded-md text-black"
                  {...register("email", { required: "Email is required" })}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email.message}</p>
                )}
              </div>
              <button
                type="submit"
                className="btn w-full mt-3 bg-[#3B82F6] text-white text-lg font-medium px-16 hover:bg-blue-600 transition-colors"
              >
                {isLoading ? "Sending..." : "Send Verification Email"}
              </button>
            </form>
          </div>

          {/* Footer */}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ResendVerifyEmailPage;
