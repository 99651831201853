import axios from "axios";
import { apiRoutes } from "utils";
import { IUpdateShipmentPlanResponse } from "pages/shipmentPlan/interfaces";
import {
  IUpdateShipmentPlanAmazonData,
} from "../interfaces";

export const updateShipmentPlanAmazonDataRequest = async (
  updatedShipmentPlan: IUpdateShipmentPlanAmazonData
) => {
  const { data } = await axios.put<IUpdateShipmentPlanResponse>(
    apiRoutes.UPDATE_SHIPMENT_PLAN_PRODUCT,
    updatedShipmentPlan
  );

  return data;
};

