import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import NewPassword from "assets/images/newPassword.png";
import { apiRoutes, getQueryParameterByName } from "utils";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IResetFormType } from "./interfaces";
import Navbar from "./components/navbar";
import MobileMenu from "./components/mobileMenu";
import Footer from "./components/footer";

// Define Schemas
const newPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.{}|<>]/,
      "Password must contain at least one speecial character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Password and Confirm Password do not match"),
});

const ResetPasswordPage: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmVisibility = () => {
    setShowConfirm(!showConfirm);
  };
  const iconProps = (toggleFunction: () => void) => {
    return {
      onClick: toggleFunction,
      className:
        "absolute right-3 top-1/2 transform -translate-y-1/2 w-6 h-6 cursor-pointer text-black bg-white",
    };
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IResetFormType>({
    resolver: yupResolver(newPasswordSchema),
  });

  const { mutate, isLoading } = useMutation(async (data: IResetFormType) => {
    const token = getQueryParameterByName("token");
    const newData = {
      token: token,
      password: data.password,
    };
    const response = await fetch(apiRoutes.RESET_PASSWORD, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newData),
    });
    if (!response.ok) {
      throw new Error("Reset password failed");
    }
    return response.json();
  });

  const onSubmit = async (data: IResetFormType) => {
    mutate(data, {
      onSuccess: () => {
        toast.success("Password successfully reset!");
        // Redirect to login page after successful password reset
        window.location.href = "/login";
      },
      onError: (error: any) => {
        toast.error(error.message || "Password reset failed");
      },
    });
  };

  useEffect(() => {
    Object.keys(errors).forEach((key) => {
      const errorMessage =
        errors[key as keyof typeof errors]?.message || "Invalid input";
      toast.error(String(errorMessage));
    });
  }, [errors]);

  return (
    <div className="min-h-screen flex flex-col">
      {/* Top Navigation */}
      <Navbar toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />

      {/* Mobile Menu */}
      {isMenuOpen && <MobileMenu toggleMenu={toggleMenu} />}

      <div className="flex-grow grid grid-cols-1 md:grid-cols-2">
        {/* Left Section */}
        <div
          className="relative flex flex-col p-8 bg-[#0f0c29] text-white left-section"
          style={{ minHeight: "575px" }}
        >
          <div className="flex flex-col justify-center h-full">
            <div className="flex flex-col gap-2">
              <div className="flex justify-center">
                <img
                  src={NewPassword}
                  alt="Reset Password"
                  className="w-3/12 sm:w-4/12 md:w-5/12 lg:w-6/12 object-contain"
                />
              </div>
              <h1 className="font-bold bg-gradient-to-r from-purple-300 to-white bg-clip-text text-center text-transparent text-[28px] lg:text-[44px]">
                Password Reset
              </h1>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="relative flex flex-col items-center justify-center p-4 sm:p-8 bg-black text-white">
          <div className="flex-1 flex flex-col items-center justify-center w-full max-w-sm gap-6 lg:mt-[90px] mb-6">
            {/* Login Form */}
            <h2 className="text-2xl font-bold mb-6">Reset Your Password</h2>
            <form
              className="space-y-6 w-full max-w-md"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <label htmlFor="password" className="block text-sm font-medium">
                  Password
                </label>
                <div className="flex relative items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Your Password"
                    className="w-full px-3 py-2 rounded-md text-black"
                    {...register("password", {
                      required: "Password is required",
                    })}
                  />
                  <div>
                    {showPassword ? (
                      <FaEyeSlash {...iconProps(togglePasswordVisibility)} />
                    ) : (
                      <FaEye {...iconProps(togglePasswordVisibility)} />
                    )}
                  </div>
                </div>
                {errors.password && (
                  <p className="text-red-500 text-sm">
                    {String(errors.password.message)}
                  </p>
                )}
              </div>
              <div className="form-group">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium"
                >
                  Confirm Password
                </label>
                <div className="flex relative items-center">
                  <input
                    type={showConfirm ? "text" : "password"}
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    className="w-full px-3 py-2 rounded-md text-black"
                    {...register("confirmPassword", {
                      required: "Full Name is required",
                    })}
                  />
                  <div>
                    {showConfirm ? (
                      <FaEyeSlash {...iconProps(toggleConfirmVisibility)} />
                    ) : (
                      <FaEye {...iconProps(toggleConfirmVisibility)} />
                    )}
                  </div>
                </div>
                {errors.confirmPassword && (
                  <p className="text-red-500 text-sm">
                    {String(errors.confirmPassword.message)}
                  </p>
                )}
              </div>
              <button
                type="submit"
                className="btn w-full mt-3 bg-[#3B82F6] text-white text-lg font-medium px-16 hover:bg-blue-600 transition-colors"
              >
                {isLoading ? "Verifying..." : "Reset Password"}
              </button>
            </form>
          </div>

          {/* Footer */}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
