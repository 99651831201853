import "./App.css";

import { Navigate, Route, Routes } from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { ReactQueryDevtools } from "react-query/devtools";
import Cookies from "js-cookie";
import { useState } from "react";
import {
  Custom404,
  Thanks,
  HomePage,
  Team,
  Faq,
  PrivacyPolicy,
  TermsAndConditions,
  Products,
  Profile,
  Login,
  ResendVerifyEmail,
  VerifyEmail,
  ForgotPassword,
  ResetPassword,
  Register,
  Charts,
  AdminView,
  AdminBlacklist,
  AdminFeedbackUrl,
  ProfitableProducts,
  ProfitableProductsDetails,
  SavedSearches,
  ShipmentPlan,
  ShipmentPlanItem,
  CustomerView,
  TechSupport,
  LoadingScreen,
  Teams,
} from "pages";
import {
  cookiesNames,
  localStorageNames,
  pageRoutes,
  UserContext,
} from "utils";
import { configureAxiosInterceptors, users } from "utils";
import {
  CheckoutErrorPage,
  PrivateRoutes,
  ThankYouPage,
  ShipmentPlanThankYouPage,
  ShipmentPlanErrorPage,
} from "components";

import "assets/styles/global.scss";
import Suppliers from "pages/suppliers";
import WarehouseOwners from "pages/adminWarehouseOwners";
import WarehousesMap from "pages/warehousesMap";
import InfoPlus from "pages/InfoPlus";
import InfoplusPageContainer from "components/Infoplus/InfoplusPageContainer";
import InfoPlusDetails from "components/Infoplus/InfoPlusDetails";
import { GOOGLE_CLIENT_ID } from "utils/oauth";
import SurveyModal from "components/SurveyModal";

import { ShipmentPlanDateSelectionContext } from "utils/context";
import Home from "pages/landingPage/home";

function App() {
  const queryClient = new QueryClient();

  configureAxiosInterceptors();

  let user: any = localStorage.getItem(
    localStorageNames.userObj
  );
  user = JSON.parse(user) ?? "";

  const {
    // MAIN,
    LANDINGPAGE,
    CustomPage404,
    ThanksPage,
    OurTeam,
    FAQPage,
    PrivacyPolicyPage,
    TermsConditionPage,
    LOGIN,
    RESEND_VERIFY_EMAIL,
    VERIFY_EMAIL,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    REGISTER,
    CHARTS,
    THANK_YOU,
    CHECKOUT_ERROR,
    PROFILE,
    BUCKETS,
    ADMIN,
    FEEDBACK_URL,
    ADMIN_BLACKLIST,
    PROFITABLE_PRODUCTS,
    PROFITABLE_PRODUCTS_DETAILS,
    SAVED_SEARCHES,
    WAREHOUSE_OWNERS,
    SHIPMENT_PLANS,
    SHIPMENT_PLAN_ID,
    WAREHOUSES_MAP_PLAN_ID,
    SHIPMENT_PLAN_PAYMENT_THANK_YOU,
    SUPPLIERS,
    SHIPMENT_PLAN_PAYMENT_ERROR,
    INFO_PLUS,
    INFO_PLUS_DETAILS_ID,
    TECH_SUPPORT,
    SHIPMENT_PLAN_LOADING,
    CUSTOMER_VIEW,
    TEAMS,
  } = pageRoutes;

  if (user) {
    user = {
      ...user,
      permissions:
        user.email === users.ADMIN_USER
          ? ["blacklist-view", "blacklist-add"]
          : null,
    };
  }
  if (!user) {
    Cookies.remove(cookiesNames.token);
    localStorage.removeItem(localStorageNames.surveyStep1);
    localStorage.removeItem(localStorageNames.userObj);
  }

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        <UserContext.Provider value={user}>
          <Routes>
            {/* Redirect from / to /home */}
            <Route path="*" element={<Custom404 />} />
            <Route path="/" element={<Navigate to="/home" replace />} />

            {/* <Route path={MAIN} element={<SurveyMap />} /> */}

            {/* <Route path={MAIN} element={<HomePage />}></Route> */}

            <Route path={LANDINGPAGE} element={<Home />}></Route>

            <Route path={OurTeam} element={<Team />} />

            <Route path={CustomPage404} element={<Custom404 />} />

            <Route path={ThanksPage} element={<Thanks />} />

            <Route path={FAQPage} element={<Faq />} />

            <Route path={PrivacyPolicyPage} element={<PrivacyPolicy />} />

            <Route path={TermsConditionPage} element={<TermsAndConditions />} />

            <Route path={LOGIN} element={<Login />} />

            <Route path={RESEND_VERIFY_EMAIL} element={<ResendVerifyEmail />} />

            <Route path={VERIFY_EMAIL} element={<VerifyEmail />} />

            <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />

            <Route path={RESET_PASSWORD} element={<ResetPassword />} />

            <Route path={REGISTER} element={<Register />} />

            <Route path={CHARTS} element={<Charts />} />

            <Route path={THANK_YOU} element={<ThankYouPage />} />

            {/* ADD PRIVATE ROUTES HERE */}
            <Route element={<PrivateRoutes />}>
              <Route element={<SurveyModal />}>
                <Route path={PROFILE} element={<Profile />} />

                <Route path={BUCKETS} element={<Products />} />

                <Route path={ADMIN} element={<AdminView />} />

                <Route path={FEEDBACK_URL} element={<AdminFeedbackUrl />} />

                <Route path={ADMIN_BLACKLIST} element={<AdminBlacklist />} />

                <Route
                  path={PROFITABLE_PRODUCTS}
                  element={<ProfitableProducts />}
                />
                {/* <Route path={ADD_PRODUCTS} element={<AddProducts />} /> */}

                <Route
                  path={PROFITABLE_PRODUCTS_DETAILS}
                  element={<ProfitableProductsDetails />}
                />

                <Route
                  path={PROFITABLE_PRODUCTS_DETAILS}
                  element={<ProfitableProductsDetails />}
                />
                <Route path={TEAMS} element={<Teams />} />

                <Route path={SAVED_SEARCHES} element={<SavedSearches />} />

                <Route path={WAREHOUSE_OWNERS} element={<WarehouseOwners />} />

                <Route path={SHIPMENT_PLANS} element={<ShipmentPlan />} />

                <Route path={SHIPMENT_PLAN_ID} element={<ShipmentPlanItem />} />

                <Route
                  path={WAREHOUSES_MAP_PLAN_ID}
                  element={<WarehousesMap />}
                />

                <Route
                  path={SHIPMENT_PLAN_PAYMENT_THANK_YOU}
                  element={<ShipmentPlanThankYouPage />}
                />

                <Route
                  path={SHIPMENT_PLAN_LOADING}
                  element={<LoadingScreen />}
                />

                <Route
                  path={SHIPMENT_PLAN_PAYMENT_ERROR}
                  element={<ShipmentPlanErrorPage />}
                />

                <Route path={SUPPLIERS} element={<Suppliers />} />

                <Route path={CUSTOMER_VIEW} element={<CustomerView />} />

                <Route path={TECH_SUPPORT} element={<TechSupport />} />
              </Route>

              <Route path={INFO_PLUS} element={<InfoPlus />}>
                <Route index element={<InfoplusPageContainer />} />
                <Route
                  path={INFO_PLUS_DETAILS_ID}
                  element={<InfoPlusDetails />}
                />
              </Route>
            </Route>
          </Routes>
        </UserContext.Provider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
