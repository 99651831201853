import { FileTypes } from "pages/shipmentPlan/interfaces";
import { IFbaAndOtherFilesUpload } from "./interfaces";

const FbaAndOtherFilesUpload = ({
  handleOnFileSelect,
  handleOnDeleteFile,
  shouldDisplayFbaTitle,
  fbaFiles,
  shouldDisplaySkaTitle,
  otherFiles,
  activeComponent,
}: IFbaAndOtherFilesUpload) => {
  return (
    <>
      <div className="d-flex flex-column sp-upload-container">
        <div>
          <label className="form-label secondary-title">FBA labels files</label>
          <label
            htmlFor="fba-file-upload"
            className="btn btn-primary btn-generic custom-file-upload fba-upload-btn"
          >
            <i className="fa fa-upload" aria-hidden="true"></i>
          </label>
          <input
            id="fba-file-upload"
            type="file"
            accept="application/pdf"
            onChange={(e) => handleOnFileSelect(e, FileTypes.FBA)}
            multiple
          />
        </div>

        {shouldDisplayFbaTitle &&
          fbaFiles.map((file: any) => (
            <div className="d-flex" key={file.filename}>
              <div className="check" />

              <p className="uploaded-file-name">
                {file.filename.replace(/^(?:[^_]*_){1}/g, "")}
              </p>

              <span className="icon-wrapper ">
                <a
                  href={`https://asinmice-api-a3b05ab58ba9.herokuapp.com/uploads/${file.filename}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-eye" aria-hidden="true"></i>
                </a>
              </span>

              <span className="icon-wrapper">
                <i
                  className="fa fa-trash-o text-danger trash-icon"
                  aria-hidden="true"
                  onClick={() => handleOnDeleteFile(file, FileTypes.FBA)}
                ></i>
              </span>
            </div>
          ))}
      </div>

      <div className="d-flex flex-column mx-3 sp-upload-container">
        <div>
          <label className="form-label me-3 mb-0 secondary-title">Other files</label>
          <label
            htmlFor="sku-file-upload"
            className="btn btn-primary btn-generic custom-file-upload sku-upload-btn"
          >
            <i className="fa fa-upload" aria-hidden="true"></i>
          </label>
          <input
            id="sku-file-upload"
            type="file"
            accept="application/pdf"
            onChange={(e) => handleOnFileSelect(e, FileTypes.OTHER)}
            multiple
          />
        </div>

        {shouldDisplaySkaTitle &&
          otherFiles.map((file: any) => (
            <div className="d-flex mt-3" key={file.filename}>
              <div className="check ms-2 me-3" />

              <p className="uploaded-file-name">
                {file.filename.replace(/^(?:[^_]*_){1}/g, "")}
              </p>

              <span className="icon-wrapper ms-3">
                <a
                  href={`https://asinmice-api-a3b05ab58ba9.herokuapp.com/uploads/${file.filename}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-eye" aria-hidden="true"></i>
                </a>
              </span>

              <span className="icon-wrapper ms-3">
                <i
                  className="fa fa-trash-o text-danger trash-icon"
                  aria-hidden="true"
                  onClick={() => handleOnDeleteFile(file, FileTypes.OTHER)}
                ></i>
              </span>
            </div>
          ))}
      </div>
    </>
  );
};

export default FbaAndOtherFilesUpload;
