// import { AddressEnum } from "./addressTypes";
// import { ContactPersonEnum } from "./contactPersonTypes";
export function capitalizeSpace(field: string) {
  return field
    .replace(/([a-z])([A-Z])/g, '$1 $2')  // Add space between lowercase and uppercase letters
    .replace(/^./, (match) => match.toUpperCase());
}

export function generateFieldsFromEnum<E extends Record<string, string>>(enumObject: E) {
  const fields = Object.values(enumObject).map((field) => {
    const placeholder = capitalizeSpace(field)
    return {
      label: field, // Capitalizing the label
      id: field,
      placeholder: `Enter ${placeholder}`,
      fieldName: field,
    };
  });
  return fields;
}

export function generateFieldsFromAllEnums(enums: Record<string, any>) {
  return Object.fromEntries(
    Object.keys(enums).map(key => [
      key, generateFieldsFromEnum(enums[key])
    ])
  );
}

// export const CombinedEnum = {
//   AddressEnum : AddressEnum,
//   ContactPersonEnum : ContactPersonEnum,
// };
// export const all = generateFieldsFromAllEnums(CombinedEnum)














