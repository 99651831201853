import { createContext } from "react";

export const UserContext = createContext(null);

type ShipmentPlanDateSelectionContextType = {
  shipmentPlanDateSelectionFlag: boolean;
  setShipmentPlanDateSelectionFlag: (flag: boolean) => void;
};

export const ShipmentPlanDateSelectionContext = createContext<
  Partial<ShipmentPlanDateSelectionContextType>
>({
  shipmentPlanDateSelectionFlag: false,
  setShipmentPlanDateSelectionFlag: () => {},
});