import { pageRoutes } from "utils";

const {
    PROFITABLE_PRODUCTS,
    SAVED_SEARCHES,
    CUSTOMER_VIEW,
    
} = pageRoutes;
export const sidebarMenuItems = [
    {
        to: PROFITABLE_PRODUCTS,
        icon: 'fa-dollar',
        label: 'Profitable',
    },
    {
        to: SAVED_SEARCHES,
        icon: 'fa-floppy-o',
        label: 'Saved Searches',
    },
    {
        to: pageRoutes.SHIPMENT_PLANS,
        icon: 'fa-truck',
        label: 'Logistics',
    },
    {
        to: CUSTOMER_VIEW,
        icon: 'fa-address-card',
        label: 'Customers',
    },
    {
        to: pageRoutes.SUPPLIERS,
        icon: 'fa-archive',
        label: 'Suppliers',
    },
    // {
    //     to: pageRoutes.TEAMS,
    //     icon: 'fa-users', 
    //     label: 'Teams',
    // }
];