import React from 'react'

export default function Team() {
  console.log("team")
  return (
    <div>
      Team
      </div>
  )
}
