import { postCheckoutRequest } from "pages/register/api";
import "./subscription.scss";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { getQueryParameterByName, plans, QUERY_KEYS } from "utils";
import { IRedirectToStripe } from "pages/register/interfaces";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_KEYS } from "utils/stripe";

const Subscription = (): JSX.Element => {
	const queryClient = useQueryClient();

	var customer = localStorage.getItem("asinmice-obj");
	let customerId = ''

// Parse the JSON string into an object
if (customer) {
    var customerObj = JSON.parse(customer);
    customerId = customerObj.customerID; // Access the customerID property
    console.log(customerId); // Output the customerID
} else {
    console.log("No data found in localStorage for 'asinmice-obj'");
}

  const { mutate: postCheckoutMutate, isLoading: isLoadingCheckout } = useMutation(postCheckoutRequest, {
    onSuccess: (data) => {
      if (data.status === "success") {
        fetchStripeAndRedirectToCheckout({ sessionId: data.sessionId });
      }
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ?? "There was an error during checkout."
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(QUERY_KEYS.POST_CHECKOUT);
    },
  });

  const publishableKey = STRIPE_KEYS.STRIPE_PUBLISHABLEKEY;
  
		if (!publishableKey) {
			throw new Error(
				"Stripe publishable key is not defined"
			);
		}
  
  // Stripe redirection
  const fetchStripeAndRedirectToCheckout = async ({ sessionId }: IRedirectToStripe) => {
    const stripe = await loadStripe(publishableKey);
    stripe?.redirectToCheckout({ sessionId });
  };
  
  // Trigger the checkout on button click
  const handleMonthlyCheckout = () => {
    const customerID = customerId; 
    const product = getQueryParameterByName("plan") ?? plans.PLAN_MONTHLY;
    postCheckoutMutate({ customerID, product });
  };

  const handleYearlyCheckout = () => {
    const customerID = customerId; 
    const product = getQueryParameterByName("plan") ?? plans.PLAN_YEARLY;
    postCheckoutMutate({ customerID, product });
  };

	return (
		<>
			<section className="pricing-table">
				<div className="card">
					<h6 className="type">Monthly</h6>
					<div className="price">
						<span>$</span>67
					</div>
					<h5 className="plan">plan</h5>
					<ul className="details text-center">
						<li>Boost your Ecommerce Business</li>
						<li>24/7 support</li>
					</ul>
					<div className="buy-button">
						<h3 className="btn" onClick={handleMonthlyCheckout}>subscribe</h3>
					</div>
				</div>

				<div className="card">
					<h6 className="type">Yearly</h6>
					<div className="price">
						<span>$</span>647
					</div>
					<h5 className="plan text-center">plan</h5>
					<ul className="details text-center">
						<li>Boost your Ecommerce Business</li>
						<li>24/7 support</li>
					</ul>
					<div className="buy-button">
						<h3 className="btn" onClick={handleYearlyCheckout}>subscribe</h3>
					</div>
				</div>
			</section>
		</>
	);
};

export default Subscription;
