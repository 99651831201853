
import "./styles.scss";
import Logo from "assets/images/logo.svg";
import LogoBig from "assets/images/LogoPrincipal.png";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const titleDescriptionPairs = [
  {
    title: "Help Me Analyze Products",
    description:
      "Seamlessly use our Chrome extension to analyze potential products for profits, risk, and demand.",
  },
  {
    title: "I Need Affordable 3PL Services",
    description:
      "Scale your ecommerce with logistics centers nationwide, to help you move your products for cheaper and faster. Send inventory in a few clicks.",
  },
  {
    title: "Can I Try It?",
    description:
      "Access 3PL facilities nationwide from our dashboard without MOQ and contracts. Send inventory in a few clicks and always get high volume discounts.",
  },
];

const HomePage: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [typedText, setTypedText] = useState<string>("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  useEffect(() => {
    let typingTimeout: NodeJS.Timeout;
    let charIndex = 0;

    const currentPair = titleDescriptionPairs[currentIndex];

    if (!currentPair || !currentPair.description) return;

    const typeEffect = () => {
      if (charIndex < currentPair.description.length) {
        setTypedText((prev) => currentPair.description.slice(0, charIndex + 1));
        charIndex++;
        typingTimeout = setTimeout(typeEffect, 40);
      } else {
        // Delay before moving to the next text
        typingTimeout = setTimeout(() => {
          setTypedText("");
          setCurrentIndex(
            (prevIndex) => (prevIndex + 1) % titleDescriptionPairs.length
          );
        }, 2000);
      }
    };

    typeEffect();
  
    // Cleanup timeout on unmount or re-run
    // return () => clearTimeout(typingTimeout);
  }, [currentIndex]);

  return (
    <div className="min-h-screen flex flex-col">
      {/* Top Navigation */}
      <nav
        role="navigation"
        aria-label="Main Navigation"
        className="absolute top-0 left-0 right-0 p-4 z-10 flex items-center justify-between">
        {/* Logo */}
        <picture>
          <source srcSet={LogoBig} media="(min-width: 768px)" />
          <img src={Logo} alt="Unielogics Logo" className="logo-img" />
          <style>{`
 
              .logo-img {
 
                width: 12rem; /* Default size for larger screens */
 
              }
 
              @media (max-width: 768px) {
 
                .logo-img {
 
                  width: 12rem; /* Change size when LogoSmall is visible */
 
                }
 
              }
            `}</style>
        </picture>

        {/* Desktop Menu */}
        <ul className="hidden lg:flex space-x-6">
          <li>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSflYitbApQARcFWUZqslwjzQYlxcClc0lwK7QmALH6W_Ke5iw/viewform"
              className="text-purple-300 hover:text-gray-200">
              Warehouse Owners
            </a>
          </li>
          <li className="text-purple-500">/</li>

          <li>
            <Link to="/team" className="text-purple-300 hover:text-gray-200">
              Our Team
            </Link>
          </li>
          <li className="text-purple-500">/</li>
          <li>
            <Link to="/faq" className="text-purple-300 hover:text-gray-200">
              FAQ
            </Link>
          </li>
        </ul>

        {/* Burger Menu */}
        <button
          className="lg:hidden text-purple-300 hover:text-gray-200"
          onClick={toggleMenu}
          aria-expanded={isMenuOpen}
          aria-label="Toggle menu">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </nav>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex flex-col items-center justify-center">
          <button
            className="absolute top-4 right-4 text-purple-300 hover:text-gray-200"
            onClick={toggleMenu}
            aria-label="Close menu">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <ul className="flex flex-col items-center space-y-4">
            <li>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSflYitbApQARcFWUZqslwjzQYlxcClc0lwK7QmALH6W_Ke5iw/viewform"
                className="text-purple-300 hover:text-gray-200 text-xl">
                Warehouse Owners
              </a>
            </li>
            <li>
              <Link
                to="/team"
                className="text-purple-300 hover:text-gray-200 text-xl">
                Our Team
              </Link>
            </li>
            <li>
              <Link
                to="/faq"
                className="text-purple-300 hover:text-gray-200 text-xl">
                FAQ
              </Link>
            </li>
          </ul>
          <img src={LogoBig} alt="Unielogics Logo" className="w-52 h-22 mt-8" />
        </div>
      )}

      <div className="flex-grow grid grid-cols-1 md:grid-cols-2">
        {/* Left Section */}
        <div
          className="relative flex flex-col p-8 bg-[#0f0c29] text-white left-section"
          style={{ minHeight: "575px" }}>
          <div className="flex-col justify-center h-full header-title">
            <div className="flex-col gap-2">
              <h1 className="font-bold bg-gradient-to-r from-purple-300 to-white bg-clip-text text-transparent text-[28px] lg:text-[44px]">
                {titleDescriptionPairs[currentIndex]?.title || ""}
              </h1>
              <p className="text-[26px] text-purple-200">{typedText}</p>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="relative flex flex-col items-center justify-center p-4 sm:p-8 bg-black text-white">
          <div className="flex-1 flex flex-col items-center justify-center w-full max-w-sm gap-6 lg:mt-[190px] mb-6">
            <h2 className="text-2xl font-bold text-center">Get Started</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full max-w-lg">
              <a href="/login" className="inline-block">
                <button className="bg-[#3B82F6] text-white text-xl font-medium px-8 py-6 rounded-full hover:bg-blue-600 transition-all w-full">
                  Log In
                </button>
              </a>
              <a href="/register?plan=plan37" className="inline-block">
                <button className="bg-[#3B82F6] text-white text-xl font-medium px-8 py-6 rounded-full hover:bg-blue-600 transition-all w-full">
                  Sign Up
                </button>
              </a>
            </div>
            <div className="text-center space-y-6 mt-6">
              <a
                href="/login"
                className="text-xl text-white hover:text-gray-300">
                Send Your First Shipment For FREE
              </a>
            </div>
          </div>

          {/* Footer */}
          <footer className="flex flex-col items-center text-gray-400 gap-4 mt-8">
            <img src={Logo} alt="Unielogics Logo" className="w-24" />
            <div className="flex flex-row gap-4">
              <a href="/terms-and-conditions" className="hover:text-gray-300">
                Terms of Use
              </a>
              <span>|</span>
              <a href="/privacy-policy" className="hover:text-gray-300">
                Privacy Policy
              </a>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
