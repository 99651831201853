export const routesToTitles:Record<string, string> = {
    '/profitable-products': 'Profitable Products',
    '/saved-searches': 'Saved Searches',
    '/shipment-plans': 'Shipment Plans',
    '/customer-view': 'Customers',
    '/suppliers': 'Vendors',
    '/admin/warehouse-owners': 'Warehouses',
    '/tech-support': 'Support',
    '/warehouses-map': 'Warehouses Map',
    '/shipment-plan-loading': 'Loading Plan...',
    '/w-payment-thank-you': 'Order Confirmation',
}