import { IWarehouseDetails } from "./interfaces";

const WarehouseDetails = ({
  warehouseOwner,
  displayType,
}: IWarehouseDetails) => {
  return (
    // check if displayType is set to 'order' and display the order details
    displayType === "order" ? (
      <div className="p-2">
        <h2 className="secondary-title">Warehouse Information</h2>
        <p>
          <span className="secondary-title">Name:</span>{" "}
          {warehouseOwner?.businessName}
          <span className="secondary-title ms-2">Street:</span>{" "}
          {warehouseOwner?.businessAddress.address}
        </p>

        <p>
          <span className="secondary-title">City:</span>{" "}
          {warehouseOwner?.businessAddress.city}
          <span className="secondary-title ms-2">State:</span>{" "}
          {warehouseOwner?.businessAddress.state}
          <span className="secondary-title ms-2">Zip Code:</span>{" "}
          {warehouseOwner?.businessAddress.zipCode}
        </p>
      </div>
    ) : (
      // otherwise display the warehouse details
      <div className="p-2">
        <h2 className="secondary-title">Warehouse Information</h2>
        <p>
          <span className="secondary-title">Name:</span>{" "}
          {warehouseOwner?.businessName}
        </p>
        <p>
          <span className="secondary-title">Street:</span>{" "}
          {warehouseOwner?.businessAddress.address}
        </p>
        <p>
          <span className="secondary-title">City:</span>{" "}
          {warehouseOwner?.businessAddress.city}
        </p>
        <p>
          <span className="secondary-title">State:</span>{" "}
          {warehouseOwner?.businessAddress.state}
        </p>
        <p>
          <span className="secondary-title">Zip Code:</span>{" "}
          {warehouseOwner?.businessAddress.zipCode}
        </p>
      </div>
    )
  );
};

export default WarehouseDetails;
