import { pageRoutes } from "utils/routes";
import { cookiesNames, localStorageNames } from "utils/storage";
import { ILoginResponse } from "./interface";
import { SurveyStatusEnum } from "./enum";
import Cookies from "js-cookie";

export const onLoginSuccess = (user: ILoginResponse) => {
  Cookies.set(cookiesNames.token, user.token, { expires: 1 });
  if (user.survey?.targetedStates &&  user.survey?.targetedStates.length > 0 
    && user.survey?.targetedMarketplaces && user.survey?.targetedMarketplaces.length > 0) {
    user.survey = {
      targetedStates: user.survey.targetedStates,
      targetedMarketplaces: user.survey.targetedMarketplaces,
      SurveyStatus: SurveyStatusEnum.COMPLETED
    };
  } else {
    user.survey = {
      targetedStates: [],
      targetedMarketplaces: [],
      SurveyStatus: SurveyStatusEnum.NOT_STARTED,
    }
  }
  localStorage.setItem(localStorageNames.userObj, JSON.stringify(user));
  setTimeout(() => {
    window.location.href = pageRoutes.PROFITABLE_PRODUCTS;
  }, 500);

};


