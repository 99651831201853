import "./About.scss";
import Unilegocs from "../../assets/images/updatedworkflow.png";
import Ebay from "../../assets/images/icons/Ebay2.png";
import Amazon from "../../assets/images/icons/Amazon3.png";
import Walmart from "../../assets/images/icons/Walmart2.png";
import Shopify from "../../assets/images/icons/Shopify2.png";
import Products from "../../assets/images/icons/products2.png";
import Price from "../../assets/images/icons/price2.png";
import Shipping from "../../assets/images/icons/shipping2.png";
import Geo from "../../assets/images/icons/geo2.png";
import Laptop from "../../assets/images/icons/keepa2.png";
import Chart from "../../assets/images/icons/calculator3.png";
import Brands from "../../assets/images/icons/brands2.png";
import UnieLogicsMobile from "../../assets/images/icons/MOBILEFLOW.png";


export const About = () => {
    return (
        <>
            <div className="about-page">
                <div className="text-center">
                    <div className="udl-section">
                        <h1>
                            HOW UNIELOGICS DECENTRALIZED LOGISTICS (UDL) WORKS
                        </h1>
                    </div>
                    <div className="about-sub-title">
                        <h1>
                            -reach more vendors and customers using our
                            network of 3PL locations nationwide-
                        </h1>
                    </div>
                    {/* SECOND PAGE */}
                    <div className="row">
                        <img
                            className="unilogics-image"
                            src={Unilegocs}
                            alt="uni"
                        />

                    {/* Mobile Image */}
                        <img
                            className="unilogics-mobile-image"
                            src={UnieLogicsMobile}
                            alt="uni-mobile"
                        />

                        <div className="about-sub-title">
                            <h1>
                                **works with all your ecommerce channels**
                            </h1>
                        </div>
                        <div className="horizontal-line-spacing">
                            <hr className="horizontal-line-about" />
                        </div>
                    </div>
                    {/* THRID PAGE */}
                    <div>
                        <div className="udl-section2">
                            <h1>
                                WE CAN INTERGRATE WITH YOUR FAVORITE MARKETPLACES
                            </h1>
                        </div>
                        <div className="company-logos">
                        <div>
                            <img src={Amazon} alt="Amazon-logo" className="amazon-logo" />
                        </div>
                        <div>
                            <img src={Walmart} alt="Walmart-logo" className="walmart-logo" />
                        </div>
                        <div>
                            <img src={Ebay} alt="Ebay-logo" className="ebay-logo" />
                        </div>
                        <div>
                            <img src={Shopify} alt="Shopify-logo" className="shopify-logo" />
                        </div>
                        </div>
                        {/* Logos end */}
                        <div className="horizontal-line-spacings">
                            <hr className="horizontal-line-about" />
                        </div>
                    </div>
                    {/* Next Page */}
                    <div className="about-section">
                        <div className="udl-section3">
                            <h1>SIMPLER, FASTER & MORE AUTOMATED</h1>
                        </div>

                        {/* FIRST ROW */}
                        <div className="products-pages">
                            <div className="products-image">
                                <img
                                    src={Products}
                                    alt="Products"
                                    className="img-fluid"
                                />
                            </div>
                            <div className="">
                                <h1 className="product-card-title">
                                    -Smarter Product Insights-
                                </h1>
                                <div className="products-text">
                                    Get compiled data from propietary databases and APIs to
                                    give you selling, risk, warehousing and transportation insights.

                                </div>
                            </div>
                        </div>

                        {/* SECOND ROW */}
                        <div className="products-pages">
                            <div className="">
                                <h1 className="product-card-title">
                                    -GeoMatching System-
                                </h1>
                                <div className="products-text">
                                    During shipment plans UnieLogics will provide you with inventory
                                    allocation suggestions based on vendor
                                    location.
                                </div>
                            </div>
                            <div className="products-image">
                                <img
                                    src={Geo}
                                    alt="Geo"
                                    className="img-fluid"
                                />
                            </div>
                        </div>

                        {/* THIRD ROW */}
                        <div className="products-pages">
                            <div className="products-image">
                                <img
                                    src={Price}
                                    alt="Price"
                                    className="img-fluid"
                                />
                            </div>
                            <div className="">
                                <h1 className="product-card-title">
                                    -Smarter Quotes-
                                </h1>
                                <div className="products-text">
                                    Based on your logistics plan our system will take into consideration your inventory needs and
                                    generate an instant quote for your shipment plan.
                                </div>
                            </div>
                        </div>

                        {/* FOURTH ROW */}
                        <div className="products-pages">
                            <div className="">
                                <h1 className="product-card-title">
                                    -Rate Shopping-
                                </h1>
                                <div className="products-text">
                                    During order fulfillment we will automate label purchasing for you to get the cheapest, fastest or any other preference
                                    of label of your preference.
                                </div>
                            </div>
                            <div className="products-image">
                                <img
                                    src={Shipping}
                                    alt="Shipping"
                                    className="img-fluid"
                                />
                            </div>
                        </div>

                    </div>
                    {/* READY TO GET STARTED? */}
                    <div className="jumbotron-about">

                        <div className="about-page-title">
                            <h1>READY TO GET STARTED ?</h1>
                        </div>
                        <div className="about-sub-title">
                            <h1>
                                Get a 7 day trial plus $10 to try your first shipment on us.
                            </h1>
                        </div>
                        <div className="home-button">
                            <div className="get-started">
                                <a
                                    className="get-started-button btn"
                                    href="/Register"
                                    role="button">
                                    Get Started
                                </a>
                            </div>
                        </div>

                        <div className="no-credit-card">
                            <h1 className="credit-card-title">
                                **No Credit Card Required**
                            </h1>
                            <hr className="no-credit-card-line" />
                        </div>
                        {/* BORDER */}


                    </div>
                    {/* FOURTH PAGE SECTION*/}
                    <div className="about-section">
                        <div className="about-page-title">
                            <h1>MORE THAN JUST LOGISTICS</h1>
                        </div>

                        {/* FIRST ROW */}
                        <div className="products-pages">
                            <div className="products-image">
                                <img
                                    src={Laptop}
                                    alt="Products"
                                    className="img-fluid"
                                />
                            </div>
                            <div className="product-card">
                                <h1 className="product-card-title">
                                    -Enhance Keepa like Data-
                                </h1>
                                <div className="products-text">
                                    Use our Chrome extension to explore products and access extensive
                                    product data to help you make a better buying decision.
                                </div>
                            </div>
                        </div>

                        {/* SECOND ROW */}
                        <div className="products-pages">
                            <div className="">
                                <h1 className="product-card-title">
                                    -Hazmat & IP Warnings-
                                </h1>
                                <div className="products-text">
                                    Protect your account and get alerts for Hazmat and IP complaints to help you avoid selling restricted products.
                                </div>
                            </div>
                            <div className="products-image">
                                <img
                                    src={Brands}
                                    alt="Geo"
                                    className="img-fluid"
                                />
                            </div>
                        </div>

                        {/* THIRD ROW */}
                        <div className="products-pages">
                            <div className="products-image long-images">
                                <img
                                    src={Chart}
                                    alt="Price"
                                    className="img-fluid"
                                />
                            </div>
                            <div className="product-card">
                                <h1 className="product-card-title">
                                    -Calculator, Notes & Collaboration-
                                </h1>
                                <div className="products-text">
                                    Use our tool to forecast your earnings, take important notes and
                                    collaborate with other users to find and manage your catalog.
                                </div>
                            </div>
                        </div>

                        {/* BORDER */}
                        <div className="horizontal-line-spacings">
                            <hr className="horizontal-line-about" />
                        </div>
                    </div>
                    {/* Last Page */}
                    <div className="display-5 about-page-title">
                        <h1>4 STEPS TO SCALE</h1>
                    </div>
                    <div className="circle-container">
                        <div className="circle-item">
                            <div className="circle">
                                <div className="number-circle">1</div>
                            </div>
                            <p className="circle-text">Join Our Beta</p>
                            <p className="circle-text">Program</p>
                        </div>
                        <div className="line"></div>
                        <div className="circle-item">
                            <div className="circle">
                                <div className="number-circle">2</div>
                            </div>
                            <p className="circle-text">Send You First</p>
                            <p className="circle-text">Shipment</p>
                        </div>
                        <div className="line"></div>
                        <div className="circle-item">
                            <div className="circle">
                                <div className="number-circle">3</div>
                            </div>
                            <p className="circle-text">Scales with</p>
                            <p className="circle-text">Unielogics</p>
                        </div>
                        <div className="line"></div>
                        <div className="circle-item">
                            <div className="circle">
                                <div className="number-circle">4</div>
                            </div>
                            <p className="circle-text">Access</p>
                            <p className="circle-text">New Features</p>
                        </div>
                    </div>

                    {/* BORDER */}
                    <div className="horizontal-line-spacings">
                        <hr className="horizontal-line-about" />
                    </div>
                    {/* READY TO GET STARTED? */}
                    <div>
                        <div className="about-page-title">
                            <h1>READY TO GET STARTED ?</h1>
                        </div>
                        <div className="about-sub-title">
                            <h1>
                                Get a 7 day trial plus $10 to try your first shipment on us.
                            </h1>
                        </div>
                        <div className="home-button">
                            <div className="get-started">
                                <a
                                    className="get-started-button btn"
                                    href="/Register"
                                    role="button">
                                    Get Started
                                </a>
                            </div>
                        </div>
                        <div className="no-credit-card">
                            <h1 className="credit-card-footer">
                                **No Credit Card Required**
                            </h1>
                            <hr className="no-credit-card-line" />
                        </div>
                    </div>
                    {/* BORDER */}
                    <div className="horizontal-line-spacings">
                        <hr className="horizontal-line-about" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;

