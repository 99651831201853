import React, { useMemo } from "react";
import dayjs from "dayjs";

interface BadgeProps {
  date: string;
}

const Badge: React.FC<BadgeProps> = ({ date }) => {
  const badgeContent = useMemo(() => {
    const currentDate = dayjs();
    const itemDate = dayjs(date);

    if (
      itemDate.isAfter(currentDate.subtract(3, "day"), "day") &&
      itemDate.isBefore(currentDate)
    ) {
      return {
        text: "< 3 days",
        className: "badge-success",
      };
    } else if (
      itemDate.isAfter(currentDate.subtract(7, "day"), "day") &&
      itemDate.isBefore(currentDate.subtract(3, "day"), "day")
    ) {
      return {
        text: "3 - 7 days",
        className: "badge-warning",
      };
    } else {
      return {
        text: "> 7 days",
        className: "badge-danger",
      };
    }
  }, [date]);

  return (
    <span className={`badge ${badgeContent.className}`}>
      {badgeContent.text}
    </span>
  );
};

export default Badge;
