import useSurveyStateStore from '../useSurveyStore';
import { surveySteps } from '../interface';

export default function SurveyControl() {
    const { currentStepIndex } = useSurveyStateStore();

    // Get the current step based on currentStepIndex
    const currentStep = surveySteps[currentStepIndex];

    return (
        <div>
            <div className="flex justify-center items-center">
                {/* Content container */}
                <div className="w-full max-w-4xl px-4">
                    {/* Horizontal layout for questions */}
                    <div className="flex space-x-4 justify-center mt-2">
                        {surveySteps.map((step, index) => (
                            <div key={index} className="flex items-center space-x-2">
                                <div
                                    className={`w-6 h-6 rounded-full flex items-center justify-center ${index < currentStepIndex ? 'bg-blue-500' : 'border-2 border-blue-500'
                                        }`}
                                ></div>

                                <span className="text-black">{step.headline}</span> {/* Display headline for each question */}
                            </div>
                        ))}
                    </div>

                    {/* Conditionally render title and subtitle based on current step */}
                    <div className="mt-1 text-center">
                        <div className="text-xl text-black">{currentStep?.title}</div>
                        <div className="text-gray-600">{currentStep?.subtitle}
                            {/* <span className="text-red-500 text-sm">(Select at least one option to proceed)</span> */}

                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}
