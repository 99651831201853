// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warehouse-owners .warehouse-business-ul li {
  list-style-type: none;
}
.warehouse-owners .warehouse-business-ul li span {
  font-style: italic;
}

thead.sticky-header {
  position: sticky;
  top: 0;
  background-color: rgb(37, 80, 141);
  font-family: "Times New Roman", Times, serif;
  font-size: 12px;
}

td {
  font-family: "Times New Roman", Times, serif;
}

.collapse.show {
  visibility: visible;
}

.custom-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: 1.3px solid rgba(238, 238, 238, 0.2274509804);
  border-radius: 5%;
  background-color: transparent;
  transition: all 0.3s ease;
  cursor: pointer;
  margin: 0 5px;
}

.custom-button i {
  font-size: 13px;
  color: #600000;
}

.edits i {
  font-size: 13px;
  color: #038271;
}

.custom-button:hover {
  border-color: #007bff;
  background-color: #f0f8ff;
  transform: scale(1.1);
}

.custom-button:active {
  transform: scale(0.9);
}`, "",{"version":3,"sources":["webpack://./src/pages/adminWarehouseOwners/styles.scss"],"names":[],"mappings":"AAEI;EACE,qBAAA;AADN;AAEM;EACE,kBAAA;AAAR;;AAOE;EACE,gBAAA;EACA,MAAA;EACA,kCAAA;EACA,4CAAA;EACA,eAAA;AAJJ;;AAQA;EACE,4CAAA;AALF;;AAUA;EACE,mBAAA;AAPF;;AASA;EACE,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,qDAAA;EACA,iBAAA;EACA,6BAAA;EACA,yBAAA;EACA,eAAA;EACA,aAAA;AANF;;AASA;EACE,eAAA;EACA,cAAA;AANF;;AASA;EACE,eAAA;EACA,cAAA;AANF;;AAQA;EACE,qBAAA;EACA,yBAAA;EACA,qBAAA;AALF;;AAQA;EACE,qBAAA;AALF","sourcesContent":[".warehouse-owners {\n  .warehouse-business-ul {\n    li {\n      list-style-type: none;\n      span {\n        font-style: italic;\n      }\n    }\n  }\n}\n\nthead {\n  &.sticky-header {\n    position: sticky;\n    top: 0;\n    background-color:rgb(37, 80, 141);\n    font-family:'Times New Roman', Times, serif;\n    font-size: 12px;\n  }\n}\n\ntd{\n  font-family:'Times New Roman', Times, serif;\n  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;\n}\n\n\n.collapse.show {\n  visibility: visible;\n}\n.custom-button {\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  width: 25px;\n  height: 25px;\n  border: 1.3px solid #eeeeee3a;\n  border-radius: 5%;\n  background-color: transparent;\n  transition: all 0.3s ease;\n  cursor: pointer;\n  margin: 0 5px;\n}\n\n.custom-button i {\n  font-size: 13px;\n  color: #600000;\n}\n\n.edits i {\n  font-size: 13px;\n  color: #038271;\n}\n.custom-button:hover {\n  border-color: #007bff;\n  background-color: #f0f8ff;\n  transform: scale(1.1);\n}\n\n.custom-button:active {\n  transform: scale(0.9);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
