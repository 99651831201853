import { useEffect, useState } from "react";
import "bootstrap/dist/js/bootstrap.bundle";
import { useForm } from "react-hook-form";
import "./style.scss";
import { createWarehouseOwner } from "pages/adminWarehouseOwners/api";
import { PostWarehouseModel } from "./saveWarehouseModal";
import { toast } from "react-toastify";

const WarehouseOwnersForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    resetField,
  } = useForm<PostWarehouseModel>({
    mode: "onChange", // This ensures isValid is updated as fields change
  });

  const [toggleShrinkWrap, setToggleShrinkWrap] = useState(false);
  // errors?.wOwner.

  const [toggleHazmatWrap, setToggleHazmatWrap] = useState(false);

  const [toggleBubbleWrapping, setToggleBubbleWrapping] = useState(false);

  const [toggleOfferStorage, setToggleOfferStorage] = useState(false);

  const [hazmatPrice, setHazmatPrice] = useState("");
  const [bubbleWrappingPrice, setBubbleWrappingPrice] = useState("");
  const [shrinkWrapPrices, setShrinkWrapPrices] = useState({
    small: "",
    medium: "",
    large: "",
  });
  const [storagePrices, setStoragePrices] = useState({
    pricePerPalet: "",
    pricePerCubicFeet: "",
  });

  const handleHazmatPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHazmatPrice(e.target.value);
  };

  const handleBubbleWrappingPriceChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBubbleWrappingPrice(e.target.value);
  };

  const handleShrinkWrapPriceChange = (size: string, value: string) => {
    setShrinkWrapPrices({ ...shrinkWrapPrices, [size]: value });
  };

  const handleStoragePriceChange = (type: string, value: string) => {
    setStoragePrices({ ...storagePrices, [type]: value });
  };

  useEffect(() => {
    if (!toggleHazmatWrap) {
      resetField("wOwner.handleHazmat.pricePerItem");
    }

    if (!toggleBubbleWrapping) {
      resetField("wOwner.bubbleWrapping.pricePerItem");
    }

    if (!toggleOfferStorage) {
      resetField("wOwner.offerStorage.pricePerPalet");
      resetField("wOwner.offerStorage.pricePerCubicFeet");
    }

    if (!toggleShrinkWrap) {
      resetField("wOwner.handleShrink.small.price");
      resetField("wOwner.handleShrink.medium.price");
      resetField("wOwner.handleShrink.large.price");
    }

    const modal = document.getElementById("addWarehouseModals");
    const handleModalHidden = () => reset();

    modal?.addEventListener("hidden.bs.modal", handleModalHidden);

    return () => {
      modal?.removeEventListener("hidden.bs.modal", handleModalHidden);
    };
  }, [
    toggleShrinkWrap,
    toggleOfferStorage,
    toggleHazmatWrap,
    toggleBubbleWrapping,
    reset,
    resetField,
  ]);

  const onSubmit = async (data: PostWarehouseModel) => {
    const dataModel: PostWarehouseModel = {
      loggedInEmail: "franco@peri-mail.com",
      wOwner: {
        ...data.wOwner,
        handleHazmat: {
          answer: toggleHazmatWrap ? "Yes" : "No",
          pricePerItem: toggleHazmatWrap ? hazmatPrice || "0" : "0",
        },
        handleShrink: {
          answer: toggleShrinkWrap ? "Yes" : "No",
          small: {
            price: toggleShrinkWrap ? shrinkWrapPrices.small || "0" : "0",
          },
          medium: {
            price: toggleShrinkWrap ? shrinkWrapPrices.medium || "0" : "0",
          },
          large: {
            price: toggleShrinkWrap ? shrinkWrapPrices.large || "0" : "0",
          },
        },
        bubbleWrapping: {
          answer: toggleBubbleWrapping ? "Yes" : "No",
          pricePerItem: toggleBubbleWrapping ? bubbleWrappingPrice || "0" : "0",
        },
        offerStorage: {
          answer: toggleOfferStorage ? "Yes" : "No",
          pricePerPalet: toggleOfferStorage
            ? storagePrices.pricePerPalet || "0"
            : "0",
          pricePerCubicFeet: toggleOfferStorage
            ? storagePrices.pricePerCubicFeet || "0"
            : "0",
        },
        costPerBox: [
          ...(data.wOwner?.costPerBox || []),
          {
            type: "Small",
            name: "Box A",
            size: {
              width: "0",
              length: "0",
              height: "0",
            },
            price: "0",
          },
        ],
      },
    };
    try {
      await createWarehouseOwner(dataModel);
      toast.success("Warehouse Successfully Created");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error: any) {
      toast.error(
        `An error occurred while saving. Please try again.${error.message}`
      );
    }
  };

  const onCancel = () => {
    reset({}, { keepErrors: false, keepDirty: false });
  };

  return (
    <>
      <div
        className="modal fade add-warehouse"
        tabIndex={-1}
        id="addWarehouseModals"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Warehouse Owners Sign Up</h5>
            </div>
            <div className="modal-body">
              <form
                className="row"
                autoComplete="nope"
                onSubmit={handleSubmit(onSubmit)}
                noValidate>
                <h1 className="text-center title">Warehouse Details</h1>
                <div className="col-6">
                  <div
                    className={`  ${
                      errors?.wOwner?.lobId?.message && "form-error"
                    }`}>
                    <label htmlFor="wo-lob-id">Lob Id</label>
                    <input
                      type="text"
                      id="wo-lob-id"
                      placeholder="Enter lobId"
                      className="form-control"
                      autoComplete="none"
                      {...register("wOwner.lobId", {
                        required: "lob Id is required",
                      })}
                    />
                    <span>{errors?.wOwner?.lobId?.message}</span>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className={`  ${
                      errors?.wOwner?.warehouseId?.message && "form-error"
                    }`}>
                    <label htmlFor="wo-warehouse-id">Warehouse Id</label>
                    <input
                      type="text"
                      id="wo-warehouse-id"
                      placeholder="Enter warehouse id"
                      className="form-control"
                      autoComplete="none"
                      {...register("wOwner.warehouseId", {
                        required: "warehouseId is required",
                      })}
                    />
                    <span>{errors?.wOwner?.warehouseId?.message}</span>
                  </div>
                </div>
                {/* Business Details */}
                <h1 className="text-center title">Business Details</h1>
                <div className="col-6">
                  <div
                    className={`  ${
                      errors?.wOwner?.businessName?.message && "form-error"
                    }`}>
                    <label htmlFor="wob-name">Name</label>
                    <input
                      className="form-control"
                      id="wob-name"
                      placeholder="Enter business name"
                      autoComplete="none"
                      {...register("wOwner.businessName", {
                        required: "businessName is required",
                      })}
                    />
                    <span>{errors?.wOwner?.businessName?.message}</span>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className={`  ${
                      errors?.wOwner?.businessAddress?.address?.message &&
                      "form-error"
                    }`}>
                    <label htmlFor="wob-address">Address</label>
                    <input
                      className="form-control"
                      id="wob-address"
                      placeholder="Enter business address"
                      autoComplete="none"
                      {...register("wOwner.businessAddress.address", {
                        required: "address is required",
                      })}
                    />
                    <span>{errors?.wOwner?.businessAddress?.message}</span>
                  </div>
                </div>
                <div className="col-4">
                  <div
                    className={`  ${
                      errors?.wOwner?.businessAddress?.state?.message &&
                      "form-error"
                    }`}>
                    <label htmlFor="wob-state">State</label>
                    <input
                      className="form-control"
                      id="wob-state"
                      placeholder="Enter business state"
                      autoComplete="none"
                      {...register("wOwner.businessAddress.state", {
                        required: "state is required",
                      })}
                    />
                    <span>
                      {errors?.wOwner?.businessAddress?.state?.message}
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  <div
                    className={`  ${
                      errors?.wOwner?.businessAddress?.city?.message &&
                      "form-error"
                    }`}>
                    <label htmlFor="wob-city">City</label>
                    <input
                      className="form-control"
                      id="wob-city"
                      placeholder="Enter business city"
                      autoComplete="none"
                      {...register("wOwner.businessAddress.city", {
                        required: "city is required",
                      })}
                    />
                    <span>
                      {errors?.wOwner?.businessAddress?.city?.message}
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  <div
                    className={`  ${
                      errors?.wOwner?.businessAddress?.zipCode?.message &&
                      "form-error"
                    }`}>
                    <label htmlFor="wob-zip-code">Zip code</label>
                    <input
                      className="form-control"
                      id="wob-zip-code"
                      placeholder="Enter zip code"
                      autoComplete="none"
                      {...register("wOwner.businessAddress.zipCode", {
                        required: "zipCode is required",
                      })}
                    />
                    <span>
                      {errors?.wOwner?.businessAddress?.zipCode?.message}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className={`  ${
                      errors?.wOwner?.businessPhoneNumber?.message &&
                      "form-error"
                    }`}>
                    <label htmlFor="wob-phone-number">Phone number</label>
                    <input
                      className="form-control"
                      id="wob-phone-number"
                      placeholder="Enter business phone number"
                      autoComplete="none"
                      {...register("wOwner.businessPhoneNumber", {
                        required: "business Phone Number is required",
                      })}
                      maxLength={12}
                    />
                    <span>{errors?.wOwner?.businessPhoneNumber?.message}</span>
                  </div>
                </div>{" "}
                <div className="col-6">
                  <div
                    className={`  ${
                      errors?.wOwner?.customerServiceEmailAddress?.message &&
                      "form-error"
                    }`}>
                    <label htmlFor="wob-customer-service-email">
                      Customer service email
                    </label>
                    <input
                      className="form-control"
                      id="wob-customer-service-email"
                      placeholder="Enter customer service email"
                      autoComplete="none"
                      {...register("wOwner.customerServiceEmailAddress", {
                        required: "customer Email Service is required",
                      })}
                    />
                    <span>
                      {errors?.wOwner?.customerServiceEmailAddress?.message}
                    </span>
                  </div>
                </div>
                {/* Personal Details */}
                <h1 className="text-center title"> Personal Details</h1>
                <div className="col-6">
                  <div
                    className={`  ${
                      errors?.wOwner?.name?.message && "form-error"
                    }`}>
                    <label htmlFor="wo-lob-id">Name</label>
                    <input
                      type="text"
                      id="wo-lob-id"
                      placeholder="Enter name"
                      className="form-control"
                      autoComplete="none"
                      {...register("wOwner.name", {
                        required: "name is required",
                      })}
                    />
                    <span>{errors?.wOwner?.name?.message}</span>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className={`  ${
                      errors?.wOwner?.email?.message && "form-error"
                    }`}>
                    <label htmlFor="wo-lob-id">Email</label>
                    <input
                      type="text"
                      id="wo-lob-id"
                      placeholder="Enter Email"
                      className="form-control"
                      autoComplete="none"
                      {...register("wOwner.email", {
                        required: "email is required",
                      })}
                    />
                    <span>{errors?.wOwner?.email?.message}</span>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className={`  ${
                      errors?.wOwner?.phoneNumber?.message && "form-error"
                    }`}>
                    <label htmlFor="wo-lob-id">Phone Number</label>
                    <input
                      type="text"
                      id="wo-lob-id"
                      placeholder="Enter Phone Number"
                      className="form-control"
                      autoComplete="none"
                      {...register("wOwner.phoneNumber", {
                        required: "Phone Number is required",
                      })}
                    />
                    <span>{errors?.wOwner?.phoneNumber?.message}</span>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className={`  ${
                      errors?.wOwner?.llcName?.message && "form-error"
                    }`}>
                    <label htmlFor="wo-lob-id">LLC Name</label>
                    <input
                      type="text"
                      id="wo-lob-id"
                      placeholder="Enter LLC Name"
                      className="form-control"
                      autoComplete="none"
                      {...register("wOwner.llcName", {
                        required: "llc Name is required",
                      })}
                    />
                    <span>{errors?.wOwner?.llcName?.message}</span>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className={`  ${
                      errors?.wOwner?.password?.message && "form-error"
                    }`}>
                    <label htmlFor="wo-lob-id">Password</label>
                    <input
                      type="text"
                      id="wo-lob-id"
                      placeholder="Enter Password"
                      className="form-control"
                      autoComplete="none"
                      {...register("wOwner.password", {
                        required: "password is required",
                      })}
                    />
                    <span>{errors?.wOwner?.password?.message}</span>
                  </div>
                </div>
                {/* COST Details */}
                <h1 className="text-center title"> Cost details</h1>
                <div className="col-6">
                  <div
                    className={`  ${
                      errors.wOwner?.costPerItemLabeling?.message &&
                      "form-error"
                    }`}>
                    <label htmlFor="wob-cost-per-item-labeling">
                      Cost per item labeling
                    </label>
                    <input
                      className="form-control"
                      id="wob-cost-per-item-labeling"
                      placeholder="Enter cost per item labeling"
                      autoComplete="none"
                      {...register("wOwner.costPerItemLabeling", {
                        required: "cost Per Item Labeling is required",
                      })}
                    />
                    <span>{errors.wOwner?.costPerItemLabeling?.message}</span>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className={`  ${
                      errors.wOwner?.costPerBoxClosing?.message && "form-error"
                    }`}>
                    <label htmlFor="wob-cost-per-item-labeling">
                      Cost per box closing
                    </label>
                    <input
                      className="form-control"
                      id="wob-cost-per-box-closing"
                      placeholder="Enter cost per blox closing"
                      autoComplete="none"
                      {...register("wOwner.costPerBoxClosing", {
                        required: "cost Per Box Closing is required",
                      })}
                    />
                    <span>{errors.wOwner?.costPerBoxClosing?.message}</span>
                  </div>
                </div>
                <div className="col-6">
                  <h5>Do they handle hazmat products?</h5>
                  <div className="d-flex">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="Yes"
                        id="wob-yes-hazmat-wrap"
                        {...register("wOwner.handleHazmat.answer", {
                          required: "This field is required",
                        })}
                        onClick={() => setToggleHazmatWrap(true)}
                        autoComplete="none"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="wob-yes-hazmat-wrap">
                        Yes
                      </label>
                    </div>

                    <div className="form-check radio-container">
                      <input
                        defaultChecked
                        autoComplete="none"
                        className="form-check-input"
                        type="radio"
                        value="No"
                        {...register("wOwner.handleHazmat.answer", {
                          required: "This field is required",
                        })}
                        id="wob-no-hazmat-wrap"
                        onClick={() => setToggleHazmatWrap(false)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="wob-no-hazmat-wrap">
                        No
                      </label>
                    </div>
                  </div>

                  {errors?.wOwner?.handleHazmat?.answer && (
                    <span>{errors.wOwner.handleHazmat.answer.message}</span>
                  )}

                  {toggleHazmatWrap && (
                    <div className="wo-signup-container">
                      <div>
                        <label htmlFor="wob-hazmat">Price</label>
                        <input
                          className="form-control"
                          id="wob-hazmat"
                          placeholder="Enter price"
                          autoComplete="none"
                          {...register("wOwner.handleHazmat.pricePerItem")}
                          value={hazmatPrice}
                          onChange={handleHazmatPriceChange}
                        />
                        <span>
                          {errors?.wOwner?.handleHazmat?.pricePerItem?.message}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-6">
                  <h5>Do they do bubble wrapping?</h5>

                  <div className="d-flex ">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="wob-yes-bubble-wrap"
                        autoComplete="none"
                        value="Yes"
                        {...register("wOwner.bubbleWrapping.answer")}
                        onClick={() => setToggleBubbleWrapping(true)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="wob-yes-bubble-wrap">
                        Yes
                      </label>
                    </div>

                    <div className="form-check radio-container">
                      <input
                        defaultChecked
                        autoComplete="none"
                        className="form-check-input"
                        type="radio"
                        value="No"
                        {...register("wOwner.bubbleWrapping.answer")}
                        id="wob-no-bubble-wrap"
                        onClick={() => setToggleBubbleWrapping(false)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="wob-no-bubble-wrap">
                        No
                      </label>
                    </div>
                  </div>

                  {toggleBubbleWrapping && (
                    <div className="wo-signup-container">
                      <div>
                        <label htmlFor="wob-buble-wrapping">Price</label>
                        <input
                          className="form-control"
                          id="wob-buble-wrapping"
                          placeholder="Enter price"
                          autoComplete="none"
                          {...register("wOwner.bubbleWrapping.pricePerItem")}
                          value={bubbleWrappingPrice}
                          onChange={handleBubbleWrappingPriceChange}
                        />
                        <span>
                          {errors.wOwner?.bubbleWrapping?.pricePerItem?.message}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {/* START EHER */}
                <div className="col-6">
                  <h5>Do they handle shrink wrap?</h5>

                  <div className="d-flex ">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="decideShrinkWrap"
                        value="Yes"
                        {...register("wOwner.handleShrink.answer")}
                        onClick={() => setToggleShrinkWrap(true)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="decideShrinkWrap">
                        Yes
                      </label>
                    </div>

                    <div className="form-check radio-container">
                      <input
                        defaultChecked
                        className="form-check-input"
                        type="radio"
                        id="decideShrinkWrap-no"
                        autoComplete="none"
                        value="No"
                        {...register("wOwner.handleShrink.answer")}
                        onClick={() => setToggleShrinkWrap(false)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="decideShrinkWrap">
                        No
                      </label>
                    </div>
                  </div>

                  {toggleShrinkWrap && (
                    <div className="wo-signup-container">
                      <div>
                        <label htmlFor="wob-shrink-small">Small</label>
                        <input
                          className="form-control"
                          id="wob-shrink-small"
                          placeholder="Enter price"
                          autoComplete="none"
                          {...register("wOwner.handleShrink.small.price")}
                          value={shrinkWrapPrices.small}
                          onChange={(e) =>
                            handleShrinkWrapPriceChange("small", e.target.value)
                          }
                        />
                        <span>
                          {errors.wOwner?.handleShrink?.small?.price?.message}
                        </span>
                      </div>

                      <div>
                        <label htmlFor="wob-shrink-medium">Medium</label>
                        <input
                          className="form-control"
                          id="wob-shrink-medium"
                          placeholder="Enter price"
                          autoComplete="none"
                          {...register("wOwner.handleShrink.medium.price")}
                          value={shrinkWrapPrices.medium}
                          onChange={(e) =>
                            handleShrinkWrapPriceChange(
                              "medium",
                              e.target.value
                            )
                          }
                        />
                        <span>
                          {errors?.wOwner?.handleShrink?.medium?.price?.message}
                        </span>
                      </div>

                      <div>
                        <label htmlFor="wob-shrink-large">Large</label>
                        <input
                          className="form-control"
                          id="wob-shrink-large"
                          placeholder="Enter price"
                          autoComplete="none"
                          {...register("wOwner.handleShrink.large.price")}
                          value={shrinkWrapPrices.large}
                          onChange={(e) =>
                            handleShrinkWrapPriceChange("large", e.target.value)
                          }
                        />
                        <span>
                          {errors.wOwner?.handleShrink?.large?.price?.message}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {/* SET HERE */}
                <div className="col-6">
                  <h5>Do they offer storage?</h5>

                  <div className="d-flex ">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="Yes"
                        id="wob-yes-storage"
                        autoComplete="none"
                        {...register("wOwner.offerStorage.answer")}
                        onClick={() => setToggleOfferStorage(true)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="wob-yes-storage">
                        Yes
                      </label>
                    </div>

                    <div className="form-check radio-container">
                      <input
                        defaultChecked
                        className="form-check-input"
                        type="radio"
                        value="No"
                        id="wob-no-storage"
                        autoComplete="none"
                        {...register("wOwner.offerStorage.answer")}
                        onClick={() => setToggleOfferStorage(false)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="wob-no-storage">
                        No
                      </label>
                    </div>
                  </div>

                  {toggleOfferStorage && (
                    <div className="wo-signup-container">
                      <div>
                        <label htmlFor="wob-storage-palet">
                          Price per palet
                        </label>
                        <input
                          className="form-control"
                          id="wob-storage-palet"
                          placeholder="Enter price"
                          autoComplete="none"
                          {...register("wOwner.offerStorage.pricePerPalet")}
                          value={storagePrices.pricePerPalet}
                          onChange={(e) =>
                            handleStoragePriceChange(
                              "pricePerPalet",
                              e.target.value
                            )
                          }
                        />
                        <span>
                          {errors.wOwner?.offerStorage?.pricePerPalet?.message}
                        </span>
                      </div>

                      <div>
                        <label htmlFor="wob-storage-cubic">
                          Price per cubic feet
                        </label>
                        <input
                          className="form-control"
                          id="wob-storage-cubic"
                          placeholder="Enter price"
                          autoComplete="none"
                          {...register("wOwner.offerStorage.pricePerCubicFeet")}
                          value={storagePrices.pricePerCubicFeet}
                          onChange={(e) =>
                            handleStoragePriceChange(
                              "pricePerCubicFeet",
                              e.target.value
                            )
                          }
                        />
                        <span>
                          {
                            errors?.wOwner?.offerStorage?.pricePerCubicFeet
                              ?.message
                          }
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={onCancel}>
                    Close
                  </button>
                  <button
                    type="submit"
                    disabled={!isValid}
                    className="btn btn-primary"
                    data-bs-dismiss="modal">
                    saved
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WarehouseOwnersForm;
