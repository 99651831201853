import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { useLocation, useNavigate } from "react-router-dom";
import { pageRoutes } from "utils";
import SidebarMenu from "components/SidebarMenu";

const LoadingScreen = () => {
  const location = useLocation();
  const planId = location.state;
  const [percentage, setPercentage] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage((prev) => {
        if (prev < 100) {
          return prev + 1;
        } else {
          clearInterval(interval);
          setIsCompleted(true);
          navigate(pageRoutes.SHIPMENT_PLAN_LOADING);
          return prev;
        }
      });
    }, 150);
    return () => clearInterval(interval);
  }, [navigate]);

  const handleConfirmation = () => {
    setIsCompleted(true);
    navigate(pageRoutes.SHIPMENT_PLAN_PAYMENT_THANK_YOU);
  };

  const styles = {
    root: {
      backgroundColor: "rgb(15, 17, 26)",
      color: "rgb(79, 70, 229)",
      display: "flex" as const,
      flexDirection: "column" as const,
      justifyContent: "center" as const,
      alignItems: "center" as const,
      fontFamily: "sans-serif",
      height: "100vh",
      margin: 0,
    },
    truck: {
      width: "12em",
      height: "auto",
      position: "relative" as const,
      marginBottom: "20px",
    },
    loadingText: {
      marginTop: "20px",
      fontSize: "1.5em",
      color: "rgb(79, 70, 229)",
    },
    percentage: {
      fontSize: "1.2em",
      marginTop: "10px",
      color: "rgb(79, 70, 229)",
    },
    button: {
      marginTop: "20px",
      padding: "10px 20px",
      fontSize: "1em",
      backgroundColor: "rgb(79, 70, 229)",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      visibility: isCompleted ? "visible" : ("hidden" as "visible" | "hidden"),
    },
  };

  return (
    <div>
      <div className="sidebar">
        <SidebarMenu
          active="shipment-plans"
          sidebarOnly={true}
        />
      </div>

      <div style={styles.root}>
        <svg
          className="truck"
          style={styles.truck}
          viewBox="0 0 48 24"
          width="48px"
          height="24px"
        >
          <g
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(0,2)"
          >
            <g>
              <g strokeDasharray="105 105">
                <polyline points="2 17,1 17,1 11,5 9,7 1,39 1,39 6" />
                <polyline points="39 12,39 17,31.5 17" />
                <polyline points="22.5 17,11 17" />
                <polyline points="6.5 4,8 4,8 9,5 9" />
                <polygon points="10 4,10 9,14 9,14 4" />
              </g>
              <polyline
                points="43 8,31 8"
                strokeDasharray="10 2 10 2 10 2 10 2 10 2 10 26"
              />
              <polyline
                points="47 10,31 10"
                strokeDasharray="14 2 14 2 14 2 14 2 14 18"
              />
            </g>
            <g strokeDasharray="15.71 15.71">
              <g>
                <circle r="2.5" cx="6.5" cy="17" />
              </g>
              <g>
                <circle r="2.5" cx="27" cy="17" />
              </g>
            </g>
          </g>
        </svg>
        <div style={styles.loadingText}>
          {percentage < 100 ? "Creating Shipment..." : "Shipment Created!"}
        </div>
        <strong>Please stay on this page till loading is finished</strong>
        <div style={styles.percentage}>{percentage}%</div>
        <button style={styles.button} onClick={handleConfirmation}>
          Order Confirmation
        </button>
      </div>
    </div>
  );
};

export default LoadingScreen;
