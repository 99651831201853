import React from "react";

interface RadioButtonGroupProps {
  register: Function; // Register function type
  // Reset form function type
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ register}) => {
  return (
    <div className="d-flex">
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          value="Yes"
          id="online-supplier-yes"
          autoComplete="none"
          {...register("onlineSupplier", { required: "This field cannot be empty" })} // Register the radio button
        />
        <label className="form-check-label" htmlFor="online-supplier-yes">
          Yes
        </label>
      </div>

      <div className="form-check radio-container">
        <input
          defaultChecked
          autoComplete="none"
          className="form-check-input"
          type="radio"
          value="No"
          id="online-supplier-no"
          {...register("onlineSupplier", { required: "This field cannot be empty" })} // Register the radio button
        />
        <label className="form-check-label" htmlFor="online-supplier-no">
          No
        </label>
      </div>
    </div>
  );
};

export default RadioButtonGroup;
