import { SurveyStatusEnum } from './enum';
export interface ISurvey {
    email: string;
    targetedStates: string[];
    targetedMarketplaces: string[]
}
export interface SurveyData {
    targetedStates: string[];
    targetedMarketplaces: string[];
    SurveyStatus: SurveyStatusEnum,
}
interface IUser {
    billingID: string;
    email: string;
    endDate: null;
    firstName: string;
    hasActiveSubscription: boolean;
    hasTrial: boolean;
    hash: string;
    lastName: string;
    notifications: boolean;
    phoneNumber: string;
    plan: string;
    role: string;
    salesPerMonthCheck: number;
    survey: SurveyData;
}

export interface ISurveyResponse {
    status: string;
    user: IUser;
}


export interface SurveyStep {
    headline: string;
    title: string;
    subtitle: string;
}

export const surveySteps: SurveyStep[] = [
    {
        headline: "Where Do You Need 3PL?",
        title: "Select the top 3 states for your business",
        subtitle: "Click or Search",
    },
    {
        headline: "Where Do You Sell",
        title: "Select all marketplaces that apply",
        subtitle: "Select all that apply",
    },
];

export const ButtonTexts = ["Next", "Finish"]

