import { useMutation } from "react-query";
import "./style.scss";
import { getShipmentPlanRequest } from "pages/shipmentPlan/api";

import { Link, useLocation } from "react-router-dom";
import { postShipmentPlanFilesRequest } from "./api";
import { toast } from "react-toastify";
import FullScreenLoading from "components/FullScreenLoading";
import { IShipmentPlan } from "pages/shipmentPlans/interfaces";
import { IContextUser } from "common/interfaces";
import { useContext, useEffect, useState } from "react";
import { QUERY_KEYS, UserContext, getQueryParameterByName } from "utils";
import { useQuery } from "react-query";
import React from "react";
import SidebarMenu from "components/SidebarMenu";

// import styles.scss
import "./style.scss";

const ShipmentPlanThankYouPage = () => {
  // useEffect(() => {
  //   let userObj = localStorage.getItem("asinmice-obj");
  //   userObj = JSON.parse(userObj!!);
  //   // @ts-ignore
  //   const email = userObj.email;

  //   if (paymentIntentId) {
  //     postShipmentPlanPayment({ paymentIntentId, email });
  //   } else {
  //     // toast.error(
  //     //   "Please contact us at support@unielogics.com with session id mentioned in URL, as it seems we couldn't mark the payment as paid. "
  //     // );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const { mutate: postShipmentPlanPayment, isLoading: isLoadingPayment } =
  //   useMutation(postShipmentPlanFilesRequest, {
  //     onSuccess: () => {
  //       toast.success("Successfully paid your invoice.");
  //       localStorage.removeItem("asinmice-payment-intent-id");
  //     },
  //     onError: (error: any) => {
  //       toast.error(
  //         error.response.data.message ??
  //           "There was an error trying to redirect to payment. Please try again and if the problem persists please contact us. "
  //       );
  //     },
  //   });

  // get shipment plan
  const planId = localStorage.getItem("planId") || "";
  const user: IContextUser = useContext(UserContext)!;
  const { email } = user;
  const [shipmentPlan, setShipmentPlan] = useState<IShipmentPlan[]>([]);
  const {
    data,
    error,
    isLoading: isLoadingShipmentPlan,
  } = useQuery(
    QUERY_KEYS.GET_SHIPMENT_PLAN,
    () => getShipmentPlanRequest(email, planId),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const supplierAddress = shipmentPlan[0]?.supplier.supplierAddress;

  const warehouseAddress = shipmentPlan[0]?.warehouseOwner?.businessAddress;

  const customerStreet = shipmentPlan[0]?.amazonData?.customerStreet;
  const customerCity = shipmentPlan[0]?.amazonData?.customerCity;
  const customerState = shipmentPlan[0]?.amazonData?.customerState;
  const customerZipCode = shipmentPlan[0]?.amazonData?.customerZipcode;

  // const customerAddress = shipmentPlans[0]?.amazonData?;

  const {
    street: supplierStreet,
    city: supplierCity,
    state: supplierState,
    zipCode: supplierZipCode,
  } = supplierAddress || {};

  const {
    address: warehouseStreet,
    city: warehouseCity,
    state: warehouseState,
    zipCode: warehouseZipCode,
  } = warehouseAddress || {};

  const shipDay = shipmentPlan[0]?.orderDate
    ? JSON.stringify(shipmentPlan[0].orderDate).slice(1, 11)
    : "N/A";

  const orderTotal = shipmentPlan[0]?.payment.totalAmount;

  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  const handleRedirect = () => {
    window.location.href = "/shipment-plans";
  }

  const toggleExpand = (index: number) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  useEffect(() => {
    if (data?.response) {
      setShipmentPlan(data?.response);
    }
    console.log(data);
  }, [data]);

  const calculateFutureDate = (
    baseDate: Date | string | undefined,
    hoursToAdd: number
  ): Date => {
    if (!baseDate) {
      baseDate = new Date();
    }

    const date = typeof baseDate === "string" ? new Date(baseDate) : baseDate;

    const millisecondsToAdd = hoursToAdd * 60 * 60 * 1000;
    return new Date(date.getTime() + millisecondsToAdd);
  };

  const arrivalDate = JSON.stringify(
    calculateFutureDate(shipmentPlan[0]?.orderDate, 48)
  ).slice(1, 11);

  return (
    <div>
      <div className="sidebar">
        <SidebarMenu active="shipment-plans" sidebarOnly={true} />
      </div>
      <>
        {/* {isLoadingPayment && <FullScreenLoading />} */}
        <div className="thankyou-page-wrapper">
          <div className="section">
            <div className="container mt-3">
              {/* display shipment plan id */}
              <strong>Your Shipment Plan:</strong>

              <p className="mb-1">#{shipmentPlan[0]?.orderNo}</p>

              <p>Is Being Processed</p>

              <div className="is-6 is-offset-desktop-3 mb-2 mt-2">
                <iframe
                  src="https://giphy.com/embed/tMwHvowh7JSM3vZUUR"
                  width="500"
                  height="170"
                  title="giphy video"
                  className="giphy-embed"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="shipment-summary">
                <p className="mb-3">Here is your shipment summary</p>
                <div className="payment-summary-grid-container">
                  <div className="payment-summary-grid-item">
                    <strong>Sending From</strong>
                    <p>
                      {supplierStreet}, {supplierCity}, {supplierState},{" "}
                      {supplierZipCode}
                    </p>
                  </div>
                  <div className="payment-summary-grid-item">
                    <strong>Receiving Facility</strong>
                    <p>
                      {warehouseStreet}, {warehouseCity}, {warehouseState},{" "}
                      {warehouseZipCode}
                    </p>
                  </div>
                  <div className="payment-summary-grid-item">
                    <strong>Final Destination</strong>
                    <p>
                      {customerStreet}, {customerCity}, {customerState},{" "}
                      {customerZipCode}
                    </p>
                  </div>
                </div>
                <div className="payment-summary-grid-container">
                  <div className="payment-summary-grid-item">
                    <strong>Receiving Day</strong>
                    <p>{shipDay}</p>
                  </div>
                  <div className="payment-summary-grid-item">
                    <strong>Estimated Arrival Day</strong>
                    <p>{arrivalDate}</p>
                  </div>
                  <div className="payment-summary-grid-item">
                    <strong>Order Total</strong>
                    <p>{Number(orderTotal).toFixed(2)}</p>
                  </div>
                </div>

                <div className="mb-3">
                  <button
                    className={`loadButton ${loading ? "loading" : "ready"}`}
                    disabled={loading}
                    onClick={handleRedirect}
                  >
                    {loading ? "Finish" : "Finish"}
                  </button>
                </div>

                <strong className="mb-2">Inventory Summary:</strong>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    gap: "10px",
                    textAlign: "left",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <div></div>
                  <div>Units Per Box</div>
                  <div>Boxes</div>
                  <div>Total Units</div>

                  {shipmentPlan[0]?.products?.map((product, index) => (
                    <React.Fragment key={index}>
                      {/* Title */}
                      <div
                        style={{
                          whiteSpace:
                            expandedRow === index ? "normal" : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          cursor: "pointer",
                          fontWeight: expandedRow === index ? "bold" : "normal",
                          width: "180px",
                        }}
                        onClick={() => toggleExpand(index)}
                      >
                        <strong>{product.title}</strong>
                      </div>

                      <div>{product.unitsPerBox || 0}</div>
                      <div>{product.boxes || 0}</div>
                      <div>
                        {Number(product.unitsPerBox || 0) *
                          Number(product.boxes || 0)}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                <p className="mt-4">*Click on title to expand</p>
              </div>

              <p>
                {/* warning image */}
                <p style={{ fontSize: "30px" }}>⚠️</p>

                <p>
                  Please be aware that you are fully responsible for maing sure
                  that the inventory is sent to the facility above. In the event
                  the items are not received within 30 days from the selected
                  "RECEIVING DAY" we will void your order. In the event, there
                  is a chargeback and your inventory is in our facilities. You
                  will be responsible for clearing the payment before we release
                  your inventory. For more information, please visit our terms
                  and contions
                </p>
              </p>
              <br />
              <Link to="/profitable-products">👉🏽 Back to Home Page</Link>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ShipmentPlanThankYouPage;
