import { create } from "zustand";
import { SurveyStep, surveySteps } from "./interface";

interface SurveyStateStore {
  surveyStates: string[];
  setSurveyStates: (newStates: string[]) => void;
  currentStepIndex: number;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  getCurrentStep: () => SurveyStep;

  selectedMarketplaces: string[]; // Track selected marketplaces
  setSelectedMarketplaces: (newSelectedMarketplaces: string[]) => void; 
  isButtonDisabled: () => boolean;

}

const useSurveyStateStore = create<SurveyStateStore>((set, get) => ({
  surveyStates: ["", "", ""], // Initial state
  setSurveyStates: (newStates) => {
    set({ surveyStates: newStates });
  },
  currentStepIndex: 0, // Start at the first step
  goToNextStep: () => {
    set((state) => {
      if (state.currentStepIndex < surveySteps.length - 1) {
        return { currentStepIndex: state.currentStepIndex + 1 };
      }
      return state; // No change if already at the last step
    });
  },
  goToPreviousStep: () => {
    set((state) => {
      if (state.currentStepIndex > 0) {
        return { currentStepIndex: state.currentStepIndex - 1 };
      }
      return state; // No change if already at the first step
    });
  },
  getCurrentStep: () => {
    const { currentStepIndex } = get();
    return surveySteps[currentStepIndex];
  },

  selectedMarketplaces: [], // Initialize as an empty array
  setSelectedMarketplaces: (newSelectedMarketplaces) => {
    set({ selectedMarketplaces: newSelectedMarketplaces });
  },
  isButtonDisabled: () => {
    const state = get(); // Get the current state of the store
    const { surveyStates, selectedMarketplaces, currentStepIndex } = state;
    if (currentStepIndex === 0) {
      console.log(surveyStates, selectedMarketplaces, currentStepIndex)

      return surveyStates.every(state => state === ""); // Check if any state is empty
    } else if (currentStepIndex === 1) {
      // Disable if no marketplaces are selected
      return selectedMarketplaces.length === 0;
    }
    return false; // No condition to disable for other steps
  },
}));


export default useSurveyStateStore;
