import { useEffect, useState } from "react";
import "./editWarehouse.scss";
import { UpdateWarehouseOwner } from "../api";
import { toast } from "react-toastify";
import type { UpdateWarehouseModel } from "./editwarehouseModel";

const EditWarehouse = ({ wOwner }: any): JSX.Element => {
  const warehouse = wOwner?.warehouses?.[0];

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [unielogicsId, setUnielogicsId] = useState(
    warehouse?.unielogicsId || ""
  );
  const [lobId, setLobId] = useState(warehouse?.lobId || "");
  const [warehouseId, setWarehouseId] = useState(warehouse?.warehouseId || "");
  const [costPerItemLabeling, setCostPerItemLabeling] = useState(
    warehouse?.costPerItemLabeling || ""
  );
  const [costPerBoxClosing, setCostPerBoxClosing] = useState(
    warehouse?.costPerBoxClosing || ""
  );
  const [businessAddress, setBusinessAddress] = useState(
    warehouse?.businessAddress || {}
  );
  const [costPerBox, setCostPerBox] = useState(warehouse?.costPerBox || []);
  const [handleShrink, setHandleShrink] = useState({
    answer: warehouse?.handleShrink?.answer || "no",
    small: warehouse?.handleShrink?.small || {},
    medium: warehouse?.handleShrink?.medium || {},
    large: warehouse?.handleShrink?.large || {},
  });
  const [handleHazmat, setHandleHazmat] = useState({
    answer: warehouse?.handleHazmat?.answer || "no",
  });
  const [bubbleWrapping, setBubbleWrapping] = useState({
    answer: warehouse?.bubbleWrapping?.answer || "no",
  });
  const [offerStorage, setOfferStorage] = useState({
    answer: warehouse?.offerStorage?.answer || "no",
  });
  const [name, setName] = useState(warehouse?.name || "");
  const [phoneNumber, setPhoneNumber] = useState(warehouse?.phoneNumber || "");
  const [llcName, setLlcName] = useState(warehouse?.llcName || "");
  const [businessName, setBusinessName] = useState(
    warehouse?.businessName || ""
  );
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState(
    warehouse?.businessPhoneNumber || ""
  );
  const [customerServiceEmailAddress, setCustomerServiceEmailAddress] =
    useState(warehouse?.customerServiceEmailAddress || "");
  const [wOwnerEmail, setWOwnerEmail] = useState(wOwner?.email || "");

  useEffect(() => {
    setUnielogicsId(warehouse?.unielogicsId || "");
    setLobId(warehouse?.lobId || "");
    setWOwnerEmail(wOwner?.email || "");
    setWarehouseId(warehouse?.warehouseId || "");
    setName(warehouse?.name || "");
    setPhoneNumber(warehouse?.phoneNumber || "");
    setLlcName(warehouse?.llcName || "");
    setBusinessName(warehouse?.businessName || "");
    setBusinessPhoneNumber(warehouse?.businessPhoneNumber || "");
    setCustomerServiceEmailAddress(
      warehouse?.customerServiceEmailAddress || ""
    );
    setCostPerItemLabeling(warehouse?.costPerItemLabeling || "");
    setCostPerBoxClosing(warehouse?.costPerBoxClosing || "");
    setBusinessAddress(warehouse?.businessAddress || {});
    setCostPerBox(warehouse?.costPerBox || []);
    setHandleShrink((prev) => ({
      ...prev,
      answer: warehouse?.handleShrink?.answer || "no",
    }));
    setHandleHazmat({
      answer: warehouse?.handleHazmat?.answer,
    });
    setBubbleWrapping({
      answer: warehouse?.bubbleWrapping?.answer,
    });
    setOfferStorage({
      answer: warehouse?.offerStorage?.answer,
    });
  }, [wOwner]);

  const goToNextPage = () => setCurrentPage(2);
  const goToPreviousPage = () => setCurrentPage(1);

  const handleSaveChanges = async () => {
    const data: UpdateWarehouseModel = {
      loggedInEmail: "franco@peri-mail.com",
      warehouseId: warehouseId,
      wOwnerEmail: wOwnerEmail,
      wOwner: {
        lobId: lobId,
        unielogicsId: unielogicsId,
        name: name,
        phoneNumber: phoneNumber,
        llcName: llcName,
        businessName: businessName,
        businessPhoneNumber: businessPhoneNumber,
        customerServiceEmailAddress: customerServiceEmailAddress,
        costPerItemLabeling: costPerItemLabeling,
        costPerBoxClosing: costPerBoxClosing,
        businessAddress: businessAddress,
        costPerBox: costPerBox,
        handleShrink: handleShrink,
        handleHazmat: handleHazmat,
        bubbleWrapping: bubbleWrapping,
        offerStorage: offerStorage,
      },
    };
    setLoading(true);

    try {
      await UpdateWarehouseOwner(data);
      toast.success("Warehouse Successfully Updated");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error("An error occurred while saving. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className="modal fade edit-editWarehouse"
        tabIndex={-1}
        id="exampleModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="container">
                <form className="row text-start">
                  {currentPage === 1 ? (
                    <>
                      <h1 className="text-center title">Warehouse Details</h1>
                      <div className="col-6 ">
                        <label>Unielogics Id</label>
                        <input
                          type="text"
                          className="form-control disabled"
                          value={unielogicsId}
                          onChange={(e) => setUnielogicsId(e.target.value)}
                          placeholder="Lob Id"
                          disabled
                        />
                      </div>
                      <div className="col-6">
                        <label>Lob Id</label>
                        <input
                          type="text"
                          className="form-control"
                          value={lobId}
                          onChange={(e) => setLobId(e.target.value)}
                          placeholder="Lob Id"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <label>Warehouse Id</label>
                        <input
                          type="text"
                          className="form-control disabled"
                          value={warehouseId}
                          placeholder="Warehouse Id"
                          disabled
                        />
                      </div>
                      <h1 className="text-center title">Patient Details</h1>
                      <div className="col-6">
                        <label>Client Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <label>Phone Number</label>
                        <input
                          type="text"
                          className="form-control"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          placeholder="Phone Number"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <label>LLC Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={llcName}
                          onChange={(e) => setLlcName(e.target.value)}
                          placeholder="LLC Name"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <label>Email</label>
                        <input
                          type="email"
                          className="form-control disabled"
                          value={wOwnerEmail}
                          onChange={(e) => setWOwnerEmail(e.target.value)}
                          placeholder="Email Address"
                          required
                          disabled
                        />
                      </div>
                      <h1 className="text-center title">Business Name</h1>
                      <div className="col-6">
                        <label>Business Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={businessName}
                          onChange={(e) => setBusinessName(e.target.value)}
                          placeholder="Business Name"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <label>Business Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          value={businessPhoneNumber}
                          onChange={(e) =>
                            setBusinessPhoneNumber(e.target.value)
                          }
                          placeholder="Business Phone"
                          required
                        />
                      </div>
                      <div className="col-12">
                        <label>Customer Business Email</label>
                        <input
                          type="email"
                          className="form-control"
                          value={customerServiceEmailAddress}
                          onChange={(e) =>
                            setCustomerServiceEmailAddress(e.target.value)
                          }
                          placeholder="Customer Business Email"
                          required
                        />
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal">
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={goToNextPage}>
                          Next
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <h1 className="text-center title">Business Address</h1>
                      <div className="col-6">
                        <label>Address</label>
                        <input
                          type="text"
                          className="form-control"
                          value={businessAddress.address}
                          onChange={(e) =>
                            setBusinessAddress({
                              ...businessAddress,
                              address: e.target.value,
                            })
                          }
                          placeholder="Address"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <label>City</label>
                        <input
                          type="text"
                          className="form-control"
                          value={businessAddress.city}
                          onChange={(e) =>
                            setBusinessAddress({
                              ...businessAddress,
                              city: e.target.value,
                            })
                          }
                          placeholder="City"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <label>State</label>
                        <input
                          type="text"
                          className="form-control"
                          value={businessAddress.state}
                          onChange={(e) =>
                            setBusinessAddress({
                              ...businessAddress,
                              state: e.target.value,
                            })
                          }
                          placeholder="State"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <label>Zip Code</label>
                        <input
                          type="text"
                          className="form-control"
                          value={businessAddress.zipCode}
                          onChange={(e) =>
                            setBusinessAddress({
                              ...businessAddress,
                              zipCode: e.target.value,
                            })
                          }
                          placeholder="Zip Code"
                          required
                        />
                      </div>
                      {/* cost details */}
                      <h1 className="text-center title">Cost Details</h1>
                      <div className="col-6">
                        <label>Cost Per Item Labeling</label>
                        <input
                          type="text"
                          className="form-control"
                          value={costPerItemLabeling}
                          onChange={(e) =>
                            setCostPerItemLabeling(e.target.value)
                          }
                          placeholder="Address"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <label>Cost Per Box Closing</label>
                        <input
                          type="text"
                          className="form-control"
                          value={costPerBoxClosing}
                          onChange={(e) => setCostPerBoxClosing(e.target.value)}
                          placeholder="Address"
                          required
                        />
                      </div>
                      <div className="d-flex justify-content-start">
                        <div className="col-6">
                          {" "}
                          <label>Do they handle Shrink Wrap?</label>
                          <div className="d-flex">
                            <div className="me-3">
                              <input
                                type="radio"
                                id="yes"
                                name="shrinkWrap"
                                value="yes"
                                checked={handleShrink.answer === "yes"}
                                onChange={() =>
                                  setHandleShrink((prev) => ({
                                    ...prev,
                                    answer: "yes",
                                  }))
                                }
                              />
                              <label htmlFor="yes">Yes</label>
                            </div>
                            <div>
                              <input
                                type="radio"
                                id="no"
                                name="shrinkWrap"
                                value="no"
                                checked={handleShrink.answer === "no"}
                                onChange={() =>
                                  setHandleShrink((prev) => ({
                                    ...prev,
                                    answer: "no",
                                  }))
                                }
                              />
                              <label htmlFor="no">No</label>
                            </div>
                          </div>
                        </div>

                        {/*  */}
                        <div className="col-6">
                          <label>Do they handle Hazmat Product?</label>
                          <div className="col-">
                            <div className="col-">
                              <div className="d-flex">
                                <div className="me-3">
                                  <input
                                    type="radio"
                                    id="yes"
                                    name="hazmat"
                                    value="yes"
                                    checked={handleHazmat.answer === "yes"}
                                    onChange={() =>
                                      setHandleHazmat({
                                        answer: "yes",
                                      })
                                    }
                                  />
                                  <label htmlFor="yes">Yes</label>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    id="no"
                                    name="hazmat"
                                    value="no"
                                    checked={handleHazmat.answer === "no"}
                                    onChange={() =>
                                      setHandleHazmat({
                                        answer: "no",
                                      })
                                    }
                                  />
                                  <label htmlFor="no">No</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="d-flex justify-content-start">
                        <div className="col-6">
                          {" "}
                          <label>Do they handle Bubble Wrapping?</label>
                          <div className="d-flex">
                            <div className="me-3">
                              <input
                                type="radio"
                                id="yes"
                                name="bubleWrapping"
                                value="yes"
                                checked={bubbleWrapping.answer === "yes"}
                                onChange={() =>
                                  setBubbleWrapping({
                                    answer: "yes",
                                  })
                                }
                              />
                              <label htmlFor="yes">Yes</label>
                            </div>
                            <div>
                              <input
                                type="radio"
                                id="no"
                                name="bubleWrapping"
                                value="no"
                                checked={bubbleWrapping.answer === "no"}
                                onChange={() =>
                                  setBubbleWrapping({
                                    answer: "no",
                                  })
                                }
                              />
                              <label htmlFor="no">No</label>
                            </div>
                          </div>
                        </div>

                        {/*  */}
                        <div className="col-6">
                          <label>Do they Offer Storage?</label>
                          <div className="col-">
                            <div className="d-flex">
                              <div className="me-3">
                                <input
                                  type="radio"
                                  id="yes"
                                  name="storage"
                                  value="yes"
                                  checked={offerStorage.answer === "yes"}
                                  onChange={() =>
                                    setOfferStorage({
                                      answer: "yes",
                                    })
                                  }
                                />
                                <label htmlFor="yes">Yes</label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  id="no"
                                  name="storage"
                                  value="no"
                                  checked={offerStorage.answer === "no"}
                                  onChange={() =>
                                    setOfferStorage({
                                      answer: "no",
                                    })
                                  }
                                />
                                <label htmlFor="no">No</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal">
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={goToPreviousPage}>
                          Back
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleSaveChanges}
                          disabled={loading}>
                          {loading ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                              style={{
                                width: "1rem",
                                height: "1rem",
                              }}>
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditWarehouse;
