import { Outlet } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { postSurvey } from './api';
import { IContextUser } from "common/interfaces";
import { localStorageNames, UserContext } from 'utils';
import useSurveyStateStore from './useSurveyStore';
import { ButtonTexts, ISurvey } from './interface';
import SurveyControl from './subComponent/SurveyControl';
import { SurveyMap } from './subComponent/SurveyMap/SurveyMap';
import Marketplaces from './subComponent/Marketplaces/Marketplaces';
import { SurveyStatusEnum } from './enum';

const SurveyModal = () => {
  const user: IContextUser = useContext(UserContext)!;
  const [showModal, setShowModal] = useState(false);
  const ModalDelayTimer = 25000
  const { surveyStates, goToNextStep, selectedMarketplaces, isButtonDisabled } = useSurveyStateStore()
  const currentStepIndex = useSurveyStateStore(state => state.currentStepIndex)

  const hideModalHandler = () => setShowModal(false);

  const handleSave = () => {
    // Perform save logic here
    if (currentStepIndex === 0) {
      goToNextStep();
      return
    }
    if (currentStepIndex === 1) {
      const data: ISurvey = {
        email: user.email,
        targetedStates: surveyStates,
        targetedMarketplaces: selectedMarketplaces
      }
      // console.log(data)
      postSurvey(data);
      hideModalHandler();
    }
  };
  const handleSurveyModal = () => {
    const user: IContextUser = JSON.parse(localStorage.getItem(localStorageNames.userObj) || '{}');
    
    if (user.survey?.SurveyStatus === SurveyStatusEnum.NOT_STARTED) {
      // Update the survey status in localStorage
      user.survey.SurveyStatus = SurveyStatusEnum.IN_PROGRESS;
      localStorage.setItem(localStorageNames.userObj, JSON.stringify(user));
      console.log("will display modal")
      // Set a timer to show the modal after a delay
      setTimeout(() => {
        console.log("Setting modal to true");
        setShowModal(true);
      }, ModalDelayTimer);
      return 
    }
    else{
      console.log("will not display modal")
    }
  };
  useEffect(() => {
    handleSurveyModal();
  }, []);
  return (
    <>
      {/* Render the current route's content */}
      <Outlet />

      {/* Modal overlay */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-3 w-11/12 max-w-7xl max-h-[90vh] relative flex flex-col">
            <h1 className="text-4xl font-bold text-center text-blue-600">
              <p className="text-black">before you continue...</p>
              Tell Us About Your Business
            </h1>
            <SurveyControl></SurveyControl>
            {currentStepIndex === 0 ? <SurveyMap /> : <Marketplaces />}
            <div className="mt-1 flex justify-end space-x-4">
              <button
                onClick={hideModalHandler}
                className="px-4 py-2 border border-red-500 text-red-500 rounded hover:bg-red-600 hover:text-white"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                // disabled={isButtonDisabled()}
              >
                {ButtonTexts[currentStepIndex]}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SurveyModal;
