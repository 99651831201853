import axios from "axios";
import { apiRoutes } from "utils";
import { ISurvey, ISurveyResponse } from "../interface";
import { toast } from "react-toastify";

export const postSurvey = async (surveyData: ISurvey): Promise<ISurveyResponse | null> => {
  try {
   

    const { data } = await axios.post<ISurveyResponse>(apiRoutes.SURVEY, surveyData);
    // Show success toast on successful submission
    toast.success("Thank you for your feedback!");
    
    return data;
  } catch (error) {
    const errorMessage = "Failed to submit survey. Please try again later.";
    console.error("Error posting survey:", error);
    
    // Show error toast
    toast.error(errorMessage);
    
    // Return null to indicate failure without throwing an error
    return null;
  }
};
