import AmazonDataCard from "components/AmazonDataCard";
import AmazonDataShipmentPlanDetails from "components/AmazonDataShipmentPlanDetails";
import WarehouseDetails from "components/WarehouseDetails";
import React, { useEffect, useState } from "react";
import { IShipmentPlan } from "pages/shipmentPlans/interfaces";
import AmazonVendorDetails from "./AmazonVendorDetails";
import OrderDetails from "./OrderDetails";
import OrderInformation from "components/OrderInformation";

interface PricingType {
  labelling: number;
  box: number;
  boxClosing: number;
  bundling: number;
  hazmat: number;
  processingFeesPercentage: number;
}

interface AmazonDataCollapseProps {
  shipmentPlan: IShipmentPlan;
  totalPriceCb: string;
  pricing: PricingType;
  shipmentPlanData: any;
  activeComponent: string;
}

export default function AmazonDataCollapse({
  shipmentPlan,
  totalPriceCb,
  pricing,
  shipmentPlanData,
  activeComponent,
}: AmazonDataCollapseProps) {
  return (
    <div className="flex flex-wrap gap-4 ml-3 my-2">
      {/* Amazon and Warehouse Details Accordion */}
      {activeComponent === "confirm" && (
        <div className="w-2/3">
          <div className="border rounded-lg shadow-lg">
            {/* Header */}
            <div className="p-1 border-b rounded-t-lg bg-gray-800 text-white">
              <button
                className="w-full text-left font-bold text-lg hover:text-blue-600 transition-colors"
                type="button"
              >
                Amazon and Warehouse Details
              </button>
            </div>
            {/* Content */}
            <div
              className={`transition-[max-height] duration-500 mb-4 overflow-hidden 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                            `}
            >
              <div className="p-2 flex justify-around">
                <AmazonVendorDetails vendor={shipmentPlan.supplier} />

                <WarehouseDetails
                  warehouseOwner={shipmentPlan?.warehouseOwner}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {activeComponent === "link" && (
        <div className="ml-20 me-20 w-5/6">
          <div className="border rounded-lg shadow-lg">
            {/* Header */}
            <div className="p-1 border-b rounded-t-lg bg-gray-800 text-white">
              <button
                className="w-full text-left font-bold text-lg hover:text-blue-600 transition-colors"
                type="button"
              >
                Orders
              </button>
            </div>
            {/* Content */}
            <div
              className={`transition-[max-height] duration-500 mb-4 overflow-hidden 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                            `}
            >
              <div className="p-2 flex justify-around">
                <WarehouseDetails
                  warehouseOwner={shipmentPlan?.warehouseOwner}
                  displayType="order"
                />

                <OrderInformation />
                
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Payment Chart Accordion */}
      {activeComponent === "confirm" && (
        <div className="w-3/12">
          <div className="border rounded-lg shadow-lg ">
            {/* Header */}
            <div className="bg-gray-800 text-white p-1 border-b rounded-t-lg">
              <button
                className="w-full text-left font-bold text-lg hover:text-green-600 transition-colors"
                type="button"
              >
                Pricing Chart |
                <span className="text-green-500 ml-2">
                  Total: ${totalPriceCb}
                </span>
              </button>
            </div>
            {/* Content */}
            <div
              className={`transition-[max-height] duration-500 overflow-hidden 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                            `}
            >
              <div className="p-1 ">
                <ul className="space-y-2 text-white">
                  <li>
                    <span className="secondary-title">Labelling:</span> $
                    {pricing.labelling}
                  </li>
                  <li>
                    <span className="secondary-title">Box:</span> ${pricing.box}
                  </li>
                  <li>
                    <span className="secondary-title">Box Closing:</span> $
                    {pricing.boxClosing}
                  </li>
                  <li>
                    <span className="secondary-title">
                      Bundling/Special Pack:
                    </span>{" "}
                    ${pricing.bundling}
                  </li>
                  <li>
                    <span className="secondary-title">Hazmat:</span> $
                    {pricing.hazmat}
                  </li>
                  <li>
                    <span className="secondary-title">Processing Fees:</span>{" "}
                    {pricing.processingFeesPercentage}%
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
