import { generateFieldsFromEnum } from "./helper";

export enum ContactPersonEnum {
    NAME = "contactName",
    EMAIL = "contactEmail",
    PHONE_NUMBER = "contactPhoneNumber",
    EXTENSION_CODE = "contactExtensionCode"
}

export const ContactPersonFields = generateFieldsFromEnum(ContactPersonEnum);
export type ContactPersonDetails = { [key in ContactPersonEnum]: string; };

