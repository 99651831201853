import axios from "axios";
import { apiRoutes } from "utils";
import {
  IPostSupplierResponse,
  IGetSuppliersResponse,
  IPostSupplierRequest,
  IDeleteSupplierResponse,
  IDeleteSupplierRequest,
} from "../interfaces";

export const getSuppliersRequest = async (email: string) => {
  const { data } = await axios.get<IGetSuppliersResponse>(
    `${apiRoutes.GET_SUPPLIERS}?email=${encodeURIComponent(email)}`
  );
  return data;
};


const buildSupplierData = (request: IPostSupplierRequest) => ({
  email: request.email,
  supplier: {
    supplierName: request.name,
    onlineSupplier: request.onlineSupplier,
    supplierAddress: {
      street: request.street,
      city: request.city,
      state: request.state,
      zipCode: request.zipCode,
    },
    supplierLink: request.link,
    contactPerson: {
      name: request.contactName,
      email: request.contactEmail,
      phoneNumber: request.contactPhoneNumber,
      extensionCode: request.contactExtensionCode,
    },
  },
});
export const postRegisterSupplier = async (request: IPostSupplierRequest) => {
  const supplierData = buildSupplierData(request);
  
  const { data } = await axios.post<IPostSupplierResponse>(apiRoutes.POST_SUPPLIERS, supplierData);
  
  return data;
};

export const deleteSuppliers = async ({
  email,
  _id,
}: IDeleteSupplierRequest) => {
  const { data } = await axios.delete<IDeleteSupplierResponse>(
    apiRoutes.DELETE_SUPPLIERS,
    {
      data: { email, _id },
    }
  );

  return data;
};
