import { Link } from 'react-router-dom';
import { sidebarMenuItems } from './constants';

const UserSideBar = () => {
    return (
        <>
            {sidebarMenuItems.map(({ to, icon, label }, index) => (
                <li
                    key={index}
                >
                    <Link to={to}>
                        <span className="fa-stack fa-sm pull-left">
                            <i className={`fa fa-sm ${icon} fa-stack-1x`}></i>
                        </span>
                        {label}
                        {<span className="badge-new">New</span>}
                    </Link>
                </li>
            ))}
        </>
    );
};

export default UserSideBar;
