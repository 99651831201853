import { useCallback, useContext, useEffect, useState } from "react";
import { QUERY_KEYS, UserContext, profitableProductsTableHeaders } from "utils";
import { IItemInFolder, IProfitableProductsList } from "./interfaces";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getSuppliersRequest } from "pages/suppliers/api";
import { ISupplier } from "pages/suppliers/interfaces";
import { IContextUser } from "common/interfaces";
import ProductRow from "./ProductRow";

interface SupplierOption {
  value: string;
  label: string;
}

const ProfitableProductsList = ({
  itemsInFolder,
  onDeleteItemClickCb,
  onSelectProductsForShipmentPlanClickCb,
  onSupplierChange,
}: IProfitableProductsList) => {
  const navigate = useNavigate();
  const user: IContextUser = useContext(UserContext)!;
  const { email } = user;

  // Initialize with empty supplier to prevent undefined issues
  const [suppliers, setSuppliers] = useState<SupplierOption[]>([
    {
      value: "no-supplier",
      label: "No Supplier",
    },
  ]);
  const [originalSuppliers, setOriginalSuppliers] = useState<ISupplier[]>([]);

  const { data, isLoading: isLoadingGetSuppliers } = useQuery(
    QUERY_KEYS.GET_SUPPLIERS,
    () => getSuppliersRequest(email),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data?.response?.suppliers) {
      const suppliersList = data.response.suppliers;

      setOriginalSuppliers(suppliersList);
      const transformedSuppliers: SupplierOption[] = [
        { value: "no-supplier", label: "No Supplier" },
        ...suppliersList.map((supplier) => ({
          value: supplier._id,
          label: supplier.supplierName || "Unnamed Supplier",
        })),
      ];

      setSuppliers(transformedSuppliers);
    }
  }, [data]);

  const goToDetailsPage = useCallback(
    ({ folderId, asin }: { folderId: string; asin: string }) => {
      navigate(
        `/profitable-products/details?folderId=${folderId}&asin=${asin}`
      );
    },
    [navigate]
  );

  const onAutocompleteSelect = useCallback(
    (
      option: SupplierOption,
      productId: string,
      folderId: string,
      currentItem: IItemInFolder
    ) => {
      const emptySupplier: ISupplier = {
        supplierName: "",
        supplierLink: "",
        onlineSupplier: "",
        supplierAddress: {
          city: "",
          state: "",
          zipCode: "",
          street: "",
          lat: 0,
          long: 0,
        },
        contactPerson: {
          email: "",
          name: "",
          phoneNumber: "",
          extensionCode: "",
        },
        _id: "no-supplier",
      };

      const selectedSupplier =
        option.value !== "no-supplier"
          ? originalSuppliers.find(
              (supplier) => supplier._id === option.value
            ) ?? emptySupplier
          : emptySupplier;

      onSupplierChange(selectedSupplier, folderId, productId, currentItem);
    },
    [originalSuppliers, onSupplierChange]
  );

  // Early return for loading state
  if (isLoadingGetSuppliers) {
    return (
      <div className="table-responsive max-h-78vh profitable-products">
        <div className="text-center p-4">Loading suppliers...</div>
      </div>
    );
  }

  // Early return for no data
  if (!Array.isArray(itemsInFolder) || itemsInFolder.length === 0) {
    return (
      <div className="table-responsive max-h-78vh profitable-products">
        {JSON.stringify(itemsInFolder)}
        <div className="text-center p-4">No items to display</div>
      </div>
    );
  }

  return (
    <div className="table-responsive max-h-78vh profitable-products">
      <table className="table table-sm">
        <thead className="sticky-header z-10">
          <tr>
            {profitableProductsTableHeaders.map((header) => (
              <th className="text-center" key={header.name}>
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {itemsInFolder.map((item) => (
            <ProductRow
              key={item._id}
              item={item}
              suppliers={suppliers}
              isLoadingGetSuppliers={isLoadingGetSuppliers}
              onGoToDetailsPage={goToDetailsPage}
              onDeleteItemClick={onDeleteItemClickCb}
              onSelectProductsForShipmentPlan={
                onSelectProductsForShipmentPlanClickCb
              }
              onAutocompleteSelect={onAutocompleteSelect}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProfitableProductsList;
