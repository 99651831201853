// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-title {
  font-size: 1.3rem;
}

.modal-content {
  background-color: rgb(33, 41, 66);
}

.modal-header {
  background-color: rgba(140, 190, 234, 0.557);
}

h3 a {
  color: rgba(39, 188, 215, 0.747) !important;
  cursor: pointer;
}

body.modal-open {
  overflow: hidden; /* Prevents scrolling */
  padding-right: 0 !important; /* Ensures no extra margin is added */
}

.edit-editWarehouse {
  margin-top: 80px;
}

.title {
  font-size: 1.3rem;
  color: rgba(255, 255, 255, 0.397);
  margin-bottom: 15px;
  font-weight: bold;
}

.col-4 {
  margin-bottom: 14px;
}

.col-6 {
  margin-bottom: 14px;
}

.col-12 {
  margin-bottom: 14px;
}

.modal-dialog {
  width: 600px;
  max-height: 600px;
}

label {
  font-size: 16px;
  font-weight: bold;
}

.modal-body {
  overflow-y: auto; /* Enable scrolling within modal body */
  max-height: 740px; /* Adjust depending on the header height */
}

input:disabled {
  background: #606061; /* Light grey */
  color: #6c757d; /* Optional: Darker grey for text */
  cursor: not-allowed; /* Show "not allowed" cursor */
}

.disabled {
  background-color: #606061;
}`, "",{"version":3,"sources":["webpack://./src/pages/suppliers/SuppliersForm/styles.scss"],"names":[],"mappings":"AACA;EACC,iBAAA;AAAD;;AAEA;EACC,iCAAA;AACD;;AACA;EACC,4CAAA;AAED;;AAEC;EACC,2CAAA;EACA,eAAA;AACF;;AAGA;EACC,gBAAA,EAAA,uBAAA;EACA,2BAAA,EAAA,qCAAA;AAAD;;AAGA;EACC,gBAAA;AAAD;;AAGA;EACC,iBAAA;EACA,iCAAA;EACA,mBAAA;EACA,iBAAA;AAAD;;AAGA;EACC,mBAAA;AAAD;;AAGA;EACC,mBAAA;AAAD;;AAGA;EACC,mBAAA;AAAD;;AAGA;EACC,YAAA;EACA,iBAAA;AAAD;;AAGA;EACC,eAAA;EACA,iBAAA;AAAD;;AAGA;EACC,gBAAA,EAAA,uCAAA;EACA,iBAAA,EAAA,0CAAA;AAAD;;AAGA;EACC,mBAAA,EAAA,eAAA;EACA,cAAA,EAAA,mCAAA;EACA,mBAAA,EAAA,8BAAA;AAAD;;AAGA;EACC,yBAAA;AAAD","sourcesContent":["\n.modal-title {\n\tfont-size: 1.3rem;\n}\n.modal-content {\n\tbackground-color: rgb(33, 41, 66);\n}\n.modal-header {\n\tbackground-color: rgba(140, 190, 234, 0.557);\n}\n\nh3 {\n\ta {\n\t\tcolor: rgba(39, 188, 215, 0.747) !important;\n\t\tcursor: pointer;\n\t}\n}\n\nbody.modal-open {\n\toverflow: hidden; /* Prevents scrolling */\n\tpadding-right: 0 !important; /* Ensures no extra margin is added */\n}\n\n.edit-editWarehouse {\n\tmargin-top: 80px;\n}\n\n.title {\n\tfont-size: 1.3rem;\n\tcolor: rgba(255, 255, 255, 0.397);\n\tmargin-bottom: 15px;\n\tfont-weight: bold;\n}\n\n.col-4 {\n\tmargin-bottom: 14px;\n}\n\n.col-6 {\n\tmargin-bottom: 14px;\n}\n\n.col-12 {\n\tmargin-bottom: 14px;\n}\n\n.modal-dialog {\n\twidth: 600px;\n\tmax-height: 600px;\n}\n\nlabel {\n\tfont-size: 16px;\n\tfont-weight: bold;\n}\n\n.modal-body {\n\toverflow-y: auto; /* Enable scrolling within modal body */\n\tmax-height: 740px; /* Adjust depending on the header height */\n}\n\ninput:disabled {\n\tbackground: #606061; /* Light grey */\n\tcolor: #6c757d; /* Optional: Darker grey for text */\n\tcursor: not-allowed; /* Show \"not allowed\" cursor */\n}\n\n.disabled {\n\tbackground-color: #606061;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
